import { View } from '@react-pdf/renderer';
import ListItem, { ListItemProps } from './list-item';
import { ListStyles } from './styles';

export interface ListProps {
  items: Omit<ListItemProps, 'index'>[];
  ordered?: boolean;
}

const List: React.FC<ListProps> = ({ items, ordered }) => {
  return (
    <View style={ListStyles.list}>
      {items.map((item, i) => (
        <ListItem key={item.children} {...item} ordered={ordered} index={i} />
      ))}
    </View>
  );
};

export default List;
