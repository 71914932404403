import { StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types/style';
import { TypographyElementTheme } from '../components/common/typography/types';
import { PdfContentStyles } from './contents';

export const PdfTemplatesStyles = StyleSheet.create({
  slideCentered: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  slideContentRowWrap: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    textAlign: 'center',
    flexWrap: 'wrap',
    gap: 16,
  },
  slideContentSpaceBetween: {
    flexDirection: 'column',

    justifyContent: 'space-between',
    textAlign: 'left',
  },
  slideContentLeftAligned: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
});

type ContentStyleType = 'center' | 'left' | 'wrap' | 'space-between';

interface HeaderElement {
  headerElementTheme: TypographyElementTheme;
}
export const createTemplateStyle = ({
  withFooter,
  withHeader,
  type,
}: {
  withHeader: boolean | HeaderElement;
  withFooter: boolean;
  type: ContentStyleType;
}) => {
  let style: Style = { ...PdfContentStyles.slideContentDefault };
  if (!!withHeader && withFooter) {
    style = { ...style, ...PdfContentStyles.slideContentWithHeaderFooter };
  } else if (!!withHeader) {
    style = { ...style, ...PdfContentStyles.slideContentWithHeader };
  } else if (withFooter) {
    style = { ...style, ...PdfContentStyles.slideContentWithFooter };
  } else {
    style = { ...style, ...PdfContentStyles.slideContentFull };
  }

  if ((withHeader as HeaderElement).headerElementTheme) {
    const elementTheme = (withHeader as HeaderElement).headerElementTheme;
    style = {
      ...style,
      height: ['h6'].includes(elementTheme)
        ? '92vh'
        : ['h5'].includes(elementTheme)
          ? '91vh'
          : style.height,
    };
  }

  switch (type) {
    case 'center':
      style = { ...style, ...PdfTemplatesStyles.slideCentered };
      break;
    case 'left':
      style = { ...style, ...PdfTemplatesStyles.slideContentLeftAligned };
      break;
    case 'wrap':
      style = { ...style, ...PdfTemplatesStyles.slideContentRowWrap };
      break;
    case 'space-between':
      style = { ...style, ...PdfTemplatesStyles.slideContentSpaceBetween };
      break;
  }
  return style;
};
