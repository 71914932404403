import { StyleSheet } from '@react-pdf/renderer';

export const ListStyles = StyleSheet.create({
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    paddingLeft: 0,
    width: '100%',
    height: 'auto',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'flex-start',
    width: '100%',
    flexShrink: 0,
  },
});
