import ImagePlus from '@hellodarwin/icons/dist/icons/ImagePlus';
import Form from 'antd/es/form';
import { useForm } from 'antd/es/form/Form';
import Input from 'antd/es/input/Input';
import Select from 'antd/es/select';
import Upload, { RcFile } from 'antd/es/upload';
import { UploadProps } from 'antd/es/upload/interface';
import { MouseEvent, MouseEventHandler, useEffect, useState } from 'react';
import {
  PortfolioAsset,
  PortfolioAssetsType,
} from '../../../../../../features/entities';
import { useTranslations } from '../../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../../plugins/styled';
import Button from '../../../../../common/button';
import Div from '../../../../../common/div';
import Modal from '../../../../../common/hd-modal';
import Typography from '../../../../../common/typography';
import FormItem from '../../../../form-layouts/form-item';
import validateFile, { getFileTypes } from '../../../../utils/validate-file';

type PortfolioAssetModalProps = {
  open: boolean;
  handleClose: () => void;
  assets: PortfolioAsset[];
  setAssets: (assets: PortfolioAsset[]) => void;
  portfolio_id: string;
};

const Footer = ({
  handleCancel,
  handleVideoSave,
}: {
  handleCancel: MouseEventHandler<HTMLButtonElement>;
  handleVideoSave?: MouseEventHandler<HTMLButtonElement>;
}) => {
  const theme = useTheme();
  const { t } = useTranslations();
  return (
    <Div
      flex="row"
      justify="flex-end"
      gap={16}
      tablet={{ align: 'center', flex: 'column' }}
    >
      <Button
        size="small"
        defaultStyle={theme.colors.white_1}
        onClick={handleCancel}
      >
        {t('button|cancel')}
      </Button>
      {!!handleVideoSave && (
        <Button
          size="small"
          defaultStyle={theme.colors.purple_1}
          onClick={handleVideoSave}
        >
          {t('button|submit')}
        </Button>
      )}
    </Div>
  );
};

const PortfolioAssetModal = ({
  open,
  handleClose,
  assets,
  setAssets,
  portfolio_id,
}: PortfolioAssetModalProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  const [form] = useForm<{ asset_url: string }>();
  const [type, setType] = useState<'video' | 'image'>('image');

  useEffect(() => {
    form.resetFields();
  }, [type]);

  const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
    const temp = [...assets];
    fileList.forEach((file) => {
      const isValid = validateFile(file);
      if (isValid) {
        const asset: PortfolioAsset = {
          pos: assets.length,
          provider_portfolio_id: portfolio_id,
          asset_type: PortfolioAssetsType.Image,
          file: file,
          temp_id: file.uid,
          filename: file.name,
        };
        temp.push(asset);
      }
    });

    setAssets(temp);
    handleClose();
    return false;
  };

  const uploadProps: UploadProps = {
    className: 'assets-uploader',
    name: 'Asset',
    beforeUpload: handleBeforeUpload,
    multiple: false,
    listType: 'picture-card',

    customRequest: (options) => {
      const { onSuccess, onError, file, onProgress } = options;
      if (!!onSuccess) onSuccess('OK');
    },
  };

  const handleVideoSave = () => {
    const { asset_url } = form.getFieldsValue();
    const newAsset: PortfolioAsset = {
      pos: assets.length,
      provider_portfolio_id: portfolio_id,
      asset_type: PortfolioAssetsType.Video,
      asset_url: asset_url,
      temp_id: `${portfolio_id}_${new Date().getTime()}`,
    };

    setAssets([...assets, newAsset]);

    handleClose();
  };

  const handleCancel = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    form.resetFields();
    handleClose();
  };

  return (
    <Modal open={open} handleCancel={handleClose}>
      <Div flex="column" gap={16} style={{ paddingRight: '1rem' }}>
        <Select placeholder="Select option" value={type} onChange={setType}>
          <Select.Option value="video">
            {t('portfolioAssetsModal|assetTypeVideo')}
          </Select.Option>
          <Select.Option value="image">
            {t('portfolioAssetsModal|assetTypeImage')}
          </Select.Option>
        </Select>
        {type === 'image' ? (
          <Div flex="column" gap={16}>
            <Upload.Dragger
              {...uploadProps}
              className={`assets-uploader`}
              multiple
              showUploadList={false}
            >
              <ImagePlus size={100} />

              <Div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                <Typography
                  elementTheme="caption"
                  color={theme.colors.grey_3}
                  textAlign="right"
                >
                  {t('portfolioAssetsModal|assetSettingResolution', {
                    width: '600',
                    height: '600',
                  })}
                </Typography>
                <Typography
                  elementTheme="caption"
                  color={theme.colors.grey_3}
                  textAlign="right"
                >
                  {t('portfolioAssetsModal|assetSettingFileSize')}
                </Typography>
                <Typography
                  elementTheme="caption"
                  color={theme.colors.grey_3}
                  textAlign="right"
                >
                  {t('portfolioAssetsModal|assetSettingAllowedFile', {
                    types: getFileTypes().join(', '),
                  })}
                </Typography>
              </Div>
            </Upload.Dragger>
            <Footer
              handleCancel={handleCancel}
              handleVideoSave={handleVideoSave}
            />
          </Div>
        ) : (
          type === 'video' && (
            <Div>
              <Form
                layout="vertical"
                name="video"
                onFinish={handleVideoSave}
                form={form}
              >
                <Div flex="column" gap={16}>
                  <FormItem
                    label="Video url"
                    name="asset_url"
                    rules={[
                      {
                        required: true,
                        message: 'Please add a valid URL',
                      },
                    ]}
                  >
                    <Input />
                  </FormItem>
                  <Footer
                    handleCancel={handleCancel}
                    handleVideoSave={handleVideoSave}
                  />
                </Div>
              </Form>
            </Div>
          )
        )}
      </Div>
    </Modal>
  );
};

export default PortfolioAssetModal;
