import { ProjectGrant, ProjectProgram } from '../../../@types/projects-types';
import addParamToTranslation from '../../../utils/add-params-to-translation';
import formatDollarValue from '../../../utils/format-dollar-value';
import { GamePlanTranslations } from '../types';

const getFundingAmount = (
  grant: ProjectGrant,
  application: ProjectProgram,
  locale: string,
  translations: GamePlanTranslations,
) => {
  const { funding_min_amount, funding_max_amount, percentage_funding } = grant;
  const {
    program_targeted_amount,
    program_accepted_amount,
    program_application_amount,
  } = application;
  let value = '';

  if (!!percentage_funding) {
    if (!!funding_min_amount && !!funding_max_amount) {
      value += `${percentage_funding}% `;
    } else if (!!funding_min_amount || !!funding_max_amount) {
      value += `${percentage_funding}% ${translations.upTo} `;
    } else {
      value += addParamToTranslation(translations.projectCostPercent, {
        percent: `${percentage_funding}`,
      });
    }
  }

  if (!!funding_min_amount && !!funding_max_amount) {
    value += addParamToTranslation(translations.fromMinToMax, {
      min: `${formatDollarValue(funding_min_amount, locale, true, true)}`,
      max: `${formatDollarValue(funding_max_amount, locale, true, true)}`,
    });
  } else if (!!funding_min_amount) {
    value += formatDollarValue(funding_min_amount, locale, true, true);
  } else if (!!funding_max_amount) {
    value += formatDollarValue(funding_max_amount, locale, true, true);
  } else if (program_targeted_amount && !percentage_funding) {
    value += `≈ ${formatDollarValue(program_targeted_amount, locale, true, true)}`;
  } else if (program_application_amount && !percentage_funding) {
    value += `≈ ${formatDollarValue(program_application_amount, locale, true, true)}`;
  } else if (program_accepted_amount && !percentage_funding) {
    value += `≈ ${formatDollarValue(program_accepted_amount, locale, true, true)}`;
  }

  if (!value?.length) return ' - ';
  return value;
};

export default getFundingAmount;
