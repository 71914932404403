import { View } from '@react-pdf/renderer';
import { ProjectResponse } from '../../../@types/projects-types';
import Group2Outlined from '../../../components/background/group2-outlined';
import CardWithIcon from '../../../components/cards/tile-with-description-and-icon';
import PropulsionIcon from '../../../components/common/icons/propulsion';
import PageHeader from '../../../components/header';
import { createTemplateStyle } from '../../../styles/templates';
import theme from '../../../theme';
import { GamePlanTranslations } from '../types';

export interface PriorityInfosProps {
  priority: ProjectResponse;
  translations: GamePlanTranslations;
}

const PriorityInfos: React.FC<PriorityInfosProps> = ({
  priority,
  translations,
}) => {
  return (
    <View id={priority.project_id}>
      <Group2Outlined
        width={679}
        height={948}
        color={theme.colors.beige_1}
        top={-68}
        margin={-354}
        align="left"
      />

      <PageHeader title="" />
      <View
        style={createTemplateStyle({
          type: 'center',
          withFooter: true,
          withHeader: true,
        })}
      >
        <CardWithIcon
          icon={{
            Icon: PropulsionIcon,
            backgroundColor: theme.colors.purple_2,
            color: theme.colors.white_1,
          }}
          size={'xl'}
          direction={'horizontal'}
          label={priority.title}
          content={`
            <p>${priority.description}</p>
           ${
             !!priority.projects &&
             `
            <br/>
            <br/>
            <p style="text-align:left;padding-bottom:4px;"><b>${translations.associatedGamePlanProjects}</b></p>
            <ul>
            ${priority.projects?.map((p) => `<li data-href="#${p.project_id}" data-color="${theme.colors.purple_2}" >${p.title}</li>`).join(' ')}
            `
           }
            </ul>`}
          bgColor={theme.colors.beige_1}
        />
      </View>
    </View>
  );
};

export default PriorityInfos;
