import Button from '@hellodarwin/core/lib/components/common/button';
import { Company } from '@hellodarwin/core/lib/features/entities/core-entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import message from 'antd/es/message';
import { MouseEventHandler, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app';
import AccountManagerFormModal from '../../components/companies/single/admins-list/account-manager-form-modal';
import { selectAdminById } from '../../features/api/slices/admins-slice';
import {
  selectCompaniesIsLoading,
  selectCompanyById,
  updateCompany,
} from '../../features/api/slices/companies-slice';
import { useAdminApi } from '../../features/api/use-admin-api';

interface AddAccountManagerButtonProps {
  companyId: string;
}

const AddAccountManagerButton: React.FC<AddAccountManagerButtonProps> = ({
  companyId,
}) => {
  const { t } = useTranslations();
  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const company = useAppSelector((state) =>
    selectCompanyById(state, companyId),
  );
  const adminProfile = useAppSelector((state) =>
    company?.account_manager
      ? selectAdminById(state, company.account_manager!)
      : undefined,
  );
  const isLoading = useAppSelector(selectCompaniesIsLoading);

  const [accountManagerModalOpen, setaccountManagerModalOpen] = useState(false);

  const handleNewUser: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setaccountManagerModalOpen(true);
  };

  const handleAccountManagerSelect = async (adminId: string | undefined) => {
    try {
      const updatedCompany: Company = {
        ...company,
        account_manager: adminId,
      };

      await dispatch(updateCompany({ api, company: updatedCompany }));
      message.success(t('companyForm|saved'));
    } catch (e: any) {
      message.error(t('companyForm|messageError'));
      console.error(e);
    }

    handleClose();
  };

  const handleDelete = () => {
    handleAccountManagerSelect(undefined);
  };

  const handleClose = () => {
    setaccountManagerModalOpen(false);
  };

  return (
    <>
      <Button
        size="circle"
        transparent
        headingIcon={adminProfile ? <Edit size={16} /> : <AddNew size={16} />}
        onClick={handleNewUser}
      />
      <AccountManagerFormModal
        open={accountManagerModalOpen}
        handleSelection={handleAccountManagerSelect}
        handleDelete={handleDelete}
        handleClose={handleClose}
        adminProfile={adminProfile}
        isLoading={isLoading}
      />
    </>
  );
};

export default AddAccountManagerButton;
