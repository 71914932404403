import { ModalConfirm } from '@hellodarwin/core/lib/components/common/hd-modal/confirm';
import { Contact } from '@hellodarwin/core/lib/features/entities/core-entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import { ItemType } from 'antd/es/menu/interface';
import message from 'antd/es/message';
import notification from 'antd/es/notification';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../../app/app-hooks';
import { deleteContact } from '../../../../features/api/slices/users-slice';
import { useAdminApi } from '../../../../features/api/use-admin-api';
import { copyIntoClipboard } from '../../../clipboard';

interface useUserDropdownItemsProps {
  user: Contact;
}
const useUserDropdownItems = ({
  user,
}: useUserDropdownItemsProps): ItemType[] => {
  const { t } = useTranslations();
  const theme = useTheme();
  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const [auth0UserID, setAuth0UserID] = useState<string>('');
  const [auth0UserIDMatch, setAuth0UserIDMatch] = useState<boolean>(false);
  const [resetPasswordLink, setResetPasswordLink] = useState<string>('');
  const [linkIsValid, setLinkIsValid] = useState<boolean>(false);

  const hasAuth = useMemo(
    () => auth0UserID !== '' && auth0UserIDMatch,
    [auth0UserID, auth0UserIDMatch],
  );

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (isMounted) {
        setAuth0UserID(user.auth_id || '');
        setAuth0UserIDMatch(user.auth_id_match ?? false);
      }
    })();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  //Create user Auth0 account and send activation email through SendGrid
  const auth0CreateAccount = async () => {
    try {
      const newUserInfo = await api.createAuth0User(user?.contact_id, 'Client');
      setAuth0UserID(newUserInfo.auth_id);
      setResetPasswordLink(newUserInfo.reset_link);
      message.success('Auth0 user created! Activation email sent');
    } catch (err: any) {
      if (err.response.data.error_code === 5024)
        message.error('Failed to find contact by ID');
      else message.error('Failed to create Auth0 account');
    }
  };

  const auth0resetPasswordEmail = async () => {
    try {
      await api.resetAuth0PasswordEmail(user?.contact_id, 'Client');
      setResetPasswordLink('');
      message.success('Reset password email sent!');
    } catch (err: any) {
      if (err.response.data.error_code === 5024)
        message.error('Failed to find contact by ID');
      else message.error('Failed to send reset password email');
    }
  };

  const auth0resetPasswordLink = async () => {
    try {
      setLinkIsValid(true);

      setResetPasswordLink(
        await api.resetAuth0PasswordLink(user?.contact_id, 'Client'),
      );
      message.success('Reset password link generated successfully!');
    } catch (err: any) {
      if (err.response.data.error_code === 5024)
        message.error('Failed to find contact by ID');
      else message.error('Failed to generate reset password link');
    }
  };

  useEffect(() => {
    if (resetPasswordLink && linkIsValid) {
      copyIntoClipboard(resetPasswordLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPasswordLink]);

  const handleContactDelete = async (contactId: string) => {
    ModalConfirm({
      title: t('singleCompanyPage|deleteContactTitle'),

      okButtonProps: {
        defaultStyle: theme.colors.red_1,
      },
      cancelButtonProps: { defaultStyle: theme.colors.white_1 },
      cancelText: t('button|cancel'),
      okText: t('button|delete'),
      content: t('singleCompanyPage|deleteContactDescription'),
      onOk: async () => {
        await dispatch(deleteContact({ api, contactId }))
          .then(({ meta: { requestStatus } }) => {
            if (requestStatus === 'fulfilled') {
              notification.success({
                message: t('singleCompanyPage|deleteContactSuccess'),
                placement: 'top',
                duration: 0,
              });
            } else {
              notification.error({
                message: t('singleCompanyPage|deleteContactFailed'),
                placement: 'top',
                duration: 0,
              });
            }
          })
          .catch((e) => {
            notification.error({
              message: t('singleCompanyPage|deleteContactFailed'),
              placement: 'top',
              duration: 0,
            });
          });
      },
      onCancel: async () => {},
    });
  };

  return useMemo(() => {
    const items: ItemType[] = [
      {
        label: t('singleCompanyPage|deleteContactCTA', {
          contact_name: user.name,
        }),
        key: 'delete',
        onClick: (e) => {
          e.domEvent.stopPropagation();
          handleContactDelete(user.contact_id);
        },
      },
    ];

    if (hasAuth) {
      items.push({
        label: t('singleCompanyPage|resetPasswordEmail', {
          contact_name: user.name,
        }),
        key: 'reset-password-email',
        onClick: (e) => {
          e.domEvent.stopPropagation();
          auth0resetPasswordEmail();
        },
      });
      items.push({
        label: t('singleCompanyPage|resetPasswordLink', {
          contact_name: user.name,
        }),
        key: 'reset-password-link',
        onClick: (e) => {
          e.domEvent.stopPropagation();
          auth0resetPasswordLink();
        },
      });
      items.push({
        label: t('singleCompanyPage|getAuthId', {
          contact_name: user.name,
        }),
        key: 'get-auth-id',
        onClick: (e) => {
          e.domEvent.stopPropagation();
          copyIntoClipboard(auth0UserID);
        },
      });
    } else {
      items.push({
        label: t('singleCompanyPage|createAuth', {
          contact_name: user.name,
        }),
        key: 'create-auth',
        onClick: (e) => {
          e.domEvent.stopPropagation();
          auth0CreateAccount();
        },
      });
    }

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, hasAuth, auth0UserID, t]);
};
export default useUserDropdownItems;
