import HelloDarwinPDF from '../..';
import { getStringDate } from '../../utils/get-string-date';
import { GamePlanPdfProps } from './types';

const GamePlanPdf: React.FC<GamePlanPdfProps> = ({
  projects,
  company,
  translations,
  admin,
  creationDate,
  locale,
  ...pdfProps
}) => {
  return (
    <HelloDarwinPDF
      titlePage={{
        title: translations.introTitle,
        subtitle: translations.introSubtitle,
        footerInfos: [
          { label: translations.presentedAt, value: company.name },
          {
            label: translations.date,
            value: getStringDate(creationDate, locale),
          },
          {
            label: translations.accountManager,
            value: [admin.first_name, admin.last_name].join(' '),
          },
        ],
      }}
      admin={admin}
      translations={translations}
      locale={locale}
      creationDate={creationDate}
      {...pdfProps}
    />
  );
};

export default GamePlanPdf;
