import Button from '@hellodarwin/core/lib/components/common/button';
import {
  AdminGrantResult,
  HdChatPromptRequest,
} from '@hellodarwin/core/lib/features/entities';
import { FormInstance } from 'antd/es/form/Form';
import { Col, Row } from 'antd/es/grid';
import message from 'antd/es/message';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  generateSingleGrantContentInfo,
  selectIsLoadingSectionsGen,
} from '../../../features/api/slices/grants-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';
import theme from '../../../theme';
import { default as GrantPromptContentSection } from '../grant-prompt-content-section';
import { GrantFormValues, transformSectionIntoLabel } from './grant-form';

type GrantFormSectionsProps = {
  grant?: AdminGrantResult;
  onSave?: () => Promise<void>;
  setSelectedPrompt: Dispatch<SetStateAction<HdChatPromptRequest>>;
  setAddNewPromptDrawer: Dispatch<SetStateAction<boolean>>;
  form: FormInstance<GrantFormValues>;
  formValues: GrantFormValues;
  prompts: HdChatPromptRequest[];
  isGeneratingRemaining?: boolean;
  isGeneratingAll?: boolean;
};

export const grantPromptSectionTitles: (keyof GrantFormValues)[] = [
  'gameplan_description_en',
  'gameplan_description_fr',
  'eligibility_criteria_short_en',
  'eligibility_criteria_short_fr',
  'eligibility_criteria_en',
  'eligibility_criteria_fr',
  'who_can_apply_en',
  'who_can_apply_fr',
  'who_cannot_apply_en',
  'who_cannot_apply_fr',
  'project_activity_en',
  'project_activity_fr',
  'zone_en',
  'zone_fr',
  'eligible_expenses_en',
  'eligible_expenses_fr',
  'selection_criteria_en',
  'selection_criteria_fr',
  'steps_how_to_apply_en',
  'steps_how_to_apply_fr',
  'funding_terms_and_conditions_en',
  'funding_terms_and_conditions_fr',
  'additional_information_en',
  'additional_information_fr',
  'expanded_description_en',
  'expanded_description_fr',
];

const GrantFormSections = ({
  grant,
  onSave,
  setSelectedPrompt,
  setAddNewPromptDrawer,
  form,
  formValues,
  prompts,
  isGeneratingRemaining,
  isGeneratingAll,
}: GrantFormSectionsProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const accessScrapeContext = () => {
    window.open(`/grants/scrape-context/${grant?.grant_id}`, '_blank');
  };

  const isLoadingSections = useAppSelector((state) =>
    selectIsLoadingSectionsGen(state),
  );

  useEffect(() => {
    if (isGeneratingRemaining) {
      for (const section of grantPromptSectionTitles) {
        const formValue = form.getFieldValue(section);
        if (
          formValue === '' ||
          formValue === null ||
          formValue === undefined ||
          formValue === '<p><br></p>' ||
          formValue === '<h2><br></h2>'
        ) {
          generateSection(section);
        }
      }
    }
    if (isGeneratingAll) {
      generateAllSections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGeneratingRemaining, isGeneratingAll]);

  const generateSection = async (section: keyof GrantFormValues) => {
    try {
      const sectionText = await dispatch(
        generateSingleGrantContentInfo({
          api,
          grantId: grant!.grant_id,
          sectionName: section,
          industrySectorsIDs: [],
        }),
      ).unwrap();
      const titleLabel = transformSectionIntoLabel(section);
      if (sectionText === '') {
        message.info(
          `No answer for ${titleLabel}. Either try again, change the prompt or add some context.`,
        );
      } else {
        form.setFieldValue(section, sectionText);

        message.success(
          `Section ${titleLabel} generated! Don't forget to save.`,
        );
      }
    } catch (e: any) {
      message.error('Error !');
      message.error(e.cause);
    }
  };

  const generateAllSections = async () => {
    for (const section of grantPromptSectionTitles) {
      generateSection(section);
    }
  };

  const displayGrantSections = () => {
    const sections: any[] = [];
    for (const key of grantPromptSectionTitles) {
      sections.push(
        <Col span={12} key={key}>
          <GrantPromptContentSection
            section={key}
            formValues={formValues}
            setSelectedPrompt={setSelectedPrompt}
            setAddNewPromptDrawer={setAddNewPromptDrawer}
            onSave={onSave}
            grant={grant}
            prompts={prompts}
            isTextArea={false}
            generateSection={generateSection}
          />
        </Col>,
      );
    }
    const rows = [];
    for (let i = 0; i < sections.length; i += 2) {
      rows.push(
        <Row gutter={12} key={i}>
          {sections.slice(i, i + 2)}
        </Row>,
      );
    }

    return rows;
  };

  return (
    <>
      {grant?.grant_id !== '' && (
        <Row
          gutter={12}
          justify="space-between"
          style={{ marginBottom: '32px' }}
        >
          <Button
            defaultStyle={theme.colors.purple_2}
            onClick={() => {
              generateAllSections();
            }}
            loading={isLoadingSections}
            disabled={isLoadingSections}
          >
            Generate All
          </Button>
          <Button
            defaultStyle={theme.colors.grey_1}
            onClick={accessScrapeContext}
          >
            See scrape context
          </Button>
        </Row>
      )}
      {displayGrantSections()}
    </>
  );
};

export default GrantFormSections;
