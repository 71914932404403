import { useState } from 'react';
import { useAppSelector } from '../../../../app/app-hooks';
import { selectCompanyById } from '../../../../features/api/slices/companies-slice';
import AdminFilesList from '../../../files/files-list';
import { GinBlockComponentProps } from '../../../gins/gin-single/gin-block';

const CompanyFilesSection = ({
  entityId,
  type,
  isEditing,
  handleEditing,
}: GinBlockComponentProps) => {
  const company = useAppSelector((state) => selectCompanyById(state, entityId));
  const [folderId, setFolderId] = useState<string | undefined>(undefined);

  const handleNavigateToFolder = (folderId: string) => {
    setFolderId(folderId);
  };

  return (
    <AdminFilesList
      owner={{
        owner_id: entityId,
        owner_type: 'company',
        owner_name: company.name,
      }}
      folderId={folderId}
      handleNavigateToFolder={handleNavigateToFolder}
      vaultPath={'/'}
    />
  );
};

export default CompanyFilesSection;
