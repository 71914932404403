import { Path, Svg } from '@react-pdf/renderer';
import theme from '../../../theme';
import { IconNode } from './types';

const NotificationsIcon: IconNode = ({
  width = 32,
  height = 32,
  color = theme.colors.grey_1,
}) => {
  return (
    <Svg viewBox="0 0 32 32" fill="none" width={width} height={height}>
      <Path
        d="M20.4694 23.9088C23.3836 23.5656 26.247 22.8815 29 21.8707C26.6685 19.3017 25.3802 15.9625 25.3854 12.5016V11.4126V11.3348C25.3854 8.85905 24.3966 6.48471 22.6367 4.7341C20.8767 2.98348 18.4897 2 16.0008 2C13.5118 2 11.1248 2.98348 9.36488 4.7341C7.60493 6.48471 6.61619 8.85905 6.61619 11.3348V12.5016C6.62091 15.9627 5.33205 19.302 3 21.8707C5.71058 22.8664 8.56819 23.5587 11.5322 23.9088M20.4694 23.9088C17.5007 24.259 14.5008 24.259 11.5322 23.9088M20.4694 23.9088C20.6948 24.6087 20.7508 25.3516 20.633 26.0771C20.5151 26.8026 20.2267 27.4902 19.7912 28.0839C19.3557 28.6775 18.7854 29.1605 18.1267 29.4935C17.468 29.8264 16.7396 30 16.0008 30C15.2619 30 14.5335 29.8264 13.8749 29.4935C13.2162 29.1605 12.6459 28.6775 12.2104 28.0839C11.7749 27.4902 11.4864 26.8026 11.3686 26.0771C11.2507 25.3516 11.3068 24.6087 11.5322 23.9088"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Svg>
  );
};

export default NotificationsIcon;
