import Div from '@hellodarwin/core/lib/components/common/div';
import Loading from '@hellodarwin/core/lib/components/loading';
import { getShortId } from '@hellodarwin/core/lib/features/helpers';
import { getStringDateFromString } from '@hellodarwin/core/lib/features/helpers/get-formatted-date';
import { getRfpStatusColor } from '@hellodarwin/core/lib/features/helpers/get-status-color';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Industry from '@hellodarwin/icons/dist/icons/Industry';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import Verified from '@hellodarwin/icons/dist/icons/Verified';
import Empty from 'antd/es/empty';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app';
import {
  fetchCompanyRFP,
  selectAllCompanyRFP,
  selectCompaniesIsLoading,
} from '../../../../features/api/slices/companies-slice';
import { useAdminApi } from '../../../../features/api/use-admin-api';
import CardLayout from '../../../gins/sidebars/cards/card-layout';

interface CompanyRFPListProps {
  company_id?: string;
}
const CompanyRFPList: React.FC<CompanyRFPListProps> = ({ company_id }) => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectCompaniesIsLoading);
  const companyRfp = useAppSelector(selectAllCompanyRFP);
  const theme = useTheme();

  useEffect(() => {
    if (!!company_id) {
      dispatch(fetchCompanyRFP({ api, company_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id]);

  if (isLoading) return <Loading />;

  return (
    <Div flex="column" gap={4}>
      {!!companyRfp?.length ? (
        companyRfp.map((p) => (
          <CardLayout
            key={p.rfp_id}
            tag={{
              text: t(`projectStatus|${p.status}`),
              color: getRfpStatusColor(p.status, theme),
              size: 'small',
              withBorder: true,
            }}
            title={p.title ?? `RFP #${getShortId(p.rfp_id)}`}
            infos={[
              {
                Icon: Timeline,
                value: !!p.created_at
                  ? getStringDateFromString(p.created_at, selectedLocale)
                  : undefined,
              },
              {
                Icon: Industry,
                value: !!p.category ? p.category : undefined,
              },
              {
                Icon: Verified,
                value: !!p.premium_at ? 'Premium' : undefined,
              },
            ]}
            to={`/projects/${p.rfp_id}`}
          />
        ))
      ) : (
        <Empty description={t('singleCompanyPage|noRFP')} />
      )}
    </Div>
  );
};

export default CompanyRFPList;
