import styled, { css } from '../../../plugins/styled';

interface RowSelectorButtonProps {
  $isActive?: boolean;
}

const ButtonActiveStyle = css`
  color: ${(props) => props.theme.colors.purple_1};

  cursor: default;
`;

export const RowSelectorButton = styled.div<RowSelectorButtonProps>`
  padding: 6px 9px;
  color: ${(props) => props.theme.colors.grey_4};
  cursor: pointer;
  border-right: 1px solid ${(props) => props.theme.colors.primary};
  &:last-child {
    border: none;
  }
  ${(props) => (props.$isActive ? ButtonActiveStyle : undefined)}
`;

export const RowSelectorContainer = styled.div`
  line-height: 1;
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.white_1};
  overflow: hidden;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  width: fit-content;
  height: fit-content;
`;
