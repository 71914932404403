import Edit from '@hellodarwin/icons/dist/icons/Edit';
import { ReactNode } from 'react';
import { ProfileBlock } from '..';
import { ProfileModalProps } from '../../../../features/entities/profile-entities';
import { Provider } from '../../../../features/entities/providers-entities';
import ProfileBlocks from '../../../../features/enums/profile-blocks';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import SingleHeaderMenu from '../../../common/single-header-menu';
import Typography from '../../../common/typography';
import ProfileActions from '../profile-actions';
import ProfileBanner from '../profile-assets/profile-banner';
import ProfileLogo from '../profile-assets/profile-logo';
import ReviewsAverageCard from './reviews-average-card';

type ProfileHeaderProps = {
  provider: Provider;
  isEditable?: boolean;
  sections: ProfileBlocks[];
  blocks: { [block_name: string]: ProfileBlock };
  providerActions?: ReactNode;
  websiteCTA?: () => void;
  handleLeaveAReview?: () => void;
  toggleEditModal?: ({ open, type, targetId }: ProfileModalProps) => void;
  handleRedirectToSettings?: () => void;
};

const LOGO_SIZE = 128;
const ProfileHeader = ({
  provider,
  isEditable,
  handleRedirectToSettings,
  sections,
  blocks,
  providerActions,
  websiteCTA,
  handleLeaveAReview,
  toggleEditModal,
}: ProfileHeaderProps) => {
  const theme = useTheme();

  const { logo, name, city, province, banner, tagline } = provider;

  return (
    <Div
      style={{ borderRadius: '11px 11px 0 0', overflow: 'hidden' }}
      borderColor={theme.colors.primary}
      backgroundColor={theme.colors.white_1}
    >
      <ProfileBanner
        banner={!!banner ? `${banner}?${new Date().toString()}` : ''}
        name={name}
        isEditable={isEditable}
        toggleEditModal={toggleEditModal}
      />
      <Div
        style={{ paddingBlock: 16, paddingInline: 48, paddingBottom: 32 }}
        xl={{
          flex: 'column',
          style: { paddingInline: 16 },
          align: 'center',
        }}
        flex="row"
        gap={32}
        position="relative"
        justify="space-between"
      >
        <Div flex="row" gap={42} xl={{ flex: 'column', align: 'center' }}>
          <ProfileLogo
            style={{ marginTop: -(LOGO_SIZE / 3 + 24) }}
            name={provider.name}
            logo={logo || ''}
            isEditable={isEditable}
            toggleEditModal={toggleEditModal}
            size={LOGO_SIZE}
          />
          <Div flex="column" gap={12}>
            <Div flex="column" gap={4}>
              <Typography.Title
                level={1}
                elementTheme="h3"
                xl={{ textAlign: 'center' }}
                noMargin
              >
                {provider.name}
              </Typography.Title>
              {!!city && !!province && (
                <Typography
                  color={theme.colors.grey_2}
                  elementTheme="body3"
                  xl={{ textAlign: 'center' }}
                >
                  {[city, province].filter((itm) => !!itm).join(', ')}
                </Typography>
              )}
            </Div>
            {(!!tagline || isEditable) && (
              <Typography
                elementTheme="body2"
                xl={{ textAlign: 'center' }}
                style={{ cursor: isEditable ? 'pointer' : 'default' }}
                onClick={isEditable ? handleRedirectToSettings : undefined}
              >
                {!!tagline.length ? tagline : isEditable ? '<tagline>' : ''}
                {isEditable && (
                  <Edit
                    size={16}
                    onClick={handleRedirectToSettings}
                    style={{
                      color: theme.colors.grey_2,
                      lineHeight: theme.fontsLineHeights.body2,
                      marginInlineStart: 8,
                    }}
                  />
                )}
              </Typography>
            )}
          </Div>
        </Div>
        <Div fitContent flex="row" gap={24}>
          <div style={{ paddingTop: 8 }}>
            <ProfileActions
              providerActions={providerActions}
              websiteCTA={websiteCTA}
            />
          </div>
          {!providerActions && (
            <ReviewsAverageCard
              average={provider.reviews_average || 0}
              handleLeaveAReview={handleLeaveAReview}
              mobileStyle={{ position: 'absolute', top: 16, right: 16 }}
            />
          )}
        </Div>
      </Div>
      <SingleHeaderMenu
        items={sections
          .filter((key) => !blocks[key].hide)
          .map((key) => ({
            label: blocks[key].label,
            id: key,
          }))}
      />
    </Div>
  );
};

export default ProfileHeader;
