import { WebsiteGrantResult } from '../../../../../features/entities/grants-entities';
import GrantEligibilitySections from '../../../../../features/enums/grant-eligibility-sections';
import parse from '../../../../../features/helpers/parse';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { grantSingleParseOptions } from '../../styles';
import AdmissibilitySectionTile from './AdmissibilitySectionTile';

const GrantAdmissibility = ({
  eligibility_criteria,
  who_can_apply,
  who_cannot_apply,
  eligible_expenses,
  project_activity,
  zone,
}: WebsiteGrantResult) => {
  const { t } = useTranslations();

  const showSection = (field?: string) => {
    return !!field && field !== '' && field !== '<p><br></p>';
  };

  return (
    <>
      {showSection(eligibility_criteria) && !!eligibility_criteria && (
        <div>{parse(eligibility_criteria, grantSingleParseOptions)}</div>
      )}
      {showSection(who_can_apply) && (
        <AdmissibilitySectionTile
          label={t(
            `grant_eligibility_criteria|${GrantEligibilitySections.WhoCanApply}`,
          )}
          content={who_can_apply}
        />
      )}
      {showSection(who_cannot_apply) && (
        <AdmissibilitySectionTile
          label={t(
            `grant_eligibility_criteria|${GrantEligibilitySections.WhoCannotApply}`,
          )}
          content={who_cannot_apply}
        />
      )}
      {showSection(eligible_expenses) && (
        <AdmissibilitySectionTile
          label={t(
            `grant_eligibility_criteria|${GrantEligibilitySections.EligibleExpenses}`,
          )}
          content={eligible_expenses}
        />
      )}

      {showSection(zone) && (
        <AdmissibilitySectionTile
          label={t(
            `grant_eligibility_criteria|${GrantEligibilitySections.Zone}`,
          )}
          content={zone}
        />
      )}
    </>
  );
};

export default GrantAdmissibility;
