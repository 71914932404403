import Empty from 'antd/es/empty';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import ReviewRatings from '../../../../reviews/ratings';

export type Sections =
  | 'About'
  | 'Video'
  | 'Reviews'
  | 'Portfolio'
  | 'Team'
  | 'Expertise';

export interface EmptySectionProps {
  handleClick?: () => void;
  cta?: string;
  title?: string;
  subtitle?: string;
  isEditable?: boolean;
  sectionName: Sections;
}

const EmptySection = ({
  handleClick,
  cta,
  title,
  subtitle,
  isEditable,
  sectionName,
}: EmptySectionProps) => {
  const theme = useTheme();

  return (
    <Div flex="column" gap={8} style={{ paddingBlock: 24 }}>
      <Empty
        imageStyle={{ height: 'auto', margin: 0 }}
        image={
          sectionName === 'Reviews' ? (
            <ReviewRatings size="extra-large" disabled defaultValue={0} />
          ) : (
            Empty.PRESENTED_IMAGE_DEFAULT
          )
        }
        description=""
      />
      {(!!isEditable || !!title) && (
        <Div flex="column" align="center" gap={12}>
          {!!title?.length && (
            <Typography
              textAlign="center"
              elementTheme="body1"
              color={theme.colors.grey_1}
            >
              {title}
            </Typography>
          )}
          {isEditable && (
            <>
              {!!subtitle?.length && (
                <Typography color={theme.colors.grey_2} textAlign="center">
                  {subtitle}
                </Typography>
              )}
              {!!handleClick && !!cta?.length && (
                <Button
                  onClick={handleClick}
                  fitContent
                  defaultStyle={theme.colors.purple_1}
                  size="small"
                >
                  {cta}
                </Button>
              )}
            </>
          )}
        </Div>
      )}
    </Div>
  );
};

export default EmptySection;
