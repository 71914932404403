import Empty from 'antd/es/empty';
import Form from 'antd/es/form/Form';
import { useCallback, useMemo } from 'react';
import { GrantResult } from '../../../features/entities';
import { usePagination } from '../../../features/providers/pagination-provider';
import { usePlatform } from '../../../features/providers/platform-provider';
import { useTranslation } from '../../../plugins/i18n';
import theme from '../../../theme';
import Container from '../../common/container';
import List from '../../common/List';
import { Tablet } from '../../common/Media';
import Loading from '../../loading';
import GrantCard from '../card';
import FundingExplorerFilter, { FundingExplorerFilterProps } from './filter';
import FilterProjects from './filter/filter-projects';
import {
  FundingExplorerFilterValues,
  InitialFundingExplorerFilterValues,
} from './filter/types';
import getFeaturedTags from './filter/utils/get-featured-tags';
import FundingExplorerListLocked from './funding-explorer-list-locked';
import useApplyFundingExplorerFilter from './hooks/apply-filters';
type FundingExplorerType =
  | 'only-header'
  | 'all'
  | 'only-header-and-projects'
  | 'only-projects'
  | 'only-grants-list'
  | 'only-header-and-grants-list';
export interface FundingExplorerProps
  extends Omit<
    FundingExplorerFilterProps,
    'handleProjectSelection' | 'resetFilters' | 'resetFiltersWithCompany'
  > {
  goToSettings: () => void;

  grants: GrantResult[];
  fetch: (values: FundingExplorerFilterValues) => Promise<void>;
  type?: FundingExplorerType;
  showCompanyFilterCardState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ];
}

const FundingExplorer = (props: FundingExplorerProps) => {
  const {
    filterform,
    goToSettings,
    isLoading,
    grants,
    company,
    type = 'all',
    featuredProjects,
    filterValues,
  } = props;

  const initialValues = {
    ...InitialFundingExplorerFilterValues,
  };

  const { app } = usePlatform();
  const { t } = useTranslation();
  const { activePage, pageSize } = usePagination();

  const {
    filtersLoading,
    isInitialised,
    resetFilters,
    resetFiltersWithCompany,
    handlePageChange,
  } = useApplyFundingExplorerFilter(props);

  const hasGrants = useMemo(
    () => grants !== undefined && grants?.length > 0,
    [grants],
  );

  const missingDefaultFields = useMemo(
    () =>
      !company?.company_id ||
      !company.name ||
      !company.annual_revenue ||
      !company.industry_sector ||
      !company.size ||
      !company.province,
    [company],
  );

  const isLocked = useMemo(
    () => app !== 'admin' && missingDefaultFields,
    [missingDefaultFields, app],
  );

  const stateIsLoading = useMemo(
    () => isLoading || filtersLoading,
    [filtersLoading, isLoading],
  );

  const filterProps: FundingExplorerFilterProps = {
    ...props,
    resetFilters,
    resetFiltersWithCompany,
    isLoading: stateIsLoading,
    filterValues,
  };

  const getFeaturedTagsCallback = useCallback(getFeaturedTags, [
    filterValues?.selectedProjects,
  ]);

  return (
    <Form form={filterform} layout="vertical" initialValues={initialValues}>
      {!['only-projects', 'only-grants-list'].includes(type) && (
        <FundingExplorerFilter {...filterProps} />
      )}

      {!['only-projects', 'only-header', 'only-header-and-projects'].includes(
        type,
      ) && (
        <Container
          style={{
            marginTop: 24,
            position: 'relative',

            minHeight: `calc(100vh - ${theme.appearance.pageHeaderHeight + theme.appearance.appHeaderHeight + 71 + 12 + 32}px)`,
          }}
        >
          {!isInitialised && <Loading />}
          <List
            renderEmpty={() => <Empty description={t('grants|noGrants')} />}
            loading={stateIsLoading}
            dataSource={grants}
            renderItem={(grant) => (
              <GrantCard
                featuredTags={getFeaturedTagsCallback(
                  grant,
                  filterValues?.selectedProjects ?? [],
                  featuredProjects,
                )}
                {...grant}
              />
            )}
            pagination={{
              total: hasGrants ? grants?.[0]?.total_count ?? 0 : 0,
              currentPage: activePage,
              onPageChange: handlePageChange,
              pageSize,
            }}
          />
          <FundingExplorerListLocked
            isLocked={isLocked}
            goToSettings={goToSettings}
          />
        </Container>
      )}
      {!isLocked &&
        ![
          'only-header',
          'only-projects',
          'only-header-and-grants-list',
        ].includes(type) && (
          <Tablet direction="up">
            <FilterProjects {...filterProps} />
          </Tablet>
        )}
    </Form>
  );
};

export default FundingExplorer;
