import { DocumentProps, PDFDownloadLink, usePDF } from '@react-pdf/renderer';
import { PropsWithChildren } from 'react';

export interface DarwinPdfDownloadProps extends PropsWithChildren {
  document: React.ReactElement<DocumentProps>;
  fileName: string;
}

const DarwinPdfDownload: React.FC<DarwinPdfDownloadProps> = ({
  document,
  fileName,
  children,
}) => {
  const [instance, update] = usePDF({ document });

  instance.blob;
  return (
    <PDFDownloadLink fileName={fileName} document={document}>
      {children}
    </PDFDownloadLink>
  );
};

export default DarwinPdfDownload;
