import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Checkbox from 'antd/es/checkbox';
import { FormInstance } from 'antd/es/form/Form';
import { BaseOptionType, DefaultOptionType, SelectProps } from 'antd/es/select';
import { CSSProperties, ReactNode } from 'react';
import { GrantStatus } from '../../../../../features/entities/grants-entities';
import { useTranslation } from '../../../../../plugins/i18n';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import HdTag from '../../../../common/hd-tag';
import Typography from '../../../../common/typography';
import FormItem from '../../../../forms/form-layouts/form-item';
import GrantStatusTag from '../../../card/grant-status-tag';
import { FilterMultipleSelectStyled, FilterSelectDropdown } from '../styles';
import { FundingExplorerFilterValues } from '../types';
import getFilterTagBarTheme from '../utils/get-filter-tag-bar-theme';

interface FilterMultipleSelectProps {
  label: string;
  name: string;
  placeholder: string;
  options: SelectProps['options'];
  filterform: FormInstance<FundingExplorerFilterValues>;
  onChange?: SelectProps['onChange'];
  isMultiple?: boolean;
  isStatusTag?: boolean;
  notFoundContent?: ReactNode;
}

const FilterMultipleSelect = ({
  label,
  name,
  placeholder,
  options,
  filterform,
  onChange,
  isMultiple,
  isStatusTag,
  notFoundContent,
}: FilterMultipleSelectProps) => {
  const theme = useTheme();

  const filterOption = (
    input: string,
    option: DefaultOptionType | undefined,
  ) => {
    if (!option?.label) return false;
    const comparingKey = `${option.label}`;
    return comparingKey.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  };

  const checkIfSelected = (value: string, fieldValue: string | string[]) => {
    const fieldValueArray = Array.isArray(fieldValue) ? fieldValue : [];
    return fieldValueArray.includes(value);
  };

  const dropdownStyle: CSSProperties = {
    padding: 0,
    borderRadius: 0,
    border: `1px solid ${theme.colors.primary}`,
  };
  const dropdownRender: SelectProps['dropdownRender'] = (el) => (
    <FilterSelectDropdown>{el}</FilterSelectDropdown>
  );

  const optionRender = (option: BaseOptionType) => {
    return (
      <Div
        flex="row"
        gap={8}
        align="center"
        borderColor={theme.colors.grey_4}
        bordersSide="bottom"
        justify="space-between"
        style={{ padding: '12px 16px' }}
      >
        <Typography
          elementTheme="body3"
          color={theme.colors.primary}
          ellipsis
          overflow
          nowrap
        >
          {option.label}
        </Typography>
        {isMultiple && (
          <Checkbox
            type="square"
            checked={checkIfSelected(
              option.value.toString(),
              filterform.getFieldValue(
                name as keyof FundingExplorerFilterValues,
              ),
            )}
          />
        )}
      </Div>
    );
  };

  const optionRenderStatusTag = (option: BaseOptionType) => {
    return (
      <Div
        flex="row"
        gap={8}
        align="center"
        borderColor={theme.colors.grey_4}
        bordersSide="bottom"
        justify="space-between"
        style={{ padding: '12px 16px' }}
      >
        <GrantStatusTag status={option.value as GrantStatus} />

        {isMultiple && (
          <Checkbox
            type="square"
            checked={checkIfSelected(
              option.value.toString(),
              filterform.getFieldValue(
                name as keyof FundingExplorerFilterValues,
              ),
            )}
          />
        )}
      </Div>
    );
  };

  return (
    <FormItem label={label} name={name} style={{ margin: 0 }} layout="vertical">
      <FilterMultipleSelectStyled
        mode={isMultiple ? 'multiple' : undefined}
        style={{ width: '100%' }}
        onChange={onChange}
        options={options}
        filterOption={filterOption}
        tagRender={({ label, onClose, value }) => (
          <HdTag
            onClose={onClose}
            text={label}
            size="small"
            style={{ marginRight: 2, overflow: 'hidden' }}
            color={getFilterTagBarTheme(name, theme, value)}
          />
        )}
        size="middle"
        maxTagCount={3}
        dropdownRender={dropdownRender}
        dropdownStyle={dropdownStyle}
        menuItemSelectedIcon={<></>}
        suffixIcon={<TabArrow width={8} />}
        placeholder={placeholder}
        optionRender={isStatusTag ? optionRenderStatusTag : optionRender}
        notFoundContent={notFoundContent}
      />
    </FormItem>
  );
};

export default FilterMultipleSelect;
