import useApolloClient from '@hellodarwin/core/lib/features/hooks/use-appollo-client';
import AppProvider from '@hellodarwin/core/lib/features/providers/app-data-provider';
import AppTranslationsProvider from '@hellodarwin/core/lib/features/providers/app-translations-provider';
import Auth0ProviderWithNavigate, {
  OauthOptions,
} from '@hellodarwin/core/lib/features/providers/auth0-provider-with-navigate';
import BreadcrumbsProvider from '@hellodarwin/core/lib/features/providers/breadcrumbs-provider';
import { LocaleProvider } from '@hellodarwin/core/lib/features/providers/locale-provider';
import PaginationProvider from '@hellodarwin/core/lib/features/providers/pagination-provider';
import PlatformProvider from '@hellodarwin/core/lib/features/providers/platform-provider';
import AppRouterProvider from '@hellodarwin/core/lib/features/providers/router-provider';
import { ApolloProvider } from '@hellodarwin/core/lib/plugins/apollo';
import { AxiosError } from '@hellodarwin/core/lib/plugins/axios';
import { I18nextProvider } from '@hellodarwin/core/lib/plugins/i18n/provider';
import { ThemeProvider } from '@hellodarwin/core/lib/plugins/styled';
import { GlobalTypographyStyling } from '@hellodarwin/core/lib/theme/global-style';
import ConfigProvider from 'antd/es/config-provider';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AdminApp from './AdminApp';
import { store } from './app/app-store';
import { fetchOauthOptions } from './auth0/use-auth0';
import ActionBarProvider from './context/action-bar-provider';
import i18n from './i18n';
import * as serviceWorker from './serviceWorker';
import ServiceTagsProvider from './store/service-tags-provider';
import theme from './theme';

import './AdminApp.scss';
import AdminSettingsProvider from './features/providers/admin-settings-provider';

const root = ReactDOM.createRoot(document.getElementById('root')!);
const DefaultLayout = () => {
  const client = useApolloClient(
    'modxx330knqj',
    'P8xozOvf7K4HtnL7xpr7Sp5aO1FV4WLyJlCquu9tPz8',
  );

  const [config, setConfig] = useState<OauthOptions>();
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    fetchOauthOptions('admin')
      .then((config) => setConfig(config))
      .catch((err) => setError(err));
  }, []);

  return (
    <PlatformProvider app="admin">
      <Provider store={store}>
        <BrowserRouter>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: theme.colors.purple_1,
                colorLink: theme.colors.purple_2,
                colorLinkActive: theme.colors.purple_3,
                colorLinkHover: theme.colors.purple_1,
              },
              components: {
                Menu: {
                  itemMarginInline: 0,
                  margin: 0,
                  controlHeightLG: 20,
                  borderRadiusLG: 0,
                  padding: 0,
                  marginXXS: 0,
                  itemColor: theme.colors.grey_2,
                  itemBg: theme.colors.purple_5,
                  itemSelectedColor: theme.colors.purple_2,
                  itemSelectedBg: theme.colors.purple_5,
                  itemHoverColor: theme.colors.purple_1,
                  itemHoverBg: theme.colors.purple_4,
                  subMenuItemBg: theme.colors.purple_5,

                  darkItemColor: theme.colors.white_1,
                  darkItemBg: theme.colors.grey_1,
                  darkItemSelectedColor: theme.colors.purple_3,
                  darkItemSelectedBg: theme.colors.grey_1,
                  darkItemHoverColor: theme.colors.purple_4,
                  darkItemHoverBg: theme.colors.grey_1,
                  darkSubMenuItemBg: theme.colors.grey_1,
                  colorSplit: 'transparent',
                },
                Dropdown: {
                  controlPaddingHorizontal: 0,
                  colorText: theme.colors.grey_3,
                  paddingXXS: 10,
                },
                Input: {
                  borderRadius: 4,
                  borderRadiusLG: 4,
                  fontSize: 14,
                  fontSizeLG: 16,
                  colorBgBase: theme.colors.white_1,
                  colorText: theme.colors.grey_1,
                  colorTextPlaceholder: theme.colors.grey_3,
                  colorBorder: theme.colors.primary,
                  colorTextDescription: theme.colors.grey_2,
                  paddingBlock: 4,
                  paddingInline: 12,
                  paddingBlockLG: 8,
                  paddingInlineLG: 16,
                },
                InputNumber: {
                  borderRadius: 4,
                  borderRadiusLG: 4,
                  fontSize: 14,
                  fontSizeLG: 16,
                  colorBgBase: theme.colors.white_1,
                  colorText: theme.colors.grey_1,
                  colorTextPlaceholder: theme.colors.grey_3,
                  colorBorder: theme.colors.primary,
                  colorTextDescription: theme.colors.grey_2,
                  paddingBlock: 4,
                  paddingInline: 12,
                  paddingBlockLG: 8,
                  paddingInlineLG: 16,
                },
                DatePicker: {
                  borderRadius: 4,
                  borderRadiusLG: 4,
                  fontSize: 14,
                  fontSizeLG: 16,
                  colorBgBase: theme.colors.white_1,
                  colorText: theme.colors.grey_1,
                  colorTextPlaceholder: theme.colors.grey_3,
                  colorBorder: theme.colors.primary,
                  colorTextDescription: theme.colors.grey_2,
                  paddingBlock: 4,
                  paddingInline: 12,
                  paddingBlockLG: 8,
                  paddingInlineLG: 16,
                },
                Select: {
                  borderRadius: 4,
                  borderRadiusLG: 4,
                  fontSize: 14,
                  fontSizeLG: 16,
                  colorBgBase: theme.colors.white_1,
                  colorText: theme.colors.grey_1,
                  colorTextPlaceholder: theme.colors.grey_3,
                  colorBorder: theme.colors.primary,
                  colorTextDescription: theme.colors.grey_2,
                },
                Layout: {
                  headerBg: theme.colors.background,
                  bodyBg: theme.colors.background,
                  footerBg: theme.colors.background,
                  siderBg: theme.colors.grey_1,
                  lightSiderBg: theme.colors.purple_5,
                  fontSize: 16,
                },
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <GlobalTypographyStyling />
              <LocaleProvider>
                <ApolloProvider client={client}>
                  <AppTranslationsProvider>
                    <I18nextProvider defaultNS={'common'} i18n={i18n}>
                      <Auth0ProviderWithNavigate
                        config={config}
                        error={error}
                        app="admin"
                      >
                        <ActionBarProvider>
                          <ServiceTagsProvider>
                            <AppRouterProvider>
                              <BreadcrumbsProvider>
                                <PaginationProvider defaultPageSize={50}>
                                  <AppProvider>
                                    <AdminSettingsProvider>
                                      <AdminApp />
                                    </AdminSettingsProvider>
                                  </AppProvider>
                                </PaginationProvider>
                              </BreadcrumbsProvider>
                            </AppRouterProvider>
                          </ServiceTagsProvider>
                        </ActionBarProvider>
                      </Auth0ProviderWithNavigate>
                    </I18nextProvider>
                  </AppTranslationsProvider>
                </ApolloProvider>
              </LocaleProvider>
            </ThemeProvider>
          </ConfigProvider>
        </BrowserRouter>
      </Provider>
    </PlatformProvider>
  );
};

root.render(<DefaultLayout />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
