import { ExpandableConfig } from 'antd/es/table/interface';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  AdminProjectResponse,
  ProjectProgram,
} from '../../../features/entities/projects-entities';
import Div from '../../common/div';
import { ProjectsTableLevel2 } from '../main-project-table/styles';
import useExpandedGrantRowRender from './use-expanded-grants-row-render';
import { ProjectsListStateValues } from './use-projects-list-state';

interface useExpandedProjectsRowRenderProps {
  projectsListState: ProjectsListStateValues;
}

const useExpandedProjectsRowRender = ({
  projectsListState,
}: useExpandedProjectsRowRenderProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const expandedGrantRowRender = useExpandedGrantRowRender({
    projectsListState,
  });

  return useMemo(() => {
    const {
      projectsColumns: { expandProgramColumns },
      applicationsPath,
    } = projectsListState;

    const expandedProjectsRowRender: ExpandableConfig<AdminProjectResponse>['expandedRowRender'] =
      (record) => {
        return (
          <Div
            flex="column"
            gap={16}
            backgroundColor={theme.colors.grey_5}
            style={{
              paddingInline: 24,
              paddingBlock: 16,
            }}
          >
            {!!record.grants?.length && (
              <ProjectsTableLevel2<ProjectProgram>
                columns={expandProgramColumns.map((col, i) =>
                  i === 0 ? { ...col, width: 500 - 24 } : col,
                )}
                dataSource={record.programs}
                pagination={false}
                rowKey={(g) => g.program_id}
                onRow={(g) => {
                  return {
                    onDoubleClick: () => {
                      navigate(`${applicationsPath}/${g.program_id}`);
                    },
                  };
                }}
                rowClassName={(_r, i) => (i % 2 ? 'odd' : 'even')}
                scroll={{ x: 'max-content' }}
              />
            )}
          </Div>
        );
      };
    return expandedProjectsRowRender;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsListState, expandedGrantRowRender]);
};

export default useExpandedProjectsRowRender;
