import { RcFile } from 'rc-upload/lib/interface';
import { ProviderInitialState } from './providers-entities';

export interface Portfolio {
  provider_portfolio_id: string;
  provider_id: string;
  description: string;
  rfp_url: string;
  client_company_name: string;
  from_hellodarwin_at: string | null;
  curated_at?: string;
  public_at?: string;
  created_at?: string;
  updated_at?: string;
  thumbnail: string;
  category: string;
  service: string;
  tags: string[];
  assets: PortfolioAsset[];
  portfolio_title: string;
  linked_in?: string;
  client_name?: string;
  portfolio_picture?: string;
  rfp_id?: string;
  specialties?: string[];
}

export const PortfolioInitialState = {
  provider_portfolio_id: '',
  provider_id: '',
  description: '',
  rfp_url: '',
  client_company_name: '',
  from_hellodarwin_at: '',
  curated_at: '',
  public_at: '',
  created_at: '',
  updated_at: '',
  thumbnail: '',
  category: '',
  service: '',
  tags: [],
  assets: [],
  provider: ProviderInitialState,
  portfolio_title: '',
  linked_in: '',
  client_name: '',
  portfolio_picture: '',
  rfp_id: '',
  specialties: '',
};

export interface MappedPortfolios {
  [key: string]: Portfolio;
}

export interface MappedPortfolioAssets {
  [key: string]: PortfolioAsset;
}

export interface MappedAssetsByPortfolio {
  [key: string]: PortfolioAsset[];
}

export interface PortfolioAsset {
  provider_portfolio_asset_id?: string;
  provider_portfolio_id: string;
  file_extension?: string;
  pos: number;
  asset_url?: string;
  asset_type: PortfolioAssetsType;
  file?: string | RcFile | Blob;
  temp_id?: string;
  filename?: string;
  deleted?: boolean;
}

export interface ProviderPortfolio {
  provider_id?: string;
  provider_portfolio_id?: string;
  description: string;
  rfp_url: string;
  client_company_name: string;
  from_hellodarwin_at: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  thumbnail: string;
  category: string;
  service: string;
  tags: string[];
  assets: string[];
  public_at: string;
  curated_at?: string;
  title: string;
}

export type PortfolioRequest = {
  provider_id: string;
  provider_portfolio_id?: string;
  portfolio_title?: string;
  description: string;
  client_company_name: string;
  rfp_url: string;
  tags: string[];
  made_with_hellodarwin: boolean;
  public: boolean;
  curated?: boolean;
};

export enum PortfolioAssetsType {
  Video = 'Video',
  Image = 'Image',
}

export type PortfolioAssetRequest = {
  provider_portfolio_id: string;
  asset_pos: number;
  asset_type: PortfolioAssetsType;
  asset_url: string;
};
