import { Path, Svg } from '@react-pdf/renderer';
import { IconNode } from './types';
import theme from '../../../theme';

const InvoicesIcon: IconNode = ({
  width = 32,
  height = 32,
  color = theme.colors.grey_1,
}) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 32 32" fill="none">
      <Path
        d="M13.25 1H6.65C5.7392 1 5 1.77538 5 2.73077V29.2692C5 30.2246 5.7392 31 6.65 31H25.35C26.2608 31 27 30.2246 27 29.2692V15.4231M13.25 1H13.8C17.3009 1 20.6583 2.45879 23.1338 5.05544C25.6093 7.6521 27 11.1739 27 14.8462V15.4231M13.25 1C14.5628 1 15.8219 1.54705 16.7502 2.52079C17.6785 3.49454 18.2 4.81522 18.2 6.19231V8.5C18.2 9.45538 18.9392 10.2308 19.85 10.2308H22.05C23.3628 10.2308 24.6219 10.7778 25.5502 11.7516C26.4785 12.7253 27 14.046 27 15.4231M16 12.9231V26.7692M13.0667 23.5177L13.9261 24.2781C15.0711 25.2923 16.9279 25.2923 18.0739 24.2781C19.2198 23.2638 19.2198 21.6208 18.0739 20.6065C17.5019 20.0988 16.7509 19.8462 16 19.8462C15.2911 19.8462 14.5822 19.5923 14.0415 19.0858C12.9601 18.0715 12.9601 16.4285 14.0415 15.4142C15.1229 14.4 16.8771 14.4 17.9585 15.4142L18.3643 15.795"
        stroke={color}
        fill="none"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default InvoicesIcon;
