import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  fetchCompany,
  selectCompaniesIsLoading,
  selectCompanyById,
} from '../../../features/api/slices/companies-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';
import GinBlock from '../../gins/gin-single/gin-block';
import { GinSingleContainer } from '../../gins/gin-single/styles';
import SingleCompanyHeader from './single-company-header';
import useCompanyBlocks, {
  CompanySinglePageSections,
} from './single-company-header/use-company-blocks';

interface CompanySingleProps {
  company_id?: string;
  sections: CompanySinglePageSections[];
}

const CompanySingle: React.FC<CompanySingleProps> = ({
  company_id,
  sections,
}) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const blocks = useCompanyBlocks();

  const isLoading = useAppSelector(selectCompaniesIsLoading);
  const company = useAppSelector((state) =>
    selectCompanyById(state, company_id ?? ''),
  );
  const activeSections = sections.filter(
    (s) => !!blocks[s] && !blocks[s]?.hide,
  );

  const { selectedLocale } = useLocale();

  useEffect(() => {
    dispatch(
      fetchCompany({
        api,
        companyId: company_id || '',
        locale: selectedLocale,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id]);

  if (isLoading || !company?.company_id) return <></>;
  return (
    <GinSingleContainer>
      <SingleCompanyHeader company_id={company_id} sections={activeSections} />
      {React.Children.toArray(
        activeSections.map((s) => (
          <GinBlock
            key={s}
            {...blocks[s]}
            entityId={company_id ?? ''}
            type="company"
            translationFile="singleCompanyPage"
          />
        )),
      )}
    </GinSingleContainer>
  );
};

export default CompanySingle;
