import { StyleSheet } from '@react-pdf/renderer';
import theme from '../../../theme';

export const TableStyles = StyleSheet.create({
  table: {
    border: `1px solid ${theme.colors.grey_4}`,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  tableColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 0',
  },
  tableColumnHeader: {
    height: 34,
    padding: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.purple_2,
  },
  tableColumnContent: {
    height: 85,
    padding: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.beige_4,
  },
});
