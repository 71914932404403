import { StyleSheet } from '@react-pdf/renderer';
import theme from '../../../theme';

export const CardWithIconStyles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.white_1,
    border: `1px solid ${theme.colors.grey_1}`,
    padding: '8px 16px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.colors.grey_1}`,
    borderRadius: 99,
    position: 'absolute',
    padding: 4,
  },
  verticalDivider: {
    height: 1,
    borderBottom: `1px solid ${theme.colors.grey_1}`,
    position: 'absolute',

    padding: 1,
    zIndex: 10,
  },
  horizontalDivider: {
    width: 1,
    borderLeft: `1px solid ${theme.colors.grey_1}`,
    position: 'absolute',

    padding: 1,
    zIndex: 10,
  },
});
