const theme = {
  colors: {
    primary: '#2b2b2b',
    secondary: '#ced000',
    tertiary: '#2b2b2b',
    quaternary: '#ffffff',
    background: '#f7f1eb',

    success: '',

    black: '#2b2b2b',

    white_1: '#ffffff',
    white_2: 'rgba(255, 255, 255, 0.60)',

    beige_1: '#f2e8dd',
    beige_2: '#f7f1eb',
    beige_3: '#fcfaf8',
    beige_4: '#fdfcfa',
    beige_1_pale: 'rgba(#f2e8dd, 0.1)',

    green_1: '#00f0af',
    green_2: '#5affcc',
    green_3: '#a8ffde',
    green_4: '#e6fff7',
    green_1_pale: 'rgba(#00f0af, 0.1)',
    green_high_contrast: '#2EE69B',
    green_status: '#00C288',

    grey_1: '#2b2b2b',
    grey_2: '#5c5c5c',
    grey_3: '#858585',
    grey_4: '#bbbbbb',
    grey_5: '#eeeeee',
    grey_1_pale: 'rgba(#2b2b2b, 0.1)',

    purple_1: '#5d0b71',
    purple_2: '#89178f',
    purple_3: '#b861d8',
    purple_4: '#efcaff',
    purple_5: '#fcf4ff',
    purple_6: '#f3eef9',
    purple_1_pale: 'rgba(#5d0b71, 0.1)',

    yellow_1: '#ced000',
    yellow_2: '#f0e74f',
    yellow_3: '#f9f299',
    yellow_4: '#fbfadf',
    yellow_1_pale: 'rgba(#ced000, 0.1)',
    yellow_star: '#D0BB00',

    error: '#f82e47',
    red_1: '#ff4e4e',
    red_2: '#ff5e5e',
    red_3: '#ff7070',
    red_4: '#FF88A4',
    red_5: '#ffb9c2',
    red_1_pale: 'rgba(#ff4e4e, 0.1)',

    orange_1: '#FF7A00',
    orange_2: '#FF9E4C',
    orange_3: '#FFBF94',
    orange_4: '#FFEEDE',

    blue_1: '#0085FF',
    blue_2: '#4CA6FF',
    blue_3: '#94CDFF',
    blue_4: '#DEF7FF',

    transparent: 'transparent',

    gradient: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3))',
  },

  fonts: {
    h1: '70px',
    h1_md: '52px',

    h2: '52px',
    h2_md: '40px',

    h3: '40px',
    h3_md: '32px',

    h4: '32px',
    h4_md: '28px',

    h5: '28px',
    h5_md: '24px',

    h6: '22px',
    h6_md: '20px',

    subtitle1: '20px',
    subtitle1_md: '18px',

    subtitle2: '18px',
    subtitle2_md: '16px',

    body1: '18px',
    body1_md: '16px',

    body2: '16px',
    body2_md: '14px',

    body3: '14px',
    body3_md: '14px',

    body4: '12px',
    body4_md: '12px',

    caption: '14px',
    caption_md: '12px',

    overline: '11px',
    overline_md: '10px',

    button_large: '18px',
    button_large_md: '16px',

    button_medium: '16px',
    button_medium_md: '15px',

    button_small: '15px',
    button_small_md: '14px',

    button_extra_small: '15px',
    button_extra_small_md: '14px',

    buttonA: '18px',
    buttonA_md: '18px',

    buttonB: '16px',
    buttonB_md: '16px',

    buttonC: '15px',
    buttonC_md: '15px',

    link_l: '20px', // min 1200px
    link_md: '20px', // min 960px
    link: '18px', // min 0
  },
  fontsLineHeights: {
    h1: '66px',
    h1_md: '52px',

    h2: '54px',
    h2_md: '42px',

    h3: '42px',
    h3_md: '36px',

    h4: '34px',
    h4_md: '32px',

    h5: '30px',
    h5_md: '28px',

    h6: '24px',
    h6_md: '24px',

    subtitle1: '28px',
    subtitle1_md: '26px',

    subtitle2: '24px',
    subtitle2_md: '20px',

    body1: '28px',
    body1_md: '28px',

    body2: '24px',
    body2_md: '24px',

    body3: '18px',
    body3_md: '18px',

    body4: '14px',
    body4_md: '14px',

    caption: '16px',
    caption_md: '14px',

    overline: '12px',
    overline_md: '12px',

    link_l: '0', // min 1200px
    link_md: '0', // min 960px
    link: '0', // min 0
  },
  letterSpacing: {
    h1: 0,
    h1_md: 0,

    h2: 0,
    h2_md: 0,

    h3: 0,
    h3_md: 0,

    h4: 0,
    h4_md: 0,

    h5: 0,
    h5_md: 0,

    h6: 0,
    h6_md: 0,

    subtitle1: 0,
    subtitle1_md: 0,

    subtitle2: 0,
    subtitle2_md: 0,

    body1: 0,
    body1_md: 0,

    body2: 0,
    body2_md: 0,

    body3: 0,
    body3_md: 0,

    body4: 0,
    body4_md: 0,

    caption: 0,
    caption_md: 0,

    overline: 0.36,
    overline_md: 0.36,

    button_large: 0.36,
    button_large_md: 0.36,

    button_medium: 0.16,
    button_medium_md: 0.16,

    button_small: 0,
    button_small_md: 0,

    button_extra_small: 0,
    button_extra_small_md: 0,

    link_l: 0,
    link_md: 0,
    link: 0,
  },

  fontWeights: {
    bold: 700,
    semibold: 600,
    medium: 500,
    regular: 400,
    light: 300,
    headings: 600,
  },
};

export default theme;
