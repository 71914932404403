import { Dayjs } from 'dayjs';
import CompanyType from '../enums/company-type';
import Language from '../enums/language';
import Province from '../enums/province';
import SingleLanguage from '../enums/single-language';
import { Contact } from './contact-entities';
import { Match } from './matches-entities';
import { Rfp } from './rfp-entities';

export interface CompanyAsset {
  asset_id: string;
  company_id: string;
  asset_url: string;
  asset_title: string;
  created_at?: string;
  updated_at?: string;
}

export interface CompanyQuickInfos {
  company_id: string;
  rfps_count: number;
  latest_activated_rfp: string;
  company_size: string;
}

export interface CompanyRequest {
  company_id?: string;
  company_description?: string;
  company_founded_at?: string | Dayjs | null;
  contact_name?: string;
  contact_email?: string;
  contact_phone?: string;
  contact_preferred_language?: string;
  contact_gender?: string;
  contact_title?: string;
  company_name?: string;
  company_address?: string;
  company_email?: string;
  company_website?: string;
  company_city?: string;
  company_postal_code?: string;
  company_province?: string;
  company_country?: string;
  company_size?: string;
  company_phone?: string;
  company_industry?: string;
  terms_and_conditions?: boolean;
  rfp_language?: Language;
  profile_language?: SingleLanguage;
  lat?: number;
  lng?: number;
  company_type?: CompanyType;
  company_tagline?: string;
  provider_hourly_rate?: number;
  facebook?: string;
  instagram?: string;
  linked_in?: string;
  tiktok?: string;
  twitter?: string;
  pinterest?: string;
  youtube?: string;
}

export interface Company {
  company_id: string;
  name: string;
  industry?: string;
  type?: CompanyType;
  size?: string;
  territory?: string;
  description?: string;
  website?: string;
  email?: string;
  phone?: string;
  ext?: string;
  address?: string;
  city?: string;
  postal_code?: string;
  province?: Province | string;
  country?: string;
  facebook?: string;
  twitter?: string;
  linked_in?: string;
  instagram?: string;
  pinterest?: string;
  youtube?: string;
  tiktok?: string;
  logo?: string;
  banner?: string;
  contacts?: Contact[];
  annual_revenue?: string;
  rfp_language?: Language;
  profile_language?: SingleLanguage;
  comments?: string;
  business_number?: string;
  terms_and_conditions?: boolean;
  deleted_at?: string;
  updated_at?: string;
  created_at?: string;
  founded_at?: string | null;
  rfps?: Rfp[];
  matches?: Match[];
  assets?: CompanyAsset[];
  short_description?: string;
  credit_balance?: string;
  network?: string;
  lat?: number;
  lng?: number;
  tagline?: string;
  is_propulsion?: boolean;
  company_billing_customer_id?: string;
  slug?: string;
  crm_id?: string;
  hubspot_url?: string;
  profile_url?: string;
  industry_sector?: string[];
  industry_subsector?: string[];
  naics_code?: string[];
  naics_entries?: string[];
  program_description?: string;
  account_manager?: string;
}

export const CompanyInitialState: Company = {
  company_id: '',
  name: '',
};

export interface CompanyGamePlanResponse {
  game_plan_id?: string;
  company_id: string;
  account_manager: string;
  file_name: string;
  file_description: string;
  locale: string;
}
export interface CompanyGamePlan {
  game_plan_id: string;
  company_id: string;
  account_manager: string;
  asset_id: string;
  created_at?: string | null;
  updated_at?: string | null;
  deleted_at?: string | null;
  file_name: string;
  locale: string;
}
export const CompanyGamePlanInitialState: CompanyGamePlan = {
  company_id: '',
  game_plan_id: '',
  account_manager: '',
  asset_id: '',
  file_name: '',
  locale: '',
};
