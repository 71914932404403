import Div from '@hellodarwin/core/lib/components/common/div';
import Dropdown from '@hellodarwin/core/lib/components/common/dropdown';
import RichTextEditor from '@hellodarwin/core/lib/components/common/rich-editor';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import AddressAutocompleteInput from '@hellodarwin/core/lib/components/forms/form-groups/adress-autocomplete-input';
import {
  annualRevenue,
  companySize,
} from '@hellodarwin/core/lib/components/forms/utils/company-infos';
import validateWebsite from '@hellodarwin/core/lib/components/forms/utils/validate-website';
import {
  AdminRfpRequest,
  Company,
  Rfp,
} from '@hellodarwin/core/lib/features/entities';
import {
  isDev,
  selectInputEmail,
} from '@hellodarwin/core/lib/features/helpers';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import { DefaultTheme } from '@hellodarwin/core/lib/theme/index';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import { FormInstance } from 'antd/es/form/Form';
import { Col, Row } from 'antd/es/grid';
import Input from 'antd/es/input';
import { MenuProps } from 'antd/es/menu';
import message from 'antd/es/message';
import Modal from 'antd/es/modal';
import notification from 'antd/es/notification';
import Select from 'antd/es/select';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app';
import { updateCompany } from '../../features/api/slices/companies-slice';
import {
  fetchBottomLevelIndustries,
  fetchIndustries,
  selectIndustriesSectors,
  selectIndustriesSubsectors,
  selectNaicsCodes,
} from '../../features/api/slices/tags-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import { AdminPagesForms } from '../../pages/single-rfp-page';
import ExternalLink from '../widgets/external-link';
import AssignCompanyModal from './assign-company-modal';
const { TextArea } = Input;

type CompanyFormProps = {
  company?: Company;
  rfp?: Rfp;
  setForms: Dispatch<SetStateAction<AdminPagesForms>>;
  isProgram?: boolean;
  companyForm?: FormInstance<CompanyFormValues>;
};

export type CompanyFormValues = {
  name: string;
  size: string;
  annual_revenue: string;
  province: string;
  postal_code: string;
  country: string;
  isCanada: boolean;
  comments: string;
  website: string;
  business_number: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  lng: number;
  lat: number;
  industry_sector: string[];
  industry_subsector: string[];
  naics_code: string[];
  program_description: string;
};

const CompanyForm = ({
  company,
  setForms,
  rfp,
  isProgram,
}: CompanyFormProps) => {
  const [form] = Form.useForm<CompanyFormValues>();
  const { t } = useTranslations();
  const theme = useTheme() as DefaultTheme;
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>(
    company?.industry_sector || [],
  );
  const industrySectors = useAppSelector(selectIndustriesSectors);
  const industrySubsectors = useAppSelector((state) =>
    selectIndustriesSubsectors(state, selectedIndustries),
  );
  const naicsCode = useAppSelector(selectNaicsCodes);

  useEffect(() => {
    dispatch(fetchIndustries({ api, locale: selectedLocale }));
    dispatch(fetchBottomLevelIndustries({ api, locale: selectedLocale }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIndustryChange = (value: string[]) => {
    setSelectedIndustries(value);
  };

  useEffect(() => {
    const currentSubsectors = form.getFieldValue('industry_subsector') || [];
    const validSubsectors = currentSubsectors.filter((subsector: string) =>
      industrySubsectors.some((option) => option.value === subsector),
    );

    if (validSubsectors.length !== currentSubsectors.length) {
      form.setFieldsValue({ industry_subsector: validSubsectors });
    }
  }, [selectedIndustries, industrySubsectors, form]);

  useEffect(() => {
    setForms((prevState: AdminPagesForms) => ({
      ...prevState,
      companyForm: form,
    }));
  }, [form, setForms]);

  useEffect(() => {
    form.resetFields();
    if (company) form.setFieldsValue(company);
  }, [form, company]);

  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const initialValues: CompanyFormValues = {
    name: company?.name || '',
    size: company?.size || '',
    annual_revenue: company?.annual_revenue || '',
    province: company?.province || '',
    country: company?.country || '',
    isCanada: company?.country === 'Canada' || company?.country === '',
    comments: company?.comments || '',
    website: company?.website || '',
    business_number: company?.business_number || '',
    email: company?.email || '',
    phone: company?.phone || '',
    address: company?.address || '',
    postal_code: company?.postal_code || '',
    city: company?.city || '',
    lat: company?.lat || 0,
    lng: company?.lng || 0,
    industry_sector: company?.industry_sector || [],
    industry_subsector: company?.industry_subsector || [],
    naics_code: company?.naics_code || [],
    program_description: company?.program_description || '',
  };

  const reassignCompany = (company: Company) => {
    if (rfp?.rfp_id) {
      Modal.confirm({
        title: t('companyForm|companyAssign'),
        content: t('companyForm|confirmCompanyAssign', {
          companyName: company?.name || '',
        }),
        okText: t('button|confirm'),
        cancelText: t('button|cancel'),
        okButtonProps: {
          style: {
            backgroundColor: theme.colors.purple_1,
            color: theme.colors.white_1,
            borderRadius: '24px',
          },
        },
        cancelButtonProps: {
          style: {
            backgroundColor: theme.colors.white_1,
            borderColor: theme.colors.grey_1,
            color: theme.colors.grey_1,
            borderRadius: '24px',
          },
        },
        onOk() {
          const company_id = company.company_id;
          return new Promise((resolve, reject) => {
            const request: AdminRfpRequest = {
              company_id,
              description: rfp.description,
              tags: rfp.tags,
              rfp_id: rfp.rfp_id,
            };

            api
              .updateRfp(request)
              .then(() => {
                notification.success({
                  message: t('companyForm|successRfpAssign'),
                  placement: 'topLeft',
                  duration: 0,
                });
                resolve('Suppression');
                setAssignModalOpen(false);
              })
              .catch((e: Error) => reject(e));
          }).catch(() => console.error(t('companyForm|messageError')));
        },
      });
    } else {
      Modal.error({
        title: t('companyForm|noRelatedRfp'),
      });
      setAssignModalOpen(false);
    }
  };

  const toggleDeleteCompany = () => {
    Modal.confirm({
      title: t('companyForm|deleteCompany', {
        companyName: company?.name || '',
      }),
      content: t('companyForm|confirmDeleteCompany'),
      okText: t('button|confirm'),
      cancelText: t('button|cancel'),
      okButtonProps: {
        style: {
          backgroundColor: theme.colors.purple_1,
          color: theme.colors.white_1,
          borderRadius: '24px',
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: theme.colors.white_1,
          borderColor: theme.colors.grey_1,
          color: theme.colors.grey_1,
          borderRadius: '24px',
        },
      },
      onOk() {
        return new Promise((resolve, reject) => {
          api
            .deleteCompany(company?.company_id || '')
            .then(() => {
              notification.success({
                message: t('companyForm|successDeleteCompany'),
                placement: 'topLeft',
                duration: 0,
              });
              resolve('Suppression');
            })
            .catch((e: Error) => reject(e));
        }).catch(() => console.error(t('companyForm|messageError')));
      },
    });
  };

  const actionMenuItems: MenuProps['items'] = [
    {
      label: t('companyForm|companyAssign'),
      key: 2,
      onClick: () => setAssignModalOpen(true),
    },
    {
      label: t('companyForm|deleteCompany', {
        companyName: company?.name || '',
      }),
      key: 1,
      onClick: toggleDeleteCompany,
    },
  ];

  const onSave = async () => {
    if (company) {
      try {
        const companyFormValues = form?.getFieldsValue()!;

        if (companyFormValues === undefined) {
          return;
        }

        const updatedCompany: Company = {
          ...company,
          ...companyFormValues,
        };

        await dispatch(updateCompany({ api, company: updatedCompany }));

        message.success(t('companyForm|saved'));
      } catch (e: any) {
        message.error(t('companyForm|messageError'));
        console.error(e);
      }
    } else {
      message.error(t('companyForm|noCompanySelect'));
    }
  };

  return (
    <>
      <Card style={{ padding: '1rem' }}>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          name="company"
        >
          <Div flex="column" gap={8}>
            <Div flex="row" justify="space-between" align="center">
              <Div flex="row" justify="left" align="left" gap={8}>
                <Typography.Title
                  level={4}
                  elementTheme="h6"
                  copyable={{
                    successMessage: [
                      company?.company_id,
                      t('companyForm|companyIdCopied'),
                    ],
                    text: company?.company_id,
                  }}
                >
                  <a
                    href={
                      isProgram
                        ? `/companies/${company?.company_id}/grants`
                        : `/companies/${company?.company_id}`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('companyForm|company')}
                  </a>
                </Typography.Title>
                {company && (
                  <ExternalLink
                    id={company.crm_id}
                    title={'Hubspot ID'}
                    url={company.hubspot_url}
                    onEnd={(newValue) => {
                      dispatch(
                        updateCompany({
                          api,
                          company: {
                            ...company,
                            ...{ crm_id: newValue },
                          },
                        }),
                      );
                    }}
                  />
                )}
                {isDev && (
                  <>
                    <Dropdown items={actionMenuItems} cta={{ size: 24 }} />
                  </>
                )}
              </Div>
              <Div flex="row" justify="right" align="right">
                <Button onClick={onSave} size="small">
                  {t('button|save')}
                </Button>
              </Div>
            </Div>

            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label={t('companyForm|form_name')}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('companyForm|companyNameRequired'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('companyForm|website')}
                  name="website"
                  rules={[
                    {
                      required: true,
                      validator: validateWebsite({
                        message: t('companyForm|websiteInvalid'),
                        isAdmin: true,
                        isRequired: true,
                      }),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label={t('companyForm|companySize')}
                  name="size"
                  rules={[
                    {
                      required: true,
                      message: t('companyForm|companySizeRequired'),
                    },
                  ]}
                >
                  <Select placeholder="Select option">
                    {companySize.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('companyForm|annualRevenue')}
                  name="annual_revenue"
                  rules={[
                    {
                      required: true,
                      message: t('companyForm|annualRevenueRequired'),
                    },
                  ]}
                >
                  <Select placeholder="Select option">
                    {annualRevenue.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <AddressAutocompleteInput form={form} showManualAddress />
              </Col>

              <Col span={24}>
                <Form.Item
                  label={t('companyForm|only_email')}
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: t('companyForm|emailRequired'),
                    },
                  ]}
                >
                  <Input onMouseDown={(e) => selectInputEmail(e)} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={t('companyForm|programDescription')}
              name="program_description"
            >
              <RichTextEditor
                initialValue={company?.program_description || ''}
              />
            </Form.Item>
            <Form.Item label={t('companyForm|comments')} name="comments">
              <TextArea rows={company?.comments ? 4 : 1} autoSize />
            </Form.Item>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label={t('companyForm|businessNumber')}
                  name="business_number"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('companyForm|phone')} name="phone">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label={t('companyForm|industrySector')}
                  name="industry_sector"
                >
                  <Select
                    onChange={(value: string[]) => {
                      handleIndustryChange(value);
                    }}
                    value={selectedIndustries}
                    mode="multiple"
                    style={{ width: '100%' }}
                    options={industrySectors}
                    filterOption={(input, option) => {
                      if (!option || !option.label) return false;
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('companyForm|industrySubsector')}
                  name="industry_subsector"
                >
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    options={industrySubsectors}
                    filterOption={(input, option) => {
                      if (!option || !option.label) return false;
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('companyForm|naicsCode')} name="naics_code">
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    options={naicsCode}
                    filterOption={(input, option) => {
                      if (!option || !option.label) return false;
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Div>
        </Form>
      </Card>
      <AssignCompanyModal
        open={assignModalOpen}
        handleClose={() => setAssignModalOpen(false)}
        handleSubmit={reassignCompany}
      />
    </>
  );
};

export default CompanyForm;
