import Div from '@hellodarwin/core/lib/components/common/div';
import Loading from '@hellodarwin/core/lib/components/loading';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Select from 'antd/es/select';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  fetchGlobalSearchResults,
  selectGlobalSearchLoading,
  selectGlobalSearchResults,
} from '../../../features/api/slices/search-global-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';
import useDebounce from '../../../hooks/use-debounce';

interface SelectCompanyProps {
  handleSubmit: (companyId: string) => void;
}

const SEARCH_DELAY_MS = 500;

const SelectCompany: React.FC<SelectCompanyProps> = ({ handleSubmit }) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const { selectedLocale } = useLocale();
  const searchCompanies = useAppSelector(selectGlobalSearchResults);
  const searchCompaniesLoading = useAppSelector(selectGlobalSearchLoading);
  const { t } = useTranslations();

  const [companyOptions, setCompanyOptions] = useState(
    searchCompanies?.companies.map((company) => ({
      key: company.company_id,
      value: company.company_id,
      label: company.company_name,
    })) || undefined,
  );

  useEffect(() => {
    if (searchCompanies) {
      if (
        !searchCompanies ||
        !searchCompanies.companies ||
        searchCompanies.companies.length < 1
      ) {
        setCompanyOptions(undefined);
        return;
      }

      setCompanyOptions(
        searchCompanies.companies.map((company) => ({
          key: company.company_id,
          value: company.company_id,
          label: company.company_name,
        })),
      );
    }
  }, [searchCompanies]);

  const onSearch = async (query: string) => {
    if (query === '') return;
    try {
      await dispatch(
        fetchGlobalSearchResults({
          api: api,
          query: query,
          locale: selectedLocale,
        }),
      );
    } catch (e: any) {
      console.error(e);
      return;
    }
  };

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <Select
      showSearch
      options={companyOptions}
      placeholder={t('createApplication|search_company')}
      loading={searchCompaniesLoading}
      filterOption={false}
      onSearch={onSearch}
      onChange={(companyId) => {
        handleSubmit(companyId);
        setSearchTerm(companyId);
      }}
      value={searchTerm}
      size="large"
      notFoundContent={
        searchCompaniesLoading ? (
          <Loading />
        ) : !!!companyOptions ? (
          <Div>No companies found</Div>
        ) : null
      }
    />
  );
};

export default SelectCompany;
