import Div from '@hellodarwin/core/lib/components/common/div';
import RichTextEditor from '@hellodarwin/core/lib/components/common/rich-editor';
import FormItem from '@hellodarwin/core/lib/components/forms/form-layouts/form-item';
import {
  AdminGrantResult,
  HdChatPromptRequest,
} from '@hellodarwin/core/lib/features/entities';
import Check from '@hellodarwin/icons/dist/icons/Check';
import Button from 'antd/es/button';
import { Row } from 'antd/es/grid';
import Input from 'antd/es/input';
import Skeleton from 'antd/es/skeleton';
import { useState } from 'react';
import { useAppSelector } from '../../app';
import { selectIsLoadingSoloGen } from '../../features/api/slices/grants-slice';
import theme from '../../theme';
import {
  GrantFormValues,
  transformSectionIntoLabel,
} from './grant-form/grant-form';
import GrantPromptButtons from './grant-prompt-buttons';
const { TextArea } = Input;

export interface GrantPromptContentSectionProps {
  section: keyof GrantFormValues;
  setSelectedPrompt?: (prompt: HdChatPromptRequest) => void;
  setAddNewPromptDrawer?: (promptDrawer: boolean) => void;
  onSave: (() => Promise<void>) | undefined;
  grant?: AdminGrantResult | undefined;
  isTextArea: boolean;
  prompts?: HdChatPromptRequest[];
  isRfp?: boolean;
  generateSection: (section: keyof GrantFormValues) => void;
  formValues: GrantFormValues;
  rowsCount?: number;
}

const GrantPromptContentSection = ({
  section,
  setSelectedPrompt,
  setAddNewPromptDrawer,
  onSave,
  grant,
  isTextArea,
  prompts,
  isRfp,
  generateSection,
  rowsCount = 10,
  formValues,
}: GrantPromptContentSectionProps) => {
  const isLoading = useAppSelector((state) =>
    selectIsLoadingSoloGen(state, section),
  );
  const [canSave, setCanSave] = useState<boolean>(false);

  const SkeletonTextArea = (rows: number) => (
    <Skeleton
      paragraph={{ rows: rows, width: '100%' }}
      style={{
        backgroundColor: theme.colors.white_1,
        padding: 10,
      }}
      active
    />
  );

  const generateContent = () => generateSection(section);

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ width: '100%', marginBottom: '8px' }}
      >
        <Div style={{ width: '200px' }}>
          {transformSectionIntoLabel(section)}
        </Div>
        {isRfp ? (
          <Button
            color={'primary'}
            onClick={() => {
              onSave!();
              setCanSave(false);
            }}
            disabled={!canSave}
          >
            <Check size={24} />
          </Button>
        ) : (
          <GrantPromptButtons
            section={section}
            setSelectedPrompt={setSelectedPrompt}
            setAddNewPromptDrawer={setAddNewPromptDrawer}
            onSave={onSave}
            grant={grant}
            generateContent={generateContent}
            prompts={prompts}
            canSave={canSave}
            setCanSave={setCanSave}
          />
        )}
      </Row>
      {isLoading ? (
        SkeletonTextArea(5)
      ) : (
        <FormItem name={section}>
          {isTextArea ? (
            <TextArea rows={rowsCount} />
          ) : (
            <RichTextEditor
              initialValue={(formValues?.[section] as unknown as string) ?? ''}
              onChange={() => {
                if (!canSave) setCanSave(true);
              }}
            />
          )}
        </FormItem>
      )}
    </>
  );
};

export default GrantPromptContentSection;
