import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface RouterContextType {
  current: string;
  previous: string | null;
  allPaths: string[];
}

export const RouterContext = React.createContext<RouterContextType>({
  current: '',
  previous: null,
  allPaths: [],
});

const AppRouterProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const [route, setRoute] = useState<RouterContextType>({
    current: location.pathname,
    previous: null,
    allPaths: [location.pathname],
  });

  useEffect(() => {
    setRoute((prev) => ({
      current: location.pathname,
      previous: prev.current,
      allPaths: [...prev.allPaths, location.pathname],
    }));
  }, [location]);

  return (
    <RouterContext.Provider value={route}>{children}</RouterContext.Provider>
  );
};

export const useAppRouter = () => useContext(RouterContext);

export default AppRouterProvider;
