import RfpStatus from '@hellodarwin/core/lib/features/enums/rfp-status';
import { getI18n } from '@hellodarwin/core/lib/plugins/i18n/utils';
import notification from 'antd/es/notification';

export const showErrorNotificationTranslated = (code: number) => {
  const { t } = getI18n();
  const message = t(`errors|${code}.message`);
  const description = t(`errors|${code}.description`);

  notification.error({
    message: message ? message : 'Server Error',
    description: description ? description : 'Error code: ' + code,
    placement: 'bottomLeft',
  });
};

export const showErrorNotification = (error: any) => {
  notification.error({
    message: error.error_code ? error.error_code : 'Server Error',
    description: error.error,
    placement: 'bottomLeft',
    duration: 3,
  });
};

export const getRfpStatus = (reason: string): RfpStatus => {
  const rfpStatus: { [index: string]: RfpStatus } = {
    Spam: RfpStatus.Rejected,
    Test: RfpStatus.Rejected,
    'No budget / unrealistic expectations': RfpStatus.Rejected,
    'No # number / Wrong contact info': RfpStatus.Rejected,
    'Not a service': RfpStatus.Rejected,
    'The rfp is too early/not mature enough': RfpStatus.Rejected,
    "The client isn't serious enough / Bad targeting": RfpStatus.Rejected,
    'Found a SP outside the platform': RfpStatus.Canceled,
    'Rfp was canceled': RfpStatus.Canceled,
    'Never reached the client': RfpStatus.Canceled,
    'Stopped Answering / Became Unresponsive': RfpStatus.Canceled,
  };

  return rfpStatus[reason];
};

// getStatusColor returns the appropriate color depending on status

export const getStatusTooltip = (status: string): string => {
  const colors: { [index: string]: string } = {
    PendingApproval: 'New Rfp',
    Active: 'Ready to start drip',
    FilterCandidates: 'Maximum number of raised hands reached',
    EvaluateFinalists: 'Maximum number of shortlist reached',
    Completed: 'This will trigger unselected emails',
    Canceled: 'Canceled',
    Rejected: 'Rejected',
  };

  return colors[status];
};

// getStatusColor returns the appropriate color depending on status
export const getStatusColor = (status: string): string => {
  const colors: { [index: string]: string } = {
    Active: 'success',
    Canceled: 'error',
    Rejected: 'error',
    Completed: 'processing',
    PENDING: 'processing',
    Pending: 'processing',
    APPROVED: 'success',
    Approved: 'success',
    ACTIVE: 'success',
    NEW: 'success',
    New: 'success',
    VERIFIED: 'success',
    Verified: 'success',
    OnGoing: 'processing',
    Waiting: 'processing',
    Sent: 'success',
    Stopped: 'error',
    Errored: 'error',
    Unverified: 'warning',
    Suspended: 'error',
    Delivered: 'success',
    Blacklisted: 'error',
    PendingApproval: 'warning',
    FilterCandidates: 'purple',
    EvaluateFinalists: 'blue',
    ProviderReferral: 'success',
  };

  return colors[status];
};

const uuidRegexString =
  '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$';

export const validateUUID = (input: string): boolean => {
  const uuidPattern = new RegExp(uuidRegexString);
  return uuidPattern.test(input);
};
