import { Text } from '@react-pdf/renderer';
import theme from '../../../theme';
import { TypographyElementTheme } from '../typography/types';

export interface ListDotProps {
  level: number;
  elementTheme: TypographyElementTheme;
  ordered?: boolean;
  index?: number;
  color?: string;
}

const ListDot: React.FC<ListDotProps> = ({
  level,
  elementTheme,
  ordered,
  index,
  color,
}) => {
  let marker;
  if (ordered) {
    switch (level % 3) {
      case 0:
        marker = `${index + 1}.`;
        break;
      case 1:
        marker = `${String.fromCharCode(97 + index)}.`;
        break;
      case 2:
        marker = `${String.fromCharCode(65 + index)}.`;
        break;
      default:
        marker = `${index + 1}.`;
    }
  } else {
    switch (level % 3) {
      case 0:
        marker = '•';
        break;
      case 1:
        marker = '◦';
        break;
      case 2:
        marker = '‣';
        break;
      default:
        marker = '•';
    }
  }

  return (
    <Text
      style={{
        fontFamily: 'Inter',
        color,
        lineHeight: theme.fontsLineHeights[elementTheme],
      }}
    >
      {marker}
    </Text>
  );
};

export default ListDot;
