import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import Invoices from '@hellodarwin/icons/dist/icons/Invoices';
import RFPIcon from '@hellodarwin/icons/dist/icons/RfpIcon';
import { AddNewProjectItemModalState } from '../../types';
import ProjectItemTile from './project-item-tile';

interface AddNewProjectItemSelectionFrameProps {
  handleItemClick: (id: AddNewProjectItemModalState) => void;
}

const AddNewProjectItemSelectionFrame: React.FC<
  AddNewProjectItemSelectionFrameProps
> = ({ handleItemClick }) => {
  const { t } = useTranslations();
  return (
    <>
      <Typography elementTheme="h5" textAlign="center">
        {t('project|whatToAdd')}
      </Typography>
      <Div flex="row" gap={32} tablet={{ flex: 'column', gap: 16 }}>
        <ProjectItemTile
          label={t('project|grant')}
          Icon={Grants}
          id="grant"
          onClick={handleItemClick}
        />
        <ProjectItemTile
          label={t('project|application')}
          Icon={Invoices}
          id="application"
          onClick={handleItemClick}
        />
        <ProjectItemTile
          label={t('project|rfp')}
          Icon={RFPIcon}
          id="rfp"
          onClick={handleItemClick}
        />
      </Div>
    </>
  );
};

export default AddNewProjectItemSelectionFrame;
