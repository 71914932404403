import { TypographyStyles } from '../styles';
import { TypographyElementTheme } from '../types';

const getTypographyStyle = (elementTheme: TypographyElementTheme) => {
  switch (elementTheme) {
    case 'h1':
      return TypographyStyles.h1;
    case 'h2':
      return TypographyStyles.h2;
    case 'h3':
      return TypographyStyles.h3;
    case 'h4':
      return TypographyStyles.h4;
    case 'h5':
      return TypographyStyles.h5;
    case 'h6':
      return TypographyStyles.h6;
    case 'subtitle1':
      return TypographyStyles.subtitle1;
    case 'subtitle2':
      return TypographyStyles.subtitle2;
    case 'overline':
      return TypographyStyles.overline;
    case 'body2':
      return TypographyStyles.body2;
    case 'body3':
      return TypographyStyles.body3;
    case 'body4':
      return TypographyStyles.body4;
    default:
      return TypographyStyles.body1;
  }
};

export default getTypographyStyle;
