import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import { useMemo, useState } from 'react';
import { AdminProfile, AppName, Profile } from '../../features/entities';
import { getMeetingUrl } from '../../features/helpers';
import useLocale from '../../features/providers/locale-provider';
import { useTranslation } from '../../plugins/i18n';
import ModalLayoutWithMeeting from '../common/hd-modal/layouts/modal-with-meeting';
import ModalWarning from '../common/hd-modal/layouts/modal-with-warning';
import Typography from '../common/typography';
import ContactModal, { ContactInfoLineProps } from '../modals/contact-modal';

type ManagerContactModalProps = {
  open: boolean;
  handleClose: () => void;
  isPropulsion?: boolean;
  admin: AdminProfile;
  profile: Profile;
  isLoading?: boolean;
  infos: ContactInfoLineProps[];
};

const ManagerContactModal = ({
  open,
  handleClose,
  isPropulsion,
  admin,
  profile,
  isLoading,
  infos,
}: ManagerContactModalProps) => {
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const [meetingOpen, setMeetingOpen] = useState(false);
  const handleModalMeeting = () => setMeetingOpen(!meetingOpen);

  const withAdmin = useMemo(() => !!admin?.admin_id?.length, [admin]);

  const meetingUrl = useMemo(() => {
    const url =
      selectedLocale === 'en' ? admin?.meeting_link_en : admin?.meeting_link_fr;

    if (!url) {
      return getMeetingUrl(
        selectedLocale,
        'client',
        isPropulsion ? 'propulsion' : 'projects',
      );
    }

    return url;
  }, [admin, selectedLocale]);

  if (!withAdmin) {
    return (
      <ModalWarning open={open} size="small" handleCancel={handleClose}>
        <Typography> {t('common|message.noAccountManager')}</Typography>
      </ModalWarning>
    );
  }

  return (
    <>
      <ContactModal
        open={open}
        handleClose={handleClose}
        picture={admin.picture}
        loading={isLoading}
        name={admin.first_name + ' ' + admin.last_name}
        infos={infos}
        cta={{
          headingIcon: <Timeline size={16} />,
          text: t('rfp|rfpActionCard.bookAMeeting.title'),
          action: handleModalMeeting,
        }}
      />

      <ModalLayoutWithMeeting
        open={meetingOpen}
        handleCancel={handleModalMeeting}
        meetingUrl={meetingUrl}
        Icon={Timeline}
        title={t('message.talkToManager')}
        name={profile.contact_name}
        phone={profile.contact_phone}
        companyName={profile.company_name}
        app={AppName.Client}
        email={profile.contact_email}
      />
    </>
  );
};

export default ManagerContactModal;
