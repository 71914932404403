import { RangePickerTimeProps } from 'antd/es/time-picker';
import dayjs from 'dayjs';
import { AdminGrantResult, GrantResult } from './grants-entities';
import { Program } from './programs-entities';
import { NewTag, ProjectTag } from './tags-entities';

export interface Project {
  project_id: string;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  company_id: string;
  parent_project?: string;
  started_date?: Date;
  end_date?: Date;
  title: string;
  description?: string;
  comments?: string;
  priority?: number;
  project_type?: string;
  reason_cancel?: string;
  activated_at?: Date;
  cancelled_at?: Date;
  completed_at?: Date;
  admin_id?: string;
  status?: string;
  estimated_budget?: number;
  tags?: NewTag[] | ProjectTag[];
  project_goals?: string[];
}

export const InitialProjectState: Project = {
  project_id: '',
  company_id: '',
  title: '',
};
export interface ProjectGrant extends AdminGrantResult {
  project_id: string;
  grant_id: string;
  created_at?: Date;
  programs?: ProjectProgram[];
}

export const InitialProjectGrantState: ProjectGrant = {
  project_id: '',
  grant_id: '',
};

export interface ProjectProgram extends Program {
  project_id: string;
  program_id: string;
  created_at?: Date;
}
export interface ProjectProgramWithGrant
  extends Program,
    GrantResult,
    ProjectProgram {
  eligibility_criteria: string;
}

export const InitialProjectProgramState: ProjectProgram = {
  project_id: '',
  program_id: '',
};

export interface ProjectFormValues {
  title: string;
  project_type: string;
  priority: number;
  description: string;
  timeline: RangePickerTimeProps<dayjs.Dayjs>['defaultValue'];
  estimated_budget?: number;
  comments: string;
  tags: NewTag[] | ProjectTag[];
}
export interface AdminProjectResponse extends Project {
  projects?: AdminProjectResponse[];
  grants?: ProjectGrant[];
  programs?: ProjectProgram[];
  parent_title?: string;
}
export interface PreviewProjectResponse extends Project {
  total_projects: number;
  total_programs: number;
  total_budget: number;
}

export interface CreateProjectFormResponse {
  title: string;
  project_type: string;
  priority: number;
  description: string;
  started_date?: Date;
  end_date?: Date;
  estimated_budget?: number;
  comments: string;
  company_id: string;
  parent_project?: string;
}
