import { SVGProps } from '@react-pdf/renderer';
import { PdfBackgroundIconProps } from './types';

interface getBackgroundIconConfigProps {
  props: PdfBackgroundIconProps;
  defaultWidth: number;
  defaultHeight: number;
}
const getBackgroundIconConfig = ({
  props,
  defaultHeight,
  defaultWidth,
}: getBackgroundIconConfigProps): SVGProps => {
  const {
    top = 0,
    align = 'right',
    margin = 0,
    rotate = 0,
    width = defaultWidth,
    height = defaultHeight,
  } = props;
  return {
    width,
    height,
    style: {
      position: 'absolute',
      top: top,
      right: align === 'right' ? margin : undefined,
      left: align === 'left' ? margin : undefined,
      transform: `rotate(${rotate}deg)`,
    },
  };
};
export default getBackgroundIconConfig;
