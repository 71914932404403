import parse from '../../../../../features/helpers/parse';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import { grantSingleParseOptions } from '../../styles';

interface AdmissibilitySectionTileProps {
  label: string;
  content?: string;
}

const AdmissibilitySectionTile = ({
  label,
  content,
}: AdmissibilitySectionTileProps) => {
  if (!content) return <></>;
  return (
    <Div flex="column" gap={16} align="flex-start">
      <div style={{ width: '100%', paddingTop: '24px' }}>
        <Typography.Title level={2} elementTheme="h6" noMargin>
          {label}
        </Typography.Title>
      </div>
      <Div>{parse(content, grantSingleParseOptions)}</Div>
    </Div>
  );
};

export default AdmissibilitySectionTile;
