import { StringMap } from 'quill';
import { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { RichTextEditorContainer } from './styles';

interface RichTextEditorProps {
  initialValue: string;
  id?: string;
  onChange?: (...props: any) => void;
}

const quillModules: StringMap = {
  toolbar: [
    [{ header: [3, 4, 5, false] }], // Support h3, h4, h5
    ['bold', 'underline', 'italic', 'strike'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ], // Multi-level lists
    ['link'], // Link option
  ],
  clipboard: {
    matchVisual: false,
  },
};

const RichTextEditor = (props: RichTextEditorProps) => {
  const ref = useRef<ReactQuill>(null);

  const { initialValue } = props;
  const [content, setContent] = useState(initialValue);

  useEffect(() => {
    setContent(initialValue);
  }, [initialValue]);

  const handleChange = (newValue: string) => {
    !!props.onChange && props.onChange(newValue);
    setContent(newValue);
  };

  return (
    <RichTextEditorContainer
      ref={ref}
      modules={quillModules}
      preserveWhitespace={true}
      value={content}
      onChange={handleChange}
    />
  );
};

export default RichTextEditor;
