import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { adminReducer } from '../features/api/slices/admins-slice';
import { assetsReducer } from '../features/api/slices/assets-slice';
import { billingReducer } from '../features/api/slices/billing-slice';
import { chatGptReducer } from '../features/api/slices/chatgpt-slice';
import { companiesReducer } from '../features/api/slices/companies-slice';
import { formsReducer } from '../features/api/slices/forms-slice';
import { companiesGamePlansReducer } from '../features/api/slices/gameplan-slice';
import { ginContactsReducer } from '../features/api/slices/gin-contacts-slice';
import { ginHistoryReducer } from '../features/api/slices/gin-history-slice';
import { ginSectionsReducer } from '../features/api/slices/gin-sections-slice';
import { ginStepsReducer } from '../features/api/slices/gin-steps-slice';
import { ginsReducer } from '../features/api/slices/gins-slice';
import { globalReducer } from '../features/api/slices/global-slice';
import { grantProjectsReducer } from '../features/api/slices/grant-projects-slice';
import { grantsReducer } from '../features/api/slices/grants-slice';
import { promptsReducer } from '../features/api/slices/hd-chat-prompts-slice';
import { questionsReducer } from '../features/api/slices/hd-chat-questions-slice';
import { chatsReducer } from '../features/api/slices/hd-chats-slice';
import { matchesReducer } from '../features/api/slices/matches-slice';
import { milestonesAttachmentsReducer } from '../features/api/slices/milestones-attachments-slice';
import { milestonesReducer } from '../features/api/slices/milestones-slice';
import { newTagsReducer } from '../features/api/slices/new-tags-slice';
import { portfoliosReducer } from '../features/api/slices/portfolios-slice';
import { programsReducer } from '../features/api/slices/programs-slice';
import { projectsReducer } from '../features/api/slices/projects-slice';
import { providersReducer } from '../features/api/slices/providers-slice';
import { relatedGrantsReducer } from '../features/api/slices/related-grants-slice';
import { reviewsReducer } from '../features/api/slices/reviews-slice';
import { rfpReducer } from '../features/api/slices/rfp-slice';
import { globalSearchReducer } from '../features/api/slices/search-global-slice';
import { tagsReducer } from '../features/api/slices/tags-slice';
import { templatesReducer } from '../features/api/slices/templates-slice';
import { usersReducer } from '../features/api/slices/users-slice';

export const store = configureStore({
  reducer: {
    global: globalReducer,
    grants: grantsReducer,
    rfp: rfpReducer,
    providers: providersReducer,
    portfolios: portfoliosReducer,
    reviews: reviewsReducer,
    programs: programsReducer,
    milestones: milestonesReducer,
    milestonesAttachments: milestonesAttachmentsReducer,
    matches: matchesReducer,
    users: usersReducer,
    tags: tagsReducer,
    billing: billingReducer,
    chatGpt: chatGptReducer,
    hdChats: chatsReducer,
    hdChatQuestions: questionsReducer,
    hdChatPrompts: promptsReducer,
    templates: templatesReducer,
    companies: companiesReducer,
    admins: adminReducer,
    forms: formsReducer,
    grantProjects: grantProjectsReducer,
    newTags: newTagsReducer,
    gins: ginsReducer,
    relatedGrants: relatedGrantsReducer,
    ginContacts: ginContactsReducer,
    ginSections: ginSectionsReducer,
    ginSteps: ginStepsReducer,
    assets: assetsReducer,
    globalSearch: globalSearchReducer,
    ginHistory: ginHistoryReducer,
    projects: projectsReducer,
    gamePlans: companiesGamePlansReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
