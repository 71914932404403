import { View } from '@react-pdf/renderer';
import PriorityBox from './box';

export interface PrioritiesBoxesProps {
  currentIndex: number;
}

const TOTAL_BOXES = 5;
const PrioritiesBoxes: React.FC<PrioritiesBoxesProps> = ({ currentIndex }) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {Array.from({ length: TOTAL_BOXES }, (_, i) => TOTAL_BOXES - i).map(
        (index) => (
          <PriorityBox index={index} isActive={index >= currentIndex} />
        ),
      )}
    </View>
  );
};

export default PrioritiesBoxes;
