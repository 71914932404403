import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Modal from '@hellodarwin/core/lib/components/common/hd-modal';
import FormItem from '@hellodarwin/core/lib/components/forms/form-layouts/form-item';
import { AdminProfile } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Form, { useForm } from 'antd/es/form/Form';
import Popconfirm from 'antd/es/popconfirm';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import SelectAdmin from '../../../programs/programs-action-modal/select-admin';

interface AdminProfileFormModalProps {
  open: boolean;
  adminProfile?: AdminProfile;
  handleSelection: (adminId: string) => void;
  handleClose: () => void;
  handleDelete: () => void;
  isLoading?: boolean;
}

interface AdminProfileFormProps {
  handleSelection: (adminId: string) => void;
  handleDelete: () => void;
  adminProfile?: AdminProfile;
  isLoading?: boolean;
}

const AccountManagerFormModal = (props: AdminProfileFormModalProps) => {
  const {
    open,
    adminProfile,
    handleSelection,
    handleClose,
    handleDelete,
    isLoading,
  } = props;

  return (
    <Modal
      open={open}
      handleCancel={handleClose}
      size="small"
      styles={{ content: { padding: '56px 72px' } }}
    >
      <AdminProfileForm
        handleSelection={handleSelection}
        handleDelete={handleDelete}
        adminProfile={adminProfile}
        isLoading={isLoading}
      />
    </Modal>
  );
};

const AdminProfileForm = ({
  handleSelection,
  handleDelete,
  adminProfile,
  isLoading,
}: AdminProfileFormProps) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const [form] = useForm();
  const [selectedAdminId, setSelectedAdminId] = useState<string | undefined>();

  useEffect(() => {
    form.setFieldValue('admin_id', adminProfile?.admin_id);
    setSelectedAdminId(adminProfile?.admin_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminProfile]);

  const handleChangeAdmin = (adminId: string) => {
    form.setFieldValue('admin_id', adminId);
    setSelectedAdminId(adminId);
  };

  const onFinish = () => {
    handleSelection(form.getFieldValue('admin_id'));
  };

  return (
    <Div flex="column">
      <Form form={form} name="setAccountManager" onFinish={onFinish}>
        <FormItem
          label={t('companyAdminPage|account_manager')}
          labelCol={{ style: { marginRight: 10 } }}
          name="admin_id"
          rules={[
            {
              required: true,
              message: t('companyAdminPage|missing_account_manager'),
            },
          ]}
        >
          <SelectAdmin
            handleSubmit={handleChangeAdmin}
            initialAdminId={selectedAdminId}
          />
        </FormItem>
        <Div
          flex="row"
          style={{ justifyContent: 'center', marginTop: 30, gap: 10 }}
        >
          {!!adminProfile && (
            <Popconfirm
              title={t('companyAdminPage|remove_account_manager')}
              description={t(
                'companyAdminPage|remove_account_manager_confirmation',
              )}
              onConfirm={handleDelete}
              okText={t('companyAdminPage|delete')}
              cancelText="No"
            >
              <Button defaultStyle={theme.colors.white_1} loading={isLoading}>
                {t('companyAdminPage|delete')}
              </Button>
            </Popconfirm>
          )}
          <Button
            htmlType="submit"
            defaultStyle={theme.colors.purple_2}
            loading={isLoading}
          >
            {t('companyAdminPage|save')}
          </Button>
        </Div>
      </Form>
    </Div>
  );
};

export default AccountManagerFormModal;
