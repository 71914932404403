import Div from '@hellodarwin/core/lib/components/common/div';
import ModalConfirm from '@hellodarwin/core/lib/components/common/hd-modal/confirm';
import UserFormModal, {
  EMAIL_ALREADY_EXISTS_ERROR,
} from '@hellodarwin/core/lib/components/profile/profile-users-list/user-form-modal';
import { Contact } from '@hellodarwin/core/lib/features/entities/core-entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import message from 'antd/es/message';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app';
import {
  selectCompaniesIsLoading,
  selectCompanyById,
} from '../../../../features/api/slices/companies-slice';
import {
  assignContact,
  createContact,
  fetchCompanyContacts,
  getContactByEmail,
  selectCompanyContacts,
  updateContact,
} from '../../../../features/api/slices/users-slice';
import { useAdminApi } from '../../../../features/api/use-admin-api';
import UserCard from './user-card';
interface CompanyUsersListProps {
  company_id?: string;
}
const CompanyUsersList: React.FC<CompanyUsersListProps> = ({ company_id }) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const users = useAppSelector((state) =>
    selectCompanyContacts(state, company_id ?? ''),
  );
  const theme = useTheme();
  const { t } = useTranslations();
  const [activeContact, setActiveContact] = useState<Contact | null>(null);
  const isLoading = useAppSelector(selectCompaniesIsLoading);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const company = useAppSelector((state) =>
    selectCompanyById(state, company_id ?? ''),
  );

  useEffect(() => {
    dispatch(fetchCompanyContacts({ api, companyId: company_id || '' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id]);

  const onContactClick = (contact: Contact) => {
    setActiveContact(contact);
    setEditModalOpen(true);
  };

  const handleContactEdit = async (contact: Contact) => {
    return await dispatch(updateContact({ api, contact }));
  };

  const handleContactAdd = async (contact: Contact) => {
    const createContactResult = await dispatch(
      createContact({
        api,
        contact: contact,
        destination: 'Client',
      }),
    );
    if (createContact.rejected.match(createContactResult)) {
      const error = createContactResult.payload;
      if (error && error.error_code === EMAIL_ALREADY_EXISTS_ERROR) {
        ModalConfirm({
          title: t('singleCompanyPage|contact_exists_modal_title'),

          okButtonProps: {
            style: {
              backgroundColor: theme.colors.purple_1,
              color: theme.colors.white_1,
              borderRadius: '24px',
            },
          },
          cancelButtonProps: {
            style: {
              backgroundColor: theme.colors.white_1,
              borderColor: theme.colors.grey_1,
              color: theme.colors.grey_1,
              borderRadius: '24px',
            },
          },
          cancelText: t('button|cancel'),
          okText: t('button|confirm'),
          content: t('singleCompanyPage|contact_exists_modal_content'),
          onOk: async () => {
            contact.company_id = company?.company_id || '';
            await dispatch(assignContact({ api, contact: contact }));
          },
          onCancel: async () => {},
        });
      } else {
        message.error(t('singleCompanyPage|messageError'));
      }
    }
    return createContactResult;
  };

  const handleEmailExists = async (email: string) => {
    if (users.find((c) => c.email === email)) {
      return Promise.reject(t('singleCompanyPage|emailExists'));
    }
    return await dispatch(getContactByEmail({ api, email }));
  };

  const handleContactAssign = async (contact: Contact) => {
    contact.company_id = company?.company_id || '';
    return await dispatch(assignContact({ api, contact: contact }));
  };

  return (
    <>
      <Div flex="column" gap={4}>
        {users.map((user) => (
          <UserCard user={user} onContactClick={onContactClick} />
        ))}
      </Div>

      <UserFormModal
        open={editModalOpen}
        companyId={company_id ?? ''}
        handleClose={() => setEditModalOpen(false)}
        handleEditUser={handleContactEdit}
        handleContactAdd={handleContactAdd}
        handleContactAssign={handleContactAssign}
        handleEmailExists={handleEmailExists}
        contact={activeContact}
        showPhone={true}
        isLoading={isLoading}
      />
    </>
  );
};

export default CompanyUsersList;
