import Sort from '@hellodarwin/icons/dist/icons/Sort';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Select, { SelectProps } from 'antd/es/select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Div from '../../../common/div';
import Typography from '../../../common/typography';

export interface FilesListSortBySelectProps {
  onChange: (value: string) => void;
  value: string;
}

const FilesListSortBySelect: React.FC<FilesListSortBySelectProps> = ({
  onChange,
  value,
}) => {
  const { t } = useTranslation();
  const SortOptions: SelectProps['options'] = useMemo(
    () => [
      {
        value: 'recent',
        label: t('assets|filters.sortBy.options.recent'),
      },
      {
        value: 'alpha',
        label: t('assets|filters.sortBy.options.alphabetical'),
      },
      {
        value: 'type',
        label: t('assets|filters.sortBy.options.type'),
      },
    ],
    [t],
  );

  return (
    <Select
      options={SortOptions}
      onChange={onChange}
      placeholder={t('rfp|filters.sortBy.title')}
      value={value}
      labelRender={({ label }) => (
        <Div flex="row" align="center" gap={8}>
          <Sort size={16} />
          <Typography elementTheme="body2">{label}</Typography>
        </Div>
      )}
      style={{ width: 221 }}
      suffixIcon={<TabArrow size={16} down />}
    />
  );
};
export default FilesListSortBySelect;
