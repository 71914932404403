import Tooltip from 'antd/es/tooltip';
import { MouseEventHandler, useMemo, useState } from 'react';
import HdTag, { TagProps } from '.';
import { TagsListContainer } from './styles';

export interface TagsListProps {
  tags: TagProps[];
  limit?: number;
  hideShowMore?: boolean;
  mainColor?: string;
  collapsedTagsInTooltip?: boolean;
}

const TagsList: React.FC<TagsListProps> = ({
  tags,
  limit,
  mainColor,
  hideShowMore,
  collapsedTagsInTooltip,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen: MouseEventHandler = (e) => {
    e.stopPropagation();
    if (!collapsedTagsInTooltip) setIsOpen(!isOpen);
  };

  const hideTags = useMemo(
    () =>
      !!limit &&
      !!tags &&
      limit < tags.length &&
      (!isOpen || collapsedTagsInTooltip),
    [isOpen, limit, tags],
  );

  const [shownTags, collapsedTags] = useMemo(
    () => (hideTags ? [tags.slice(0, limit), tags.slice(limit)] : [tags, []]),
    [hideTags, limit, tags],
  );

  return (
    <TagsListContainer>
      {shownTags?.map((tag) => (
        <HdTag {...tag} color={tag.color ?? mainColor} />
      ))}
      {hideTags && !hideShowMore && (
        <Tooltip
          overlayInnerStyle={{ background: 'white' }}
          title={
            collapsedTagsInTooltip && (
              <TagsListContainer>
                {collapsedTags.map((tag) => (
                  <HdTag {...tag} color={tag.color ?? mainColor} />
                ))}
              </TagsListContainer>
            )
          }
        >
          <HdTag
            text={`+${tags.length - limit}`}
            color={mainColor}
            hoverable
            onClick={handleOpen}
          />
        </Tooltip>
      )}
    </TagsListContainer>
  );
};

export default TagsList;
