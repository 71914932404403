import { View } from '@react-pdf/renderer';
import { TableStyles } from '../styles';
import PdfColumnContent from './content';
import PdfColumnHeader from './header';
import theme from '../../../../theme';

export interface PdfTableColumnProps {
  label: string;
  content: string;
  id: string;
}

const PdfTableColumn: React.FC<PdfTableColumnProps & { isLast?: boolean }> = (
  props,
) => {
  return (
    <View
      style={{
        ...TableStyles.tableColumn,
        borderRight: !props.isLast
          ? `1px solid ${theme.colors.grey_4}`
          : undefined,
      }}
    >
      <PdfColumnHeader {...props} />
      <PdfColumnContent {...props} />
    </View>
  );
};

export default PdfTableColumn;
