import CopyOutlined from '@ant-design/icons/CopyOutlined';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { SearchResults } from '@hellodarwin/core/lib/features/entities';
import { getShortId } from '@hellodarwin/core/lib/features/helpers';
import theme from '@hellodarwin/core/lib/theme';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import Button from 'antd/es/button';
import List from 'antd/es/list';
import Skeleton from 'antd/es/skeleton';
import Tooltip from 'antd/es/tooltip';
import { Link } from 'react-router-dom';
import '../../layout/search-bar.scss';
import { copyIntoClipboard } from '../clipboard';

type SearchBarGrantsProps = {
  results: SearchResults;
  isSearching: boolean;
  onClose: () => void;
};

const SearchBarGrants = ({
  results,
  isSearching,
  onClose,
}: SearchBarGrantsProps) => {
  return (
    <Div style={{ position: 'relative', marginBottom: 24 }}>
      <Div className="search-results-title">
        <Typography elementTheme="body2">Grants</Typography>
      </Div>
      <List
        grid={{ gutter: 16, column: 2 }}
        itemLayout="horizontal"
        dataSource={results?.grants}
        renderItem={(p, index) => (
          <List.Item
            style={{
              border: `1px solid ${theme.colors.grey_1}`,
              backgroundColor: theme.colors.beige_4,
              padding: 8,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Skeleton title={false} loading={isSearching} active>
              <List.Item.Meta
                title={
                  <Link to={'/grants/' + p.grant_id} onClick={onClose}>
                    <Typography
                      elementTheme="overline"
                      style={{ marginTop: 7 }}
                    >
                      {`${p.grant_title} // ${p.grant_display_title}`}
                    </Typography>
                  </Link>
                }
                description={
                  <Div flex="row" gap={20}>
                    <Typography elementTheme="overline">
                      {'ID: '} {getShortId(p.grant_id)}
                    </Typography>
                  </Div>
                }
              />
              <Div
                onClick={() => window.open('/gin/' + p.grant_id, '_blank')}
                style={{
                  width: 60,
                  height: 20,
                  marginBottom: 7,
                }}
              >
                <Tooltip
                  placement="top"
                  zIndex={5000}
                  title={'Open in new page'}
                >
                  <Button style={{ width: 45, border: 0 }}>GIN</Button>
                </Tooltip>
              </Div>
              <Div
                onClick={() => window.open('/grants/' + p.grant_id, '_blank')}
                style={{
                  width: 60,
                  height: 20,
                  marginBottom: 7,
                }}
              >
                <Tooltip
                  placement="top"
                  zIndex={5000}
                  title={'Open in new page'}
                >
                  <Button
                    style={{ width: 45, border: 0 }}
                    icon={<NewWindow width={17} height={17} />}
                  ></Button>
                </Tooltip>
              </Div>
              <Div
                onClick={() => copyIntoClipboard(p.grant_id)}
                style={{ width: 60, height: 30, margin: 0 }}
              >
                <Tooltip
                  placement="top"
                  zIndex={5000}
                  title={'Copy ID To Clipboard'}
                >
                  <Button
                    style={{
                      width: 45,
                      border: 0,
                    }}
                    icon={<CopyOutlined />}
                  ></Button>
                </Tooltip>
              </Div>
            </Skeleton>
          </List.Item>
        )}
      />
    </Div>
  );
};

export default SearchBarGrants;
