import SubscriptionStatus from '../enums/subscription-status';
import { Company } from './companies-entities';
import { Portfolio } from './provider-portfolios-entities';
import { ReviewResult } from './reviews-entities';
import { MappedParseTags, ProviderTag } from './tags-entities';

export interface Provider extends Company {
  hourly_rate?: number;
  languages?: string;
  verified_at: string | null;
  portfolios?: Portfolio[];
  team_members?: TeamMember[];
  provider_id: string;
  billing_subscription_id?: string;
  status?: ProviderStatus;
  billing_customer_id?: string;
  canceled_reason?: string;
  score?: number;
  score_quality?: number;
  score_affiliate?: number;
  score_link_building?: number;
  score_credit_request?: number;
  score_bought_recently?: number;
  score_before?: number;
  network?: string;
  sb_uid?: string;
  sb_uid_referrer?: string;
  sb_promocode?: string;
  onboarded_at?: string;
  subscription_status?: SubscriptionStatus;
  subscription_plan_name?: string;
  subscription_plan_price?: number;
  subscription_plan_interval?: string;
  subscription_plan_interval_count?: number;
  aqt_at?: string | null;
  tags: MappedParseTags;
  rfp_bought_count: number;
  rfp_total_value: number;
  reviews_average: number;
  reviews_count: number;
  visible?: boolean;
  quality_note?: number;
  activated_at?: string;
  reviews?: ReviewResult[];
  hubspot_url?: string;
  referral_code?: string;
  available_raise_hands?: number;
  latitude?: number;
  longitude?: number;
  province_id?: string;
}

export enum ProviderStatus {
  Unverified = 'Unverified',
  Verified = 'Verified',
  Rejected = 'Rejected',
  Suspended = 'Suspended',
  Canceled = 'Canceled',
}

export enum QueriedProvidersType {
  Shortlisted = 'shortlisted',
  Raisedhand = 'raisedhand',
  Matched = 'matched',
  All = 'all',
}

export interface ProviderAdmin extends Omit<Provider, 'tags'> {
  tags: ProviderTag[];
}

export const ProviderInitialState: Provider = {
  provider_id: '',
  rfp_total_value: 0,
  rfp_bought_count: 0,
  reviews_average: 0,
  reviews_count: 0,
  tags: {},
  name: '',
  verified_at: '',
  company_id: '',
};

export interface TeamMember {
  provider_team_member_id: string;
  provider_id: string;
  name: string;
  role: string;
  linked_in: string;
  profile_picture: string;
  phone?: string;
  email?: string;
  order_team?: number;
}

export const InitialTeamMember: TeamMember = {
  profile_picture: '',
  provider_id: '',
  name: '',
  role: '',
  linked_in: '',
  provider_team_member_id: '',
};

export interface TeamMemberRequest {
  provider_id?: string;
  name: string;
  role: string;
  linked_in: string;
  order_team?: number;
}

export interface ProviderSearchResult {
  provider_id: string;
  provider_created_at: string;
  company_id: string;
  company_name: string;
  company_email: string;
  company_comments: string;
  provider_subscription_plan_name: string;
  provider_subscription_status: string;
  provider_status: string;
}
