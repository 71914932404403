import { View } from '@react-pdf/renderer';
import { PdfProps } from '../@types';
import AdminCard from '../components/admin-card';
import Group2Outlined from '../components/background/group2-outlined';
import PdfHdLogo from '../components/logo';
import { createTemplateStyle } from '../styles/templates';
import theme from '../theme';

const AdminPageTemplate: React.FC<PdfProps> = ({
  admin,
  locale,
  translations,
}) => {
  return (
    <View>
      <Group2Outlined
        width={618}
        height={903}
        color={theme.colors.beige_3}
        top={-27}
        margin={-120}
        align="right"
      />

      <View
        style={{
          ...createTemplateStyle({
            withFooter: false,
            withHeader: false,
            type: 'center',
          }),
          paddingTop: 54,
        }}
      >
        <PdfHdLogo backgroundType="light" />

        <AdminCard admin={admin} locale={locale} translations={translations} />
      </View>
    </View>
  );
};

export default AdminPageTemplate;
