import { Contact } from '@hellodarwin/core/lib/features/entities/core-entities';
import formatPhoneNumber from '@hellodarwin/core/lib/features/helpers/format-phone';
import Email from '@hellodarwin/icons/dist/icons/Email';
import Language from '@hellodarwin/icons/dist/icons/Language';
import Phone from '@hellodarwin/icons/dist/icons/Phone';
import CardLayout from '../../../gins/sidebars/cards/card-layout';
import useUserDropdownItems from './use-dropdown-items';

export interface UserCardProps {
  user: Contact;
  onContactClick: (contact: Contact) => void;
}

const UserCard: React.FC<UserCardProps> = ({ user, onContactClick }) => {
  const dropdownItems = useUserDropdownItems({ user });

  const onClick = () => onContactClick(user);
  return (
    <CardLayout
      key={user.contact_id}
      title={user.name}
      subtitle={user.title}
      infos={[
        {
          Icon: Phone,
          value: !!user.phone ? formatPhoneNumber(user.phone) : undefined,
        },
        { Icon: Email, value: user.email },
        { Icon: Language, value: user.preferred_language },
      ]}
      dropdownItems={dropdownItems}
      onClick={onClick}
    />
  );
};

export default UserCard;
