import Div from '@hellodarwin/core/lib/components/common/div';
import SidebarPageLayout, {
  OnSidebarChangeFunc,
} from '@hellodarwin/core/lib/components/common/layout/sidebar-page-layout.tsx';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import Button from 'antd/es/button';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app';
import GinApplicationSingle from '../../components/gins/application-single';
import { GinApplicationSinglePageSection } from '../../components/gins/application-single/use-gin-application-blocks';
import ApplicationCompanyCard from '../../components/gins/sidebars/cards/company-card';
import ApplicationContactCard from '../../components/gins/sidebars/cards/contact-card';
import ApplicationProgramCard from '../../components/gins/sidebars/cards/program-card';
import ProgramContactsModal from '../../components/gins/sidebars/cards/program-contacts-modal';
import GinSidebarMenu from '../../components/gins/sidebars/menu';
import RelatedApplicationsList from '../../components/gins/sidebars/related-applications';
import {
  fetchGinApplicationCompany,
  fetchGinApplicationContacts,
  selectGinApplication,
  selectGinApplicationCompany,
  selectGinApplicationContacts,
  selectGinProgramContactsModal,
  toggleProgramContactsModal,
} from '../../features/api/slices/gins-slice';
import { useNewAdminApi } from '../../features/api/use-admin-api';
import { useAdminSettings } from '../../features/providers/admin-settings-provider';

const SingleProgramPage = () => {
  const { t } = useTranslations();
  const { programId } = useParams<{ programId: string }>();
  const dispatch = useAppDispatch();
  const api = useNewAdminApi();
  const application = useAppSelector(selectGinApplication);
  const programContactsModal = useAppSelector(selectGinProgramContactsModal);
  const programContacts = useAppSelector(selectGinApplicationContacts);
  const company = useAppSelector(selectGinApplicationCompany);
  const navigate = useNavigate();
  const handleProgramContactsModalOpen = () => {
    dispatch(toggleProgramContactsModal({ isVisible: true }));
  };

  const { getSetting, updateSetting } = useAdminSettings();
  const sidebarSetting = getSetting('applicationSidebarsCollapsed');

  useEffect(() => {
    if (programId) {
      dispatch(fetchGinApplicationCompany({ api, applicationId: programId }));
      dispatch(fetchGinApplicationContacts({ api, applicationId: programId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  const sections: GinApplicationSinglePageSection[] = [
    GinApplicationSinglePageSection.ProductionStages,
    GinApplicationSinglePageSection.Notes,
    GinApplicationSinglePageSection.ImportantDate,
    GinApplicationSinglePageSection.Files,
  ];
  const handleBack = () => {
    navigate(`/programs`);
  };

  const onSidebarChange: OnSidebarChangeFunc = (side, value) => {
    updateSetting('applicationSidebarsCollapsed', {
      ...sidebarSetting,
      [side]: value,
    });
  };

  return (
    <SidebarPageLayout
      title={application?.application_name}
      app="admin"
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: t('application_list|applications'),
          path: '/programs',
        },
        {
          breadcrumbName: application?.application_name,
        },
      ]}
      rightSidebarBlocks={[
        {
          title: t('application_single|application_company'),
          content: <ApplicationCompanyCard company={company} />,
        },
        {
          title: (
            <Div flex="row" justify="space-between" align="center">
              {t('application_single|application_contact')}
              <Button
                onClick={handleProgramContactsModalOpen}
                style={{ border: 'none', cursor: 'pointer' }}
                icon={<Edit size={16} color={theme.colors.purple_1} />}
              ></Button>
            </Div>
          ),
          content: <ApplicationContactCard programContacts={programContacts} />,
        },
        {
          title: t('application_single|application_program'),
          content: <ApplicationProgramCard applicationId={programId ?? ''} />,
        },
      ]}
      leftSidebarBlocks={[
        { title: 'Menu', content: <GinSidebarMenu menu_items={sections} /> },
        {
          title: t('application_single|other_company_applications'),
          content: <RelatedApplicationsList applicationId={programId ?? ''} />,
        },
      ]}
      defaultLeftSidebarCollapsed={sidebarSetting.left}
      defaultRightSidebarCollapsed={sidebarSetting.right}
      defaultNavCollapsed
      noFooter
      handleBack={handleBack}
      onSidebarChange={onSidebarChange}
    >
      <ProgramContactsModal
        isVisible={programContactsModal.isVisible}
        handleClose={() =>
          dispatch(
            toggleProgramContactsModal({
              isVisible: false,
            }),
          )
        }
        applicationId={programId || ''}
        programContacts={programContacts}
        companyId={company?.company_id || ''}
      />
      <GinApplicationSingle
        applicationId={programId ?? ''}
        sections={sections}
      />
    </SidebarPageLayout>
  );
};
export default SingleProgramPage;
