import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import RichTextEditor from '@hellodarwin/core/lib/components/common/rich-editor';
import SeeMoreTags from '@hellodarwin/core/lib/components/common/see-more/see-more-tags';
import FormItem from '@hellodarwin/core/lib/components/forms/form-layouts/form-item';
import validateAnyType from '@hellodarwin/core/lib/components/forms/utils/validate-any-type';
import {
  NewTag,
  ProjectTag,
  TagType,
} from '@hellodarwin/core/lib/features/entities';
import {
  Project,
  ProjectFormValues,
} from '@hellodarwin/core/lib/features/entities/projects-entities';
import { dollarFormat } from '@hellodarwin/core/lib/features/helpers';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import { Input, InputNumber, InputNumberProps, Rate } from 'antd';
import DatePicker from 'antd/es/date-picker';
import Form, { RuleObject } from 'antd/es/form';
import { useForm, useWatch } from 'antd/es/form/Form';
import { StoreValue } from 'antd/es/form/interface';
import { RangePickerTimeProps } from 'antd/es/time-picker';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app';
import {
  fetchChildTags,
  fetchProjectTags,
  selectExpenseCategoryTagsOptions,
  selectExpenseTagsOptions,
  selectGoalTagsOptions,
  selectProjectTags,
  selectSelectedTags,
  setSelectedTags,
} from '../../../../../features/api/slices/new-tags-slice';
import { useNewAdminApi } from '../../../../../features/api/use-admin-api';
import TagsDrawer from '../../../../tags/tags-drawer';
import TagSelectionDropdowns from '../../../../tags/tags-dropdowns';
import { PriorityBox } from './styles';

const { RangePicker } = DatePicker;

export interface ProjectFormProps {
  project: Project;
  isModal?: boolean;
  isPriority?: boolean;
  withProjectType?: boolean;
  handleSaveProject: (values: ProjectFormValues) => void;
}

export interface ProjectFormModalProps {
  project: Project;
  parentProject?: Project;
  open: boolean;
  handleCancel: () => void;
  handleSaveProject: (values: ProjectFormValues) => void;
}

const ProjectForm: React.FC<ProjectFormProps> = ({
  project,
  isModal,
  withProjectType,
  handleSaveProject,
  isPriority,
}) => {
  const [form] = useForm<ProjectFormValues>();
  const { t } = useTranslations();
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();
  const tags = useAppSelector(selectProjectTags);
  const goalTagsOptions = useAppSelector(selectGoalTagsOptions);
  const expenseCategoryTagsOptions = useAppSelector(
    selectExpenseCategoryTagsOptions,
  );
  const expenseTagsOptions = useAppSelector(selectExpenseTagsOptions);

  const selectedTags = useAppSelector(selectSelectedTags);
  const [projectTags, setProjectTags] = useState<ProjectTag[] | NewTag[]>([]);

  const formValues = useWatch([], form);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [isProjectTagsDrawerOpen, setIsProjectTagsDrawerOpen] = useState(false);

  useEffect(() => {
    setProjectTags([]);
    dispatch(setSelectedTags([]));
    if (project.project_id) {
      const fetchTags = async () => {
        try {
          await dispatch(
            fetchProjectTags({
              api,
              projectId: project.project_id,
              locale: selectedLocale,
            }),
          );
        } catch (error) {
          console.error('Failed to fetch project tags:', error);
        }
      };
      fetchTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.project_id]);

  useEffect(() => {
    if (tags) {
      setProjectTags(tags);
      form.setFieldsValue({ tags });
    }
  }, [tags, form]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, formValues]);

  const inputDollarFormat: InputNumberProps<number>['formatter'] = (value) => {
    return dollarFormat(value, selectedLocale);
  };

  const initialValues: ProjectFormValues = useMemo(() => {
    const timeline: RangePickerTimeProps<dayjs.Dayjs>['defaultValue'] = [
      !!project.started_date ? dayjs(project.started_date) : undefined,
      !!project.end_date ? dayjs(project.end_date) : undefined,
    ];

    const values: ProjectFormValues = {
      title: project.title,
      description: project.description ?? '',
      priority: project.priority ?? 3,
      estimated_budget: project.estimated_budget ?? 0,
      timeline: timeline,
      comments: project.comments ?? '',
      project_type: project.project_type ?? '',
      tags: tags ?? [],
    };
    return values;
  }, [project, tags]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  const [selectedGoals, setSelectedGoals] = useState<NewTag[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<NewTag[]>([]);
  const [selectedExpenses, setSelectedExpenses] = useState<NewTag[]>([]);

  const handleTagChange = (
    values: string[],
    setSelected: React.Dispatch<React.SetStateAction<NewTag[]>>,
    type: TagType,
  ) => {
    const options = getOptions(type);
    const newTags = values.map((value) => options[value]);

    setSelected(newTags);

    const updatedSelectedTags = [
      ...selectedTags.filter(
        (tag) => !newTags.some((newTag) => newTag.tag_id === tag.tag_id),
      ),
      ...newTags,
    ];

    dispatch(setSelectedTags(updatedSelectedTags));
  };

  const getOptions = (type: TagType) => {
    switch (type) {
      case TagType.Goal:
        return goalTagsOptions.entities;
      case TagType.ExpenseCategory:
        return expenseCategoryTagsOptions.entities;
      case TagType.Expense:
        return expenseTagsOptions.entities;
      default:
        return {};
    }
  };

  useEffect(() => {
    dispatch(
      fetchChildTags({
        api,
        parentIds: [],
        locale: selectedLocale,
        type: TagType.Goal,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch child tags dynamically based on selected parent tags
  useEffect(() => {
    const parentIds = selectedGoals?.map((goal) => goal.tag_id);
    dispatch(
      fetchChildTags({
        api,
        parentIds,
        locale: selectedLocale,
        type: TagType.ExpenseCategory,
      }),
    );
    setSelectedCategories(
      selectedCategories.filter((expenseCategory) =>
        parentIds.includes(expenseCategory.parent_id),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoals, selectedLocale]);

  useEffect(() => {
    const parentIds = selectedCategories?.map(
      (expenseCategory) => expenseCategory.tag_id,
    );
    dispatch(
      fetchChildTags({
        api,
        parentIds,
        locale: selectedLocale,
        type: TagType.Expense,
      }),
    );
    setSelectedExpenses(
      selectedExpenses.filter((expense) =>
        parentIds.includes(expense.parent_id),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories, selectedLocale]);

  const onSave = () => {
    setSubmittable(false);
    form
      .validateFields()
      .then((values) => {
        const formData: ProjectFormValues = {
          ...values,
        };

        if (isModal) {
          formData.tags = selectedTags;
        }

        handleSaveProject(formData);
      })
      .catch((e) => console.error(e));
  };

  const openTagDrawer = () => setIsProjectTagsDrawerOpen(true);

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          width: '100%',
        }}
        initialValues={initialValues}
        onFinish={onSave}
      >
        <FormItem
          label={
            isPriority
              ? t('project|priority_title')
              : t('project|project_title')
          }
          name="title"
          rules={[
            {
              required: true,
              validator: validateAnyType({}),
              message: t('form|required', {
                label: isPriority
                  ? t('project|priority_title')
                  : t('project|project_title'),
              }),
            },
          ]}
        >
          <Input size="large" />
        </FormItem>
        <Div flex={'row'} tablet={{ flex: 'column', gap: 16 }} gap={32}>
          {!!withProjectType && !isPriority && (
            <FormItem
              label={t('project|project_type')}
              name="project_type"
              style={{ flex: 2 }}
              rules={[
                {
                  required: true,
                  validator: validateAnyType({}),
                  message: t('form|required', {
                    label: t('project|project_type'),
                  }),
                },
              ]}
            >
              <Input size="large" />
            </FormItem>
          )}
          {!isPriority && (
            <FormItem
              label={t('project|project_priority')}
              name="priority"
              style={{ flex: 1 }}
              required
              rules={[
                {
                  required: true,
                  validator: validateAnyType({}),
                  message: t('form|required', {
                    label: t('project|project_priority'),
                  }),
                },
              ]}
            >
              <Rate
                character={({ index, value }) => (
                  <PriorityBox
                    $active={(index ?? 0) <= (value ?? 0) - 1}
                    $value={index ?? 0}
                  >
                    {(index ?? 0) + 1}
                  </PriorityBox>
                )}
              />
            </FormItem>
          )}
        </Div>
        {!isPriority && (
          <FormItem
            name="tags"
            label={t('project|project_tags')}
            rules={[
              {
                required: true,
                validator: () => {
                  const tagsToValidate = isModal ? selectedTags : projectTags;
                  if (tagsToValidate.length > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      t('form|required', {
                        label: t('project|project_tags'),
                      }),
                    ),
                  );
                },
                message: t('form|required', {
                  label: t('project|project_tags'),
                }),
              },
            ]}
          >
            {isModal ? (
              <TagSelectionDropdowns
                selectedGoals={selectedGoals}
                selectedCategories={selectedCategories}
                selectedExpenses={selectedExpenses}
                onGoalsChange={(values) =>
                  handleTagChange(values, setSelectedGoals, TagType.Goal)
                }
                onCategoriesChange={(values) =>
                  handleTagChange(
                    values,
                    setSelectedCategories,
                    TagType.ExpenseCategory,
                  )
                }
                onExpensesChange={(values) =>
                  handleTagChange(values, setSelectedExpenses, TagType.Expense)
                }
                goalTags={goalTagsOptions.entities}
                expenseCategoryTags={expenseCategoryTagsOptions.entities}
                expenseTags={expenseTagsOptions.entities}
                parentGoals={selectedGoals}
                parentCategories={selectedCategories}
              />
            ) : (
              <Div flex={'row'} justify="space-between" gap={16}>
                <SeeMoreTags limit={10} size="medium" content={projectTags} />
                <Button
                  defaultStyle={theme.colors.grey_3}
                  transparent
                  size="square"
                  headingIcon={<Edit size={20} />}
                  onClick={openTagDrawer}
                />
              </Div>
            )}
          </FormItem>
        )}
        {!isPriority && (
          <Div flex={isModal ? 'row' : 'column'} gap={isModal ? 32 : 16}>
            <FormItem
              label={t('project|project_timeline')}
              name="timeline"
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                  validator: (rules: RuleObject, value: StoreValue) => {
                    const { required, message } = rules;

                    if (!required) return Promise.resolve();

                    if (!!value[0]) return Promise.resolve();

                    return Promise.reject(new Error(`${message}`));
                  },
                  message: t('form|required', {
                    label: t('project|project_timeline'),
                  }),
                },
              ]}
            >
              <RangePicker style={{ width: '100%' }} size="large" />
            </FormItem>

            <Div
              flex={'row'}
              tablet={{ flex: 'column', gap: 16 }}
              style={{ flex: 1 }}
              gap={32}
            >
              <FormItem
                style={{ flex: 1 }}
                label={t('project|project_estimated_budget')}
                name="estimated_budget"
                rules={[
                  {
                    required: false,
                    validator: (rules: RuleObject, value: StoreValue) => {
                      const { required, message } = rules;

                      if (!required) return Promise.resolve();
                      if (value > 0) return Promise.resolve();

                      return Promise.reject(new Error(`${message}`));
                    },
                    message: t('form|required', {
                      label: t('project|project_estimated_budget'),
                    }),
                  },
                ]}
              >
                <InputNumber
                  prefix="$"
                  size="large"
                  style={{ width: '100%' }}
                  formatter={inputDollarFormat}
                />
              </FormItem>
            </Div>
          </Div>
        )}
        <FormItem
          label={
            isPriority
              ? t('project|priority_description')
              : t('project|project_description')
          }
          name="description"
          rules={[
            {
              required: false,
              validator: validateAnyType({}),
              message: t('form|required', {
                label: isPriority
                  ? t('project|priority_description')
                  : t('project|project_description'),
              }),
            },
          ]}
        >
          <RichTextEditor initialValue={project.description ?? ''} />
        </FormItem>
      </Form>
      <Button
        defaultStyle={theme.colors.purple_2}
        style={
          !isModal
            ? {
                position: 'fixed',
                top: 12,
                right: 12,
                zIndex: 1000,
              }
            : { alignSelf: 'center' }
        }
        onClick={onSave}
        disabled={!submittable}
      >
        {t('button|save')}
      </Button>
      {!isModal && (
        <TagsDrawer
          visible={isProjectTagsDrawerOpen}
          onClose={() => setIsProjectTagsDrawerOpen(false)}
          currentTags={projectTags}
          entityType="Project"
          entityId={project.project_id}
          locale={selectedLocale}
          onChange={(updatedTags) => {
            setProjectTags(updatedTags);
            form.setFieldsValue({ tags: updatedTags });
          }}
        />
      )}
    </>
  );
};

export default ProjectForm;
