import Typography from '@hellodarwin/core/lib/components/common/typography';
import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';
import { AddNewProjectItemModalState } from '../../types';
import { ProjectItemTileContainer } from './styles';

interface ProjectItemTileProps {
  label: string;
  Icon: IconNode;
  id: AddNewProjectItemModalState;
  onClick: (id: AddNewProjectItemModalState) => void;
}

const ProjectItemTile: React.FC<ProjectItemTileProps> = ({
  label,
  Icon,
  onClick,
  id,
}) => {
  const handleClick = () => onClick(id);
  return (
    <ProjectItemTileContainer id={id} onClick={handleClick}>
      <Icon size={48} />
      <Typography elementTheme="subtitle2">{label}</Typography>
    </ProjectItemTileContainer>
  );
};

export default ProjectItemTile;
