import { View } from '@react-pdf/renderer';

import { PdfProps } from '../@types';
import Group1Filled from '../components/background/group1-filled';
import Typography from '../components/common/typography';
import PdfHdLogo from '../components/logo';
import { PdfLayoutStyles } from '../styles/layout';
import { createTemplateStyle } from '../styles/templates';
import theme from '../theme';

const PdfTitlePage: React.FC<PdfProps> = ({
  titlePage: { title, subtitle, footerInfos },
}) => {
  return (
    <>
      <Group1Filled
        width={604}
        height={553}
        color={theme.colors.purple_3}
        top={-27}
        margin={-112}
      />
      <View style={PdfLayoutStyles.header}>
        <PdfHdLogo backgroundType="dark" />
      </View>
      <View
        style={createTemplateStyle({
          withFooter: true,
          withHeader: true,
          type: 'center',
        })}
      >
        <Typography elementTheme="h1" color={theme.colors.white_1}>
          {title}
        </Typography>
        <Typography elementTheme="body1" color={theme.colors.white_1}>
          {subtitle}
        </Typography>
      </View>
      <View style={PdfLayoutStyles.footer}>
        {footerInfos.map((info) => (
          <Typography
            textAlign="left"
            elementTheme="body3"
            color={theme.colors.white_1}
            parseHtml
            key={info.value}
          >{`${info.label} : <b>${info.value}</b>`}</Typography>
        ))}
      </View>
    </>
  );
};

export default PdfTitlePage;
