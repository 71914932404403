import dayjs from 'dayjs';

export const getStringDate = (
  date: Date,
  locale: string | undefined,
): string => {
  if (locale == undefined) {
    locale = 'en';
  }
  if (locale === 'fr') {
    return dayjs(date).locale(locale).format('D MMMM YYYY');
  } else if (locale === 'en') {
    return dayjs(date).locale(locale).format('MMMM D, YYYY');
  } else {
    return date.toString();
  }
};
