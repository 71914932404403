import Div from '@hellodarwin/core/lib/components/common/div';
import { Company } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import message from 'antd/es/message';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app';
import {
  fetchAdminById,
  selectAdminById,
} from '../../../../features/api/slices/admins-slice';
import {
  selectCompaniesIsLoading,
  selectCompanyById,
  updateCompany,
} from '../../../../features/api/slices/companies-slice';
import { useAdminApi } from '../../../../features/api/use-admin-api';
import AccountManagerCard from './account-manager-card';
import AccountManagerFormModal from './account-manager-form-modal';

interface CompanyUsersListProps {
  company_id?: string;
}

const CompanyAdminsList: React.FC<CompanyUsersListProps> = ({ company_id }) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { t } = useTranslations();
  const isLoading = useAppSelector(selectCompaniesIsLoading);
  const [accountManagerModalOpen, setAccountManagerModalOpen] = useState(false);
  const company = useAppSelector((state) =>
    selectCompanyById(state, company_id ?? ''),
  );
  const adminProfile = useAppSelector((state) =>
    company?.account_manager
      ? selectAdminById(state, company.account_manager!)
      : undefined,
  );

  useEffect(() => {
    if (!!company?.account_manager) {
      dispatch(fetchAdminById({ api, adminId: company.account_manager || '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id]);

  useEffect(() => {
    if (!!company?.account_manager) {
      dispatch(fetchAdminById({ api, adminId: company.account_manager || '' }));
    }
  }, [company, dispatch, api]);

  const onAMClick = () => {
    setAccountManagerModalOpen(true);
  };

  const handleAMDelete = () => {
    handleAMSelect(undefined);
  };

  const handleClose = () => {
    setAccountManagerModalOpen(false);
  };

  const handleAMSelect = async (adminId: string | undefined) => {
    try {
      const updatedCompany: Company = {
        ...company,
        account_manager: adminId,
      };

      await dispatch(updateCompany({ api, company: updatedCompany }));
      message.success(t('companyForm|saved'));
    } catch (e: any) {
      message.error(t('companyForm|messageError'));
      console.error(e);
    }

    handleClose();
  };

  return (
    <>
      {!!adminProfile && (
        <Div flex="column" gap={4}>
          <AccountManagerCard
            accountManager={adminProfile}
            onAMClick={onAMClick}
          />
        </Div>
      )}

      <AccountManagerFormModal
        open={accountManagerModalOpen}
        handleSelection={handleAMSelect}
        handleDelete={handleAMDelete}
        handleClose={handleClose}
        adminProfile={adminProfile}
        isLoading={isLoading}
      />
    </>
  );
};

export default CompanyAdminsList;
