import { Page, View } from '@react-pdf/renderer';
import { ProjectResponse } from '../../../@types/projects-types';
import TitleWithDescriptionCard from '../../../components/cards/title-with-description';
import PageHeader from '../../../components/header';
import { PdfPagesStyles } from '../../../styles/pages';
import { createTemplateStyle } from '../../../styles/templates';

interface GamePlanBusinessPrioritiesProps {
  label: string;
  projects: ProjectResponse[];
}
const GamePlanBusinessPriorities: React.FC<GamePlanBusinessPrioritiesProps> = ({
  projects,
  label,
}) => {
  return (
    <Page
      size="A4"
      orientation="landscape"
      style={PdfPagesStyles.pageLevel2}
      id="priorities"
    >
      <PageHeader title={label} />
      <View
        style={createTemplateStyle({
          withFooter: false,
          withHeader: true,
          type: 'wrap',
        })}
      >
        {projects?.map((p) => (
          <TitleWithDescriptionCard
            title={p.title}
            description={p.description}
            key={p.project_id}
            isWrapped
          />
        ))}
      </View>
    </Page>
  );
};

export default GamePlanBusinessPriorities;
