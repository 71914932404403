import { Image, Link, View } from '@react-pdf/renderer';
import { PdfTranslations } from '../../@types';
import { AdminProfile } from '../../@types/company-types';
import theme from '../../theme';
import Typography from '../common/typography';
import { TypographyStyles } from '../common/typography/styles';

export interface AdminCardProps {
  admin: AdminProfile;
  locale: string;
  translations: PdfTranslations;
}

const AdminCard: React.FC<AdminCardProps> = ({
  admin,
  locale,
  translations,
}) => {
  return (
    <View
      style={{
        backgroundColor: theme.colors.white_1,
        border: `1px solid ${theme.colors.grey_1}`,
        width: 478,
        height: 'auto',
      }}
    >
      <View
        style={{
          padding: '24px 32px 8px 32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 4,
        }}
      >
        <Image
          src={admin.picture}
          style={{
            width: 180,
            height: 180,
            border: `1px solid ${theme.colors.grey_1}`,
            backgroundColor: theme.colors.grey_4,
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
        <Typography>{`${admin.first_name} ${admin.last_name}`}</Typography>
      </View>
      <View
        style={{
          padding: '16px 32px',
          backgroundColor: theme.colors.grey_1,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          alignItems: 'center',
        }}
      >
        <Typography elementTheme="subtitle1" color={theme.colors.white_1}>
          {translations.adminCardTitle}
        </Typography>
        <Typography elementTheme="body3" color={theme.colors.white_1}>
          {translations.adminCardDescription}
        </Typography>
        <Link
          style={{ ...TypographyStyles.body3, color: theme.colors.purple_3 }}
          href={locale === 'en' ? admin.meeting_link_en : admin.meeting_link_fr}
        >
          {translations.adminCardCta}
        </Link>
      </View>
    </View>
  );
};

export default AdminCard;
