import Div from '@hellodarwin/core/lib/components/common/div';
import {
  Contact,
  Grant,
  GrantResult,
  Program,
  ProgramGrantStatus,
  ProgramSource,
  ProgramType,
} from '@hellodarwin/core/lib/features/entities';
import { Company } from '@hellodarwin/core/lib/features/entities/core-entities';
import Form from 'antd/es/form';
import { Col, Row } from 'antd/es/grid';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import Modal from 'antd/es/modal';
import notification from 'antd/es/notification';
import Radio from 'antd/es/radio';

import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Select from 'antd/es/select';
import Typography from 'antd/es/typography';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app';
import { selectAuthentifiedUser } from '../../../features/api/slices/global-slice';
import { createContact } from '../../../features/api/slices/users-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';
import useDebounce from '../../../hooks/use-debounce';
import SelectAdmin from './select-admin';
import SelectCompany from './select-company';
import SelectGrant from './select-grant';

const SEARCH_DELAY_MS = 300;
export const EMAIL_ALREADY_EXISTS_ERROR = 5023;

type CreateProgramFormProps = {
  visible: boolean;
  handleClose: () => void;
  companyId?: string;
  companies?: Company[];
  grants?: Grant[];
};

export const ProgramGrantStatusProd = [
  'ongoing_information_required',
  'ongoing_work_hellodarwin',
  'validation_required',
  'completed_to_submit',
  'submitted_waiting_for_results',
  'accepted',
  'refused',
  'not_submited',
];

const CreateProgramForm = ({
  visible,
  handleClose,
  companyId,
  companies,
}: CreateProgramFormProps) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isNewContact, setIsNewContact] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<
    Contact[] | undefined
  >();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedGrant, setSelectedGrant] = useState<GrantResult | undefined>();
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>();
  const [searchTerm, setSearchTerm] = useState('');
  const user = useAppSelector(selectAuthentifiedUser);
  const { t } = useTranslations();

  useEffect(() => {
    if (selectedCompany) {
      initContacts(selectedCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({ program_account_manager: user.admin_id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const initContacts = async (companyId: string) => {
    try {
      const response = await api.queryCompanyContacts(companyId, 1, 20, '');
      setContacts(response);
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
    }
  };

  const onFinish = async (values: any) => {
    setIsSaving(true);
    try {
      let newProgram: Program = {
        ...values,
      };
      if (isNewContact) {
        const contactFetch = await api.getContactByEmail(values.contact_email);
        if (contactFetch) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response: { data: { error_code: EMAIL_ALREADY_EXISTS_ERROR } },
          };
        }
        await dispatch(
          createContact({
            api,
            contact: {
              contact_id: '',
              name: values.contact_name,
              company_id: selectedCompany || '',
              preferred_language: values.project_language,
              email: values.contact_email,
            },
            destination: 'Client',
          }),
        );

        const contact = await api.fetchCompanyContacts(selectedCompany || '');
        newProgram.program_contact_id = contact[contact.length - 1].contact_id;
        newProgram.notification_contacts = [contact[contact.length - 1]];
      }

      if (companyId) {
        newProgram.program_company_id = companyId;
      }

      if (selectedCompany) {
        newProgram.program_company_id = selectedCompany;
      }

      newProgram.program_name = selectedGrant?.grant_display_title;
      if (selectedContacts && selectedContacts.length > 0) {
        newProgram.program_contact_id = selectedContacts[0].contact_id;
        newProgram.program_company_id = selectedContacts[0].company_id;
        newProgram.contacts = selectedContacts;
        newProgram.notification_contacts = selectedContacts;
      }

      newProgram.program_type = ProgramType.GrantsRoadmap;
      const program = await api.createProgram(newProgram);

      setSelectedGrant(undefined);
      setIsSaving(false);
      handleClose();
      if (!companyId || companies) {
        navigate('/programs/' + program.program_id);
      }
      message.success('Saved!');
    } catch (e: any) {
      setIsSaving(false);

      if (e.response?.data?.error_code === EMAIL_ALREADY_EXISTS_ERROR) {
        notification.error({
          message: 'Email already exists',
          description: 'This email is already associated with a contact',
        });
        return;
      }

      message.error('Something went wrong, try again later!');
      console.error(e);
    }
  };

  const onSearch = async (query: string) => {
    if (query === '' && (!companyId || companyId === 'default')) return;

    setIsSearching(true);
    try {
      const response = companyId
        ? await api.queryCompanyContacts(companyId, 1, 8, query)
        : await api.queryContacts(1, 8, query, true);
      setContacts(response);
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
    } finally {
      setIsSearching(false);
    }
  };

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleSelectCompany = (companyId: string) => {
    setSelectedCompany(companyId);
    form.setFieldsValue({ company_id: companyId });
  };

  const handleSelectGrant = (grant: GrantResult) => {
    setSelectedGrant(grant);
    form.setFieldsValue({ program_grant_id: grant.grant_id });
  };

  const handleSelectAdmin = (adminId: string) => {
    form.setFieldsValue({ program_account_manager: adminId });
  };

  return (
    <Modal
      title={t('createApplication|create_application_title')}
      afterClose={() => {
        setIsNewContact(false);
        setSelectedContacts(undefined);
        setSelectedGrant(undefined);
        form.resetFields();
      }}
      open={visible}
      okText={t('createApplication|button_create')}
      okButtonProps={{ loading: isSaving }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
      cancelText={t('createApplication|cancel')}
      onCancel={handleClose}
      keyboard={true}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="create-program"
      >
        <Row gutter={16}>
          <Col span={24}>
            {!!companies && (
              <Form.Item
                label={t('createApplication|application_company')}
                name="company_id"
                rules={[
                  {
                    required: true,
                    message: t('createApplication|missing_company'),
                  },
                ]}
              >
                <SelectCompany handleSubmit={handleSelectCompany} />
              </Form.Item>
            )}
            <Form.Item
              label={t('createApplication|application_manager')}
              name="program_account_manager"
              rules={[
                {
                  required: true,
                  message: t('createApplication|missing_application_manager'),
                },
              ]}
            >
              <SelectAdmin
                handleSubmit={handleSelectAdmin}
                initialAdminId={user.admin_id}
              />
            </Form.Item>
            <Form.Item
              label={t('createApplication|program_source')}
              name="program_source"
              rules={[
                {
                  required: true,
                  message: t('createApplication|missing_program_source'),
                },
              ]}
            >
              <Select
                onChange={(e) => {
                  form.setFieldValue('program_source', e);
                }}
              >
                {(
                  Object.keys(ProgramSource) as (keyof typeof ProgramSource)[]
                ).map((key, index) => (
                  <Select.Option key={index} value={ProgramSource[key]}>
                    {ProgramSource[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('createApplication|application_program')}
              name="program_grant_id"
              rules={[
                {
                  required: true,
                  message: t('createApplication|missing_application_program'),
                },
              ]}
            >
              <SelectGrant handleSubmit={handleSelectGrant} />
            </Form.Item>
            <Form.Item
              label={t('createApplication|status')}
              name="program_grant_status"
              onReset={() =>
                form.setFieldValue(
                  'program_grant_status',
                  'identified_opportunitites',
                )
              }
            >
              <Select
                onChange={(e) => {
                  form.setFieldValue('program_grant_status', e);
                }}
              >
                {(
                  Object.keys(
                    ProgramGrantStatus,
                  ) as (keyof typeof ProgramGrantStatus)[]
                ).map((key, index) => (
                  <Select.Option key={index} value={ProgramGrantStatus[key]}>
                    {t(`programs_labels|${ProgramGrantStatus[key]}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Div style={{ marginBottom: 15 }}>
              {companyId ? (
                <>
                  <Form.Item
                    label={t('createApplication|contact')}
                    name="notifications_contacts"
                    style={{ marginBottom: 0, paddingBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: t('createApplication|minimum_contacts'),
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      style={{ width: '100%' }}
                      loading={isSearching}
                      filterOption={false}
                      showSearch
                      placeholder={t(
                        'createApplication|search_company_contacts',
                      )}
                      defaultActiveFirstOption={false}
                      onChange={(values) => {
                        const selectedContacts = contacts.filter((c) =>
                          values.includes(c.contact_id),
                        );
                        setSelectedContacts(selectedContacts);
                        form.setFieldValue('notification_contacts', values);
                        if (companyId) {
                          const value =
                            values.length > 0 ? values[0] : 'placeholder';
                          form.setFieldValue('program_contact_id', value);
                        }
                      }}
                      onSearch={handleSearch}
                    >
                      {contacts.map((c) => (
                        <Select.Option key={c.contact_id} value={c.contact_id}>
                          {c.name} / {c.email}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Div
                    flex="row"
                    justify="space-between"
                    style={{ marginBottom: 15 }}
                  >
                    <Typography.Text strong>
                      {t('createApplication|for') + ':'}{' '}
                    </Typography.Text>
                    <Radio.Group
                      options={[
                        {
                          label: t('createApplication|contact_exist'),
                          value: '0',
                        },
                        {
                          label: t('createApplication|new_contact'),
                          value: '1',
                        },
                      ]}
                      onChange={(e) => {
                        setSelectedContacts(undefined);
                        setIsNewContact(e.target.value === '1');
                      }}
                      value={isNewContact ? '1' : '0'}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Div>
                  {!isNewContact ? (
                    <Select
                      mode="multiple"
                      style={{ width: '100%' }}
                      loading={isSearching}
                      filterOption={false}
                      showSearch
                      placeholder={t(
                        'createApplication|search_company_contacts',
                      )}
                      defaultActiveFirstOption={false}
                      onChange={(value) => {
                        const selectedContacts = contacts.filter((c) =>
                          value.includes(c.contact_id),
                        );
                        setSelectedContacts(selectedContacts);
                        form.setFieldValue('notification_contacts', value);
                        if (companyId) {
                          form.setFieldValue(
                            'program_contact_id',
                            value ?? 'placeholder',
                          );
                        }
                      }}
                      onSearch={handleSearch}
                    >
                      {contacts.map((c) => (
                        <Select.Option key={c.contact_id} value={c.contact_id}>
                          {c.name} / {c.email}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <>
                      <Col span={24}>
                        <Form.Item
                          label={t('createApplication|fullName')}
                          name="contact_name"
                          rules={[
                            {
                              required: true,
                              message: t('createApplication|missing_fullName'),
                            },
                          ]}
                        >
                          <Input placeholder="Name" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={t('createApplication|only_email')}
                          name="contact_email"
                          rules={[
                            {
                              required: true,
                              message: t('createApplication|emailRequired'),
                            },
                            {
                              type: 'email',
                              message: t('createApplication|email_valid'),
                            },
                          ]}
                        >
                          <Input placeholder={t('createApplication|email')} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={t('createApplication|preferredLanguage')}
                          name="project_language"
                          rules={[
                            {
                              required: true,
                              message: t('createApplication|language_required'),
                            },
                          ]}
                        >
                          <Select
                            placeholder={t('createApplication|selectLanguage')}
                          >
                            <Select.Option value="fr">Français</Select.Option>
                            <Select.Option value="en">English</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </>
              )}
            </Div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateProgramForm;
