import { Styles, Text, View } from '@react-pdf/renderer';
import React from 'react';
import Html from 'react-pdf-html';
import theme from '../../../theme';
import { ListStyles } from '../list/styles';
import { TypographyProps } from './types';
import getTypographyFontWeight from './utils/get-font-weight';
import getTypographyStyle from './utils/get-typography-style';
import useRenderLi from './utils/render-li';

const Typography: React.FC<TypographyProps> = (props) => {
  const {
    children,
    elementTheme = 'body1',
    weight = 'regular',
    parseHtml = false,
    color = theme.colors.grey_1,
    textAlign = 'center',
    fullWidth = false,
    noLineHeight = false,
  } = props;
  const renderLi = useRenderLi(props);
  const fontWeight = getTypographyFontWeight(weight);
  const style = getTypographyStyle(elementTheme);

  const fullStyle: Styles = {
    typography: {
      ...style,
      fontWeight: !!fontWeight ? fontWeight : style.fontWeight,
      color,
      width: fullWidth ? '100%' : undefined,
      textAlign,
      lineHeight: noLineHeight ? 1 : style.lineHeight,
    },
  };
  if (!children?.length) return '';
  return parseHtml ? (
    <Html
      resetStyles
      renderers={{
        ul: ({ children, element }) => (
          <View style={{ ...ListStyles.list, ...fullStyle.typography }}>
            {children}
          </View>
        ),
        li: renderLi,
      }}
      style={{
        ...fullStyle.typography,
      }}
    >
      {children}
    </Html>
  ) : (
    <Text style={{ ...fullStyle.typography }}>{children}</Text>
  );
};

export default Typography;
