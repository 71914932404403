import { AdminProfile } from '@hellodarwin/core/lib/features/entities';
import Email from '@hellodarwin/icons/dist/icons/Email';
import CardLayout from '../../../gins/sidebars/cards/card-layout';

export interface AccountManagerCardProps {
  accountManager: AdminProfile;
  onAMClick: (adminProfile: AdminProfile) => void;
}

const AccountManagerCard: React.FC<AccountManagerCardProps> = ({
  accountManager,
  onAMClick,
}) => {
  const onClick = () => onAMClick(accountManager);

  return (
    <CardLayout
      key={accountManager.admin_id}
      title={`${accountManager.first_name} ${accountManager.last_name}`}
      subtitle={accountManager.title}
      infos={[{ Icon: Email, value: accountManager.email }]}
      onClick={onClick}
    />
  );
};

export default AccountManagerCard;
