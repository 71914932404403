import React from 'react';
import { HtmlRendererProps } from 'react-pdf-html/dist/types/render';
import ListItem from '../../list/list-item';
import { TypographyProps } from '../types';

const useRenderLi =
  (props: TypographyProps) =>
  ({ children, element }: React.PropsWithChildren<HtmlRendererProps>) => {
    const textContent = React.Children.toArray(children)
      .map((child) => {
        if (typeof child === 'string' || typeof child === 'number') {
          return child.toString();
        }
        if (React.isValidElement(child) && child.props?.children) {
          return React.Children.toArray(child.props.children).join(' ');
        }
        return '';
      })
      .join(' ');
    const dataHref = element?.attrs?.['data-href'];
    const dataColor = element?.attrs?.['data-color'];

    return (
      <ListItem
        index={1}
        children={textContent}
        level={0}
        typography={{ ...props, color: dataColor }}
        href={dataHref}
      />
    );
  };

export default useRenderLi;
