import { View } from '@react-pdf/renderer';
import Group1Filled from '../components/background/group1-filled';
import List from '../components/common/list';
import { ListItemProps } from '../components/common/list/list-item';
import PageHeader from '../components/header';
import { createTemplateStyle } from '../styles/templates';
import theme from '../theme';

interface TitleWithListProps {
  label: string;
  items: ListItemProps[];
}
const TitleWithList: React.FC<TitleWithListProps> = ({ label, items }) => {
  return (
    <View>
      <Group1Filled
        width={610}
        height={558}
        color={theme.colors.beige_3}
        top={-7}
        margin={-110}
        align="right"
      />

      <PageHeader title={label} />
      <View
        style={createTemplateStyle({
          type: 'left',
          withFooter: false,
          withHeader: true,
        })}
      >
        <List items={items} />
      </View>
    </View>
  );
};

export default TitleWithList;
