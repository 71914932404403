import Div from '@hellodarwin/core/lib/components/common/div';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Select, { SelectProps } from 'antd/es/select';
import Typography from 'antd/es/typography/Typography';
import React from 'react';

interface GrantChangeDifficultyProps {
  difficulty?: string;
  grantId: string;
  handleChange: (value: string) => Promise<void>;
}

const GrantChangeDifficulty: React.FC<GrantChangeDifficultyProps> = ({
  difficulty,
  grantId,
  handleChange,
}) => {
  const { t } = useTranslations();
  const difficultyOptions: SelectProps['options'] = [
    { label: '', value: '' },
    { label: t('programs_admin|veryEasy'), value: 'very_easy' },
    { label: t('programs_admin|easy'), value: 'easy' },
    { label: t('programs_admin|medium'), value: 'medium' },
    { label: t('programs_admin|difficult'), value: 'difficult' },
    { label: t('programs_admin|veryDifficult'), value: 'very_difficult' },
  ];

  return (
    <Div flex="column" align="center" gap={8} style={{ width: 'auto' }}>
      <Div key={grantId} flex="row" align="center" gap={8} fitContent>
        <Typography>{t('programs_admin|difficulty')}: </Typography>
        <Select
          value={difficulty || undefined}
          style={{ width: 180 }}
          options={difficultyOptions}
          onChange={(value) => handleChange(value)}
        />
      </Div>
    </Div>
  );
};

export default GrantChangeDifficulty;
