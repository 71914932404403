import { Page } from '@react-pdf/renderer';
import { PdfPagesStyles } from '../../../styles/pages';
import DescriptionWithTableTemplate from '../../../templates/description-with-table';
import { GamePlanBusinessProfileProps } from '../types';

const GamePlanBusinessProfile: React.FC<GamePlanBusinessProfileProps> = ({
  label,
  company,
  columns,
}) => {
  return (
    <Page
      size="A4"
      orientation="landscape"
      style={PdfPagesStyles.pageLevel2}
      id="business-profile"
    >
      <DescriptionWithTableTemplate
        description={company.program_description}
        label={label}
        columns={columns}
      />
    </Page>
  );
};

export default GamePlanBusinessProfile;
