import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';
import GridViewFill from '@hellodarwin/icons/dist/icons/GridViewFill';
import LineViewFill from '@hellodarwin/icons/dist/icons/LineViewFill';
import { MouseEventHandler, useMemo } from 'react';
import { RowSelectorButton, RowSelectorContainer } from './styles';

interface RowSelectorProps {
  isRow: boolean;
  setIsRow: (isRow: boolean) => void;
}

interface RowSelectorItem {
  Icon: IconNode;
  isActive: boolean;
  onClick: MouseEventHandler;
}

const RowSelector: React.FC<RowSelectorProps> = ({ isRow, setIsRow }) => {
  const items: RowSelectorItem[] = useMemo(
    () => [
      { Icon: GridViewFill, isActive: !isRow, onClick: () => setIsRow(false) },
      { Icon: LineViewFill, isActive: isRow, onClick: () => setIsRow(true) },
    ],
    [isRow],
  );

  return (
    <RowSelectorContainer>
      {items.map(({ Icon, isActive, onClick }, index) => (
        <RowSelectorButton key={index} $isActive={isActive} onClick={onClick}>
          <Icon size={18} />
        </RowSelectorButton>
      ))}
    </RowSelectorContainer>
  );
};

export default RowSelector;
