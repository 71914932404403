import { Path, Svg } from '@react-pdf/renderer';
import theme from '../../../theme';
import { IconNode } from './types';

const PropulsionIcon: IconNode = ({
  width = 32,
  height = 32,
  color = theme.colors.grey_1,
}) => {
  return (
    <Svg viewBox="0 0 32 32" fill="none" width={width} height={height}>
      <Path
        d="M21.5231 19.6462C21.8434 21.0017 21.8529 22.4121 21.5507 23.7717C21.2486 25.1314 20.6426 26.4051 19.7783 27.4973C18.914 28.5895 17.8137 29.472 16.5599 30.0786C15.3061 30.6852 13.9313 31.0002 12.5385 31V23.6154M21.5231 19.6462C24.4614 17.5058 26.8516 14.7008 28.4987 11.4601C30.1458 8.21949 31.0029 4.63519 31 1.00001C27.3651 0.997381 23.7811 1.8546 20.5407 3.50165C17.3004 5.14869 14.4956 7.53886 12.3554 10.4769M21.5231 19.6462C18.8491 21.602 15.7851 22.956 12.5385 23.6154M12.5385 23.6154C12.38 23.6477 12.22 23.6785 12.06 23.7077C10.6643 22.601 9.40059 21.3373 8.29384 19.9415C8.32291 19.7812 8.35214 19.6212 8.38461 19.4615M12.3554 10.4769C10.9998 10.1563 9.5892 10.1466 8.22933 10.4487C6.86947 10.7507 5.5956 11.3566 4.50319 12.2209C3.41077 13.0853 2.52815 14.1857 1.92146 15.4396C1.31477 16.6935 0.999746 18.0685 1 19.4615H8.38461M12.3554 10.4769C10.3998 13.1505 9.04423 16.2155 8.38461 19.4615M4.94 23.1385C3.92797 23.891 3.14112 24.9061 2.66465 26.0738C2.18819 27.2415 2.04027 28.5173 2.23692 29.7631C3.48281 29.9596 4.75871 29.8114 5.92643 29.3347C7.09414 28.8579 8.10916 28.0708 8.86154 27.0585M22.9231 11.3846C22.9231 11.9967 22.6799 12.5836 22.2472 13.0164C21.8144 13.4492 21.2274 13.6923 20.6154 13.6923C20.0033 13.6923 19.4164 13.4492 18.9836 13.0164C18.5508 12.5836 18.3077 11.9967 18.3077 11.3846C18.3077 10.7726 18.5508 10.1856 18.9836 9.75283C19.4164 9.32006 20.0033 9.07693 20.6154 9.07693C21.2274 9.07693 21.8144 9.32006 22.2472 9.75283C22.6799 10.1856 22.9231 10.7726 22.9231 11.3846Z"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Svg>
  );
};

export default PropulsionIcon;
