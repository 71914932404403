import CopyOutlined from '@ant-design/icons/CopyOutlined';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { SearchResults } from '@hellodarwin/core/lib/features/entities';
import {
  getShortFormattedDate,
  getShortId,
} from '@hellodarwin/core/lib/features/helpers';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import Button from 'antd/es/button';
import List from 'antd/es/list';
import Skeleton from 'antd/es/skeleton';
import Tag from 'antd/es/tag';
import Tooltip from 'antd/es/tooltip';
import { Link } from 'react-router-dom';
import { getStatusColor } from '../../features/utils';
import '../../layout/search-bar.scss';
import { copyIntoClipboard } from '../clipboard';
import theme from '@hellodarwin/core/lib/theme';

type SearchBarRfpProps = {
  results: SearchResults;
  isSearching: boolean;
  onClose: () => void;
};

const SearchBarRfp = ({ results, isSearching, onClose }: SearchBarRfpProps) => {
  const { t } = useTranslations();
  return (
    <Div style={{ position: 'relative', marginBottom: 24 }}>
      <Div className="search-results-title">
        <Typography elementTheme="body2">{t('rfps_admin|rfp')}</Typography>
      </Div>

      <List
        grid={{ gutter: 16, column: 2 }}
        itemLayout="horizontal"
        dataSource={results?.rfp}
        renderItem={(p, index) => (
          <List.Item
            style={{
              border: `1px solid ${theme.colors.grey_1}`,
              backgroundColor: theme.colors.beige_4,
              padding: 8,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Skeleton title={false} loading={isSearching} active>
              <List.Item.Meta
                title={
                  <Link to={'/projects/' + p.rfp_id} onClick={onClose}>
                    <Typography
                      elementTheme="overline"
                      style={{ marginTop: 7 }}
                    >
                      {`${p.company_name} // ${p.company_email}`}
                    </Typography>
                  </Link>
                }
                description={
                  <Div flex="row" gap={20}>
                    {p.rfp_status && (
                      <Tag color={getStatusColor(p.rfp_status)}>
                        {p.rfp_status}
                      </Tag>
                    )}
                    <Typography elementTheme="overline">
                      {'ID: '} {getShortId(p.rfp_id)}
                    </Typography>
                    <Typography elementTheme="overline">
                      {'Created: '}
                      {getShortFormattedDate(p.rfp_created_at)}
                    </Typography>
                  </Div>
                }
              />
              <>
                <Div
                  onClick={() => window.open('/projects/' + p.rfp_id, '_blank')}
                  style={{
                    width: 60,
                    height: 20,
                    marginBottom: 7,
                  }}
                >
                  <Tooltip
                    placement="top"
                    zIndex={5000}
                    title={'Open in new page'}
                  >
                    <Button
                      style={{ width: 45, border: 0 }}
                      icon={<NewWindow width={17} height={17} />}
                    ></Button>
                  </Tooltip>
                </Div>
                <Div
                  onClick={() => copyIntoClipboard(p.rfp_id)}
                  style={{ width: 60, height: 30, margin: 0 }}
                >
                  <Tooltip
                    placement="top"
                    zIndex={5000}
                    title={'Copy ID To Clipboard'}
                  >
                    <Button
                      style={{
                        width: 45,
                        border: 0,
                      }}
                      icon={<CopyOutlined />}
                    ></Button>
                  </Tooltip>
                </Div>
              </>
            </Skeleton>
          </List.Item>
        )}
      />
    </Div>
  );
};

export default SearchBarRfp;
