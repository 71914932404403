import { CardWithIcon_IconSizes, CardWithIconSize } from '../types';

const getIconSizes = (cardSize: CardWithIconSize): CardWithIcon_IconSizes => {
  const iconContainerSize = ['xl'].includes(cardSize)
    ? 60
    : ['large'].includes(cardSize)
      ? 54
      : ['medium'].includes(cardSize)
        ? 46
        : 32;
  const iconPadding = iconContainerSize / 6;
  const iconSize = iconContainerSize - iconPadding;
  const halfIconContainerSize = iconContainerSize / 2;
  return { iconSize, iconContainerSize, halfIconContainerSize, iconPadding };
};

export default getIconSizes;
