import addParamToTranslation from '../../../utils/add-params-to-translation';
import { getStringDate } from '../../../utils/get-string-date';
import { GamePlanTranslations } from '../types';

const formatTimeline = (
  started_at: Date,
  end_at: Date,
  locale: string,
  translations: GamePlanTranslations,
) => {
  if (!!started_at && !!end_at) {
    return addParamToTranslation(translations.fromDateToDate, {
      openingDate: getStringDate(started_at, locale),
      closingDate: getStringDate(end_at, locale),
    });
  } else if (!!started_at) {
    return addParamToTranslation(translations.fromDate, {
      date: getStringDate(started_at, locale),
    });
  } else if (!!end_at) {
    return addParamToTranslation(translations.toDate, {
      date: getStringDate(end_at, locale),
    });
  } else {
    return '';
  }
};

export default formatTimeline;
