import { Document, Page } from '@react-pdf/renderer';
import React from 'react';
import { PdfProps } from './@types';
import PdfIntro from './sections/pdf-intro';
import PdfOutro from './sections/pdf-outro';
import { PdfPagesStyles } from './styles/pages';
import PdfSectionTitlePage from './templates/section-title-page';

const HelloDarwinPDF: React.FC<PdfProps> = (props) => {
  const { translations, sections, locale, title, creationDate } = props;
  return (
    <Document
      producer="helloDarwin"
      language={locale}
      title={title}
      creationDate={creationDate}
    >
      {/* Create multiple pages as horizontal slides */}
      <PdfIntro {...props} />

      {sections.map((s, i) => (
        <React.Fragment key={s.id}>
          <Page
            size="A4"
            orientation="landscape"
            style={PdfPagesStyles.titlePage}
            id={s.id}
          >
            <PdfSectionTitlePage
              title={s.label}
              translations={translations}
              sectionIndex={i + 1}
            />
          </Page>
          {s.children}
        </React.Fragment>
      ))}

      <PdfOutro {...props} />
    </Document>
  );
};

export default HelloDarwinPDF;
