import Div from '@hellodarwin/core/lib/components/common/div';
import Loading from '@hellodarwin/core/lib/components/loading';
import { GrantResult } from '@hellodarwin/core/lib/features/entities';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Select from 'antd/es/select';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  searchGrants,
  selectAllSearchedGrants,
  selectIsLoadingSoloGen,
} from '../../../features/api/slices/grants-slice';
import { useNewAdminApi } from '../../../features/api/use-admin-api';
import useDebounce from '../../../hooks/use-debounce';

interface SelectGrantProps {
  handleSubmit: (grant: GrantResult) => void;
}

const SEARCH_DELAY_MS = 500;
const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;

const SelectGrant: React.FC<SelectGrantProps> = ({ handleSubmit }) => {
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const { selectedLocale } = useLocale();
  const grants = useAppSelector(selectAllSearchedGrants);
  const isLoading = useAppSelector((state) =>
    selectIsLoadingSoloGen(state, 'search-grants'),
  );
  const { t } = useTranslations();

  const [grantOptions, setGrantOptions] = useState(
    grants?.map((grant) => ({
      key: grant.grant_id,
      value: grant.grant_id,
      label: grant.grant_display_title,
    })) || undefined,
  );

  useEffect(() => {
    if (grants) {
      if (!grants || grants.length < 1) {
        setGrantOptions([]);
        return;
      }

      setGrantOptions(
        grants.map((grant) => ({
          key: grant.grant_id,
          value: grant.grant_id,
          label: grant.grant_display_title!,
        })),
      );
    }
  }, [grants]);

  const onSearch = (query: string) => {
    if (query === '') return;
    (async () => {
      dispatch(
        searchGrants({
          api,
          page: DEFAULT_PAGE,
          limit: DEFAULT_LIMIT,
          query,
          locale: selectedLocale,
        }),
      );
    })();
  };

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const onSelectGrant = (grantId: string) => {
    const grant = grants.find((g) => g.grant_id === grantId);
    handleSubmit(grant!);
  };

  return (
    <Select
      showSearch
      options={grantOptions}
      placeholder={t('createApplication|search_grant')}
      loading={isLoading}
      filterOption={false}
      onSearch={onSearch}
      onChange={(grantId) => {
        onSelectGrant(grantId);
        setSearchTerm(grantId);
      }}
      value={searchTerm}
      notFoundContent={
        isLoading ? <Loading /> : !!!grants ? <Div>No grants found</Div> : null
      }
      size="large"
    />
  );
};

export default SelectGrant;
