import {
  ApiError,
  ApiErrorInitialState,
  Company,
  CompanyRequest,
  Provider,
  Rfp,
} from '@hellodarwin/core/lib/features/entities';
import {
  EntityState,
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app';
import { showErrorNotification } from '../../utils';
import AdminApiClient from '../admin-api-client';

const companiesAdapter = createEntityAdapter({
  selectId: (model: Company) => model.company_id,
});
const companiesRFPAdapter = createEntityAdapter({
  selectId: (model: Rfp) => model.rfp_id,
});

export interface ModalState {
  open: boolean;
  type: string;
  company_id?: string;
}

export const InitialModalState: ModalState = {
  open: false,
  type: '',
  company_id: '',
};

type StatusType = 'generic' | 'fetchRFP' | 'fetchProvider';

type Status = {
  [key in StatusType]: 'idle' | 'pending';
};

export interface CompanyState {
  status: 'idle' | 'pending';
  mappedStatus: Status;
  error: ApiError;
  companies: EntityState<Company, string>;
  activeCompanyRFP: EntityState<Rfp, string>;
  activeCompanyProvider: Provider | null;
  totalCompanies: number;
  modal: ModalState;
}

const initialState: CompanyState = {
  status: 'idle',
  mappedStatus: {
    generic: 'idle',
    fetchRFP: 'idle',
    fetchProvider: 'idle',
  },
  error: ApiErrorInitialState,
  companies: companiesAdapter.getInitialState(),
  activeCompanyRFP: companiesRFPAdapter.getInitialState(),
  activeCompanyProvider: null,
  totalCompanies: 0,
  modal: InitialModalState,
};

export const queryCompanies = createAsyncThunk<
  { results: Company[]; total: number },
  { api: AdminApiClient; page: number; limit: number; query: string },
  { rejectValue: ApiError }
>(
  'admin/queryCompanies',
  async (
    {
      api,
      page,
      limit,
      query,
    }: { api: AdminApiClient; page: number; limit: number; query: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.queryCompanies(page, limit, query);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchCompany = createAsyncThunk<
  Company,
  { api: AdminApiClient; companyId: string; locale?: string },
  { rejectValue: ApiError }
>(
  'admin/fetchCompany',
  async ({ api, companyId, locale = 'fr' }, { rejectWithValue }) => {
    try {
      return await api.fetchCompany(companyId, locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateCompany = createAsyncThunk<
  Company,
  { api: AdminApiClient; company: Company },
  { rejectValue: ApiError }
>(
  'admin/updateCompany',
  async (
    { api, company }: { api: AdminApiClient; company: Company },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateCompany(company);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createCompany = createAsyncThunk<
  Company,
  { api: AdminApiClient; company: CompanyRequest },
  { rejectValue: ApiError }
>(
  'admin/createCompany',
  async (
    { api, company }: { api: AdminApiClient; company: CompanyRequest },
    { rejectWithValue },
  ) => {
    try {
      return await api.createCompany(company);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const fetchCompanyRFP = createAsyncThunk<
  Rfp[],
  { api: AdminApiClient; company_id: string },
  { rejectValue: ApiError }
>(
  'admin/fetchCompanyRFP',
  async (
    { api, company_id }: { api: AdminApiClient; company_id: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchCompanyRFP(company_id);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const fetchCompanyProvider = createAsyncThunk<
  Provider,
  { api: AdminApiClient; company_id: string },
  { rejectValue: ApiError }
>(
  'admin/fetchCompanyProvider',
  async (
    { api, company_id }: { api: AdminApiClient; company_id: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchCompanyProvider(company_id);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const toggleCompanyModal = createAction<ModalState>(
  'admin/toggleCompanyModal',
);

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(queryCompanies.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(queryCompanies.fulfilled, (state, { payload }) => {
      if (!!payload) {
        companiesAdapter.setAll(state.companies, payload.results ?? []);
        state.totalCompanies = payload.total;
      }
      state.status = 'idle';
    });
    builder.addCase(queryCompanies.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchCompany.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchCompany.fulfilled, (state, { payload }) => {
      companiesAdapter.upsertOne(state.companies, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateCompany.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateCompany.fulfilled, (state, { payload }) => {
      if (!payload.account_manager) {
        payload.account_manager = undefined;
      }
      companiesAdapter.upsertOne(state.companies, payload);
      state.status = 'idle';
    });
    builder.addCase(updateCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(createCompany.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createCompany.fulfilled, (state, { payload }) => {
      companiesAdapter.upsertOne(state.companies, payload);
      state.status = 'idle';
    });
    builder.addCase(createCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchCompanyRFP.pending, (state) => {
      state.mappedStatus.fetchRFP = 'pending';
    });
    builder.addCase(fetchCompanyRFP.fulfilled, (state, { payload }) => {
      if (!payload?.length) return;
      companiesRFPAdapter.setAll(state.activeCompanyRFP, payload);
      state.mappedStatus.fetchRFP = 'idle';
    });
    builder.addCase(fetchCompanyRFP.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.mappedStatus.fetchRFP = 'idle';
    });
    builder.addCase(fetchCompanyProvider.pending, (state) => {
      state.mappedStatus.fetchProvider = 'pending';
    });
    builder.addCase(fetchCompanyProvider.fulfilled, (state, { payload }) => {
      state.activeCompanyProvider = payload;
      state.mappedStatus.fetchProvider = 'idle';
    });
    builder.addCase(fetchCompanyProvider.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.mappedStatus.fetchProvider = 'idle';
    });
    builder.addCase(toggleCompanyModal, (state, { payload }) => {
      state.modal = payload;
    });
  },
});

export const { selectAll: selectAllCompanies, selectById: selectCompanyById } =
  companiesAdapter.getSelectors(
    (state: RootState) => state.companies.companies,
  );
export const {
  selectAll: selectAllCompanyRFP,
  selectById: selectCompanyRFPByID,
} = companiesRFPAdapter.getSelectors(
  (state: RootState) => state.companies.activeCompanyRFP,
);

export const selectError = (state: RootState) =>
  state.companies.error.error_code;

export const selectCompaniesIsLoading = createSelector(
  [
    (state: RootState, _?: StatusType) => state.companies.mappedStatus,
    (state: RootState, _?: StatusType) => state.companies.status,
    (_, type?: StatusType) => type,
  ],
  (mappedStatus, status, type) => {
    if (!type) {
      return status === 'pending';
    } else {
      return mappedStatus[type] === 'pending';
    }
  },
);

export const selectCompanyProvider = (state: RootState) =>
  state.companies.activeCompanyProvider;
export const selectCompaniesTotal = (state: RootState) =>
  state.companies.totalCompanies;
export const selectCompanyModal = (state: RootState) => state.companies.modal;

export const companiesReducer = companiesSlice.reducer;
