import { View } from '@react-pdf/renderer';
import Group3Outlined from '../components/background/group3-outlined';
import PageHeader from '../components/header';
import { createTemplateStyle } from '../styles/templates';
import theme from '../theme';
import PdfTable from '../components/common/table';
import { PdfTableColumnProps } from '../components/common/table/column';
import Typography from '../components/common/typography';

interface DescriptionWithTableTemplateProps {
  label: string;
  description: string;
  columns: PdfTableColumnProps[];
}
const DescriptionWithTableTemplate: React.FC<
  DescriptionWithTableTemplateProps
> = ({ label, description, columns }) => {
  return (
    <View>
      <Group3Outlined
        width={648}
        height={738}
        color={theme.colors.beige_3}
        top={-82}
        margin={-205}
        align="left"
      />

      <PageHeader title={label} />
      <View
        style={createTemplateStyle({
          type: 'space-between',
          withFooter: false,
          withHeader: true,
        })}
      >
        <Typography elementTheme="body1" parseHtml>
          {description}
        </Typography>

        <PdfTable columns={columns} />
      </View>
    </View>
  );
};

export default DescriptionWithTableTemplate;
