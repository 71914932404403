const formatDollarValue = (
  x: number | undefined,
  lang: string | undefined,
  withDollarSign?: boolean,
  useAbbreviations?: boolean,
): string => {
  if (x === undefined || x === null) return '';
  if (!lang) lang = 'en';

  const abbreviations = [
    { value: 1e9, suffix: lang === 'fr' ? 'Md' : 'B' }, // Billion
    { value: 1e6, suffix: lang === 'fr' ? 'M' : 'M' }, // Million
    { value: 1e3, suffix: lang === 'fr' ? 'k' : 'K' }, // Thousand
  ];

  let formattedValue = x;
  let suffix = '';

  if (useAbbreviations) {
    for (const { value, suffix: abbr } of abbreviations) {
      if (x >= value) {
        formattedValue = x / value;
        suffix = abbr;
        break;
      }
    }
  }

  const formattedString =
    lang === 'fr'
      ? formattedValue
          .toFixed(0)
          .replace('.', ',')
          .replace(/\B(?=(\d{3})+(?!\d))/g, String.fromCharCode(160))
      : formattedValue.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (withDollarSign) {
    return `${lang === 'en' ? '$ ' : ''}${formattedString}${suffix}${lang === 'fr' ? ' $' : ''}`;
  }

  return formattedString + suffix;
};

export default formatDollarValue;
