import { Font, StyleSheet } from '@react-pdf/renderer';
import theme from '../../../theme';

const FigtreeMedium = require('../../../../assets/fonts/Figtree/Figtree-Medium.ttf');
const FigtreeBold = require('../../../../assets/fonts/Figtree/Figtree-Bold.ttf');
const FigtreeSemiBold = require('../../../../assets/fonts/Figtree/Figtree-SemiBold.ttf');

const InterRegular = require('../../../../assets/fonts/Inter/Inter-Regular.ttf');
const InterMedium = require('../../../../assets/fonts/Inter/Inter-Medium.ttf');
const InterSemiBold = require('../../../../assets/fonts/Inter/Inter-SemiBold.ttf');

Font.register({
  family: 'Inter',
  fonts: [
    {
      src: InterRegular,
      fontWeight: theme.fontWeights.regular,
    },
    {
      src: InterMedium,
      fontWeight: theme.fontWeights.medium,
    },
    {
      src: InterSemiBold,
      fontWeight: theme.fontWeights.semibold,
    },
  ],
});

Font.register({
  family: 'Figtree',
  fonts: [
    {
      src: FigtreeMedium,
      fontWeight: theme.fontWeights.medium,
    },
    {
      src: FigtreeSemiBold,
      fontWeight: theme.fontWeights.semibold,
    },
    {
      src: FigtreeBold,
      fontWeight: theme.fontWeights.bold,
    },
  ],
});

Font.registerHyphenationCallback((word) => {
  return [word];
});

export const TypographyStyles = StyleSheet.create({
  h1: {
    fontSize: theme.fonts.h1,
    fontWeight: theme.fontWeights.bold,
    lineHeight: theme.fontsLineHeights.h1,
    fontFamily: 'Figtree',
    marginBottom: 24,
  },
  h2: {
    fontSize: theme.fonts.h2,
    fontWeight: theme.fontWeights.semibold,
    lineHeight: theme.fontsLineHeights.h2,
    fontFamily: 'Figtree',
    marginVertical: 2,
  },
  h3: {
    fontSize: theme.fonts.h3,
    fontWeight: theme.fontWeights.semibold,
    lineHeight: theme.fontsLineHeights.h3,
    fontFamily: 'Figtree',
    marginVertical: 2,
  },
  h4: {
    fontSize: theme.fonts.h4,
    fontWeight: theme.fontWeights.semibold,
    lineHeight: theme.fontsLineHeights.h4,
    fontFamily: 'Figtree',
    marginVertical: 2,
  },
  h5: {
    fontSize: theme.fonts.h5,
    fontWeight: theme.fontWeights.semibold,
    lineHeight: theme.fontsLineHeights.h5,
    fontFamily: 'Figtree',
    marginVertical: 2,
  },
  h6: {
    fontSize: theme.fonts.h6,
    fontWeight: theme.fontWeights.semibold,
    lineHeight: theme.fontsLineHeights.h6,
    fontFamily: 'Figtree',
    marginVertical: 2,
  },
  subtitle1: {
    fontSize: theme.fonts.subtitle1,
    fontWeight: theme.fontWeights.semibold,
    lineHeight: theme.fontsLineHeights.subtitle1,
    fontFamily: 'Inter',
  },
  subtitle2: {
    fontSize: theme.fonts.subtitle2,
    fontWeight: theme.fontWeights.semibold,
    lineHeight: theme.fontsLineHeights.subtitle2,
    fontFamily: 'Inter',
  },
  body1: {
    fontSize: theme.fonts.body1,
    fontWeight: theme.fontWeights.regular,
    lineHeight: theme.fontsLineHeights.body1,
    fontFamily: 'Inter',
  },
  body2: {
    fontSize: theme.fonts.body2,
    fontWeight: theme.fontWeights.regular,
    lineHeight: theme.fontsLineHeights.body2,
    fontFamily: 'Inter',
  },
  body3: {
    fontSize: theme.fonts.body3,
    fontWeight: theme.fontWeights.regular,
    lineHeight: theme.fontsLineHeights.body3,
    fontFamily: 'Inter',
  },
  body4: {
    fontSize: theme.fonts.body4,
    fontWeight: theme.fontWeights.regular,
    lineHeight: theme.fontsLineHeights.body4,
    fontFamily: 'Inter',
  },
  body3Bold: {
    fontSize: theme.fonts.body3,
    fontWeight: theme.fontWeights.bold,
    lineHeight: theme.fontsLineHeights.body3,
    fontFamily: 'Inter',
  },
  overline: {
    fontSize: theme.fonts.overline,
    fontWeight: theme.fontWeights.medium,
    letterSpacing: '0.33px',
    textTransform: 'uppercase',
    lineHeight: theme.fontsLineHeights.overline,
    fontFamily: 'Inter',
  },
});
