import { View } from '@react-pdf/renderer';
import theme from '../../theme';

export interface PriorityBoxProps {
  index: number;
  isActive?: boolean;
}

export interface PriorityIndexTheme {
  text: string;
  bg: string;
  border: string;
}
const getPriorityIndexTheme = ({
  index,
  isActive,
}: PriorityBoxProps): PriorityIndexTheme => {
  if (!isActive) {
    return {
      text: theme.colors.grey_2,
      border: `2px solid ${theme.colors.grey_3}`,
      bg: theme.colors.grey_5,
    };
  }
  switch (index) {
    case 5:
      return {
        text: theme.colors.purple_1,
        border: `2px solid ${theme.colors.grey_1}`,
        bg: theme.colors.purple_5,
      };
    case 4:
      return {
        text: theme.colors.purple_1,
        border: `2px solid ${theme.colors.grey_1}`,
        bg: theme.colors.purple_4,
      };

    case 3:
      return {
        text: theme.colors.white_1,
        border: `2px solid ${theme.colors.grey_1}`,
        bg: theme.colors.purple_3,
      };
    case 2:
      return {
        text: theme.colors.white_1,
        border: `2px solid ${theme.colors.grey_1}`,
        bg: theme.colors.purple_2,
      };
    case 1:
      return {
        text: theme.colors.white_1,
        border: `2px solid ${theme.colors.grey_1}`,
        bg: theme.colors.purple_1,
      };
  }
};
const PriorityBox: React.FC<PriorityBoxProps> = (props) => {
  const priorityTheme = getPriorityIndexTheme(props);
  return (
    <View
      style={{
        width: 24,
        height: 24,
        backgroundColor: priorityTheme.bg,
        border: priorityTheme.border,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
      }}
    />
  );
};

export default PriorityBox;
