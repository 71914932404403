import {
  attributesToProps,
  domToReact,
  HTMLReactParserOptions,
} from '../../../features/helpers/parse';
import styled from '../../../plugins/styled';
import Div from '../../common/div';
import Typography from '../../common/typography';

export const GrantSingleContainer = styled(Div)``;

export const grantSingleParseOptions: HTMLReactParserOptions = {
  replace: (domNode: any) => {
    const props = !!domNode.attribs ? attributesToProps(domNode.attribs) : {};
    switch (domNode.name) {
      case 'a':
        return (
          <a {...props} rel="nofollow" target="_blank">
            {domToReact(domNode.children, grantSingleParseOptions)}
          </a>
        );
      case 'p':
        return (
          <Typography>
            {domToReact(domNode.children, grantSingleParseOptions)}
          </Typography>
        );
      case 'li':
        return (
          <li {...props}>
            {domToReact(domNode.children, grantSingleParseOptions)}
          </li>
        );

      case 'ul':
        return <ul>{domToReact(domNode.children, grantSingleParseOptions)}</ul>;
      case 'h1':
        return (
          <Typography.Title level={1} elementTheme="h6">
            {domToReact(domNode.children, grantSingleParseOptions)}
          </Typography.Title>
        );
      case 'h2':
        return (
          <Typography.Title level={2} elementTheme="h6">
            {domToReact(domNode.children, grantSingleParseOptions)}
          </Typography.Title>
        );
      case 'h3':
        return (
          <Typography.Title level={3} elementTheme="h6">
            {domToReact(domNode.children, grantSingleParseOptions)}
          </Typography.Title>
        );
      case 'h4':
        return (
          <Typography.Title level={4} elementTheme="subtitle1">
            {domToReact(domNode.children, grantSingleParseOptions)}
          </Typography.Title>
        );
      case 'h5':
        return (
          <Typography.Title level={5} elementTheme="subtitle2">
            {domToReact(domNode.children, grantSingleParseOptions)}
          </Typography.Title>
        );
      case 'h6':
        return (
          <Typography.Title level={6} elementTheme="body2" medium>
            {domToReact(domNode.children, grantSingleParseOptions)}
          </Typography.Title>
        );
    }
  },
};
