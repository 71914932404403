import Div from '@hellodarwin/core/lib/components/common/div';
import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import { ProgramGrantStatus } from '@hellodarwin/core/lib/features/entities';
import { getProgramGrantStatusColor } from '@hellodarwin/core/lib/features/helpers/get-status-color';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import { Checkbox, Col, Input, Modal, Row, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  fetchGinApplications,
  selectSelectedGinApplications,
} from '../../../features/api/slices/gins-slice';
import { useNewAdminApi } from '../../../features/api/use-admin-api';
import { ProgramCard } from './cards/application-card';

interface ProgramsSearchModalProps {
  isVisible: boolean;
  handleClose: () => void;
  grantId: string;
}

const ProgramsSearchModal = ({
  isVisible,
  handleClose,
  grantId,
}: ProgramsSearchModalProps) => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  const dispatch = useAppDispatch();
  const api = useNewAdminApi();
  const theme = useTheme();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const ginPrograms = useAppSelector(selectSelectedGinApplications);

  useEffect(() => {
    dispatch(fetchGinApplications({ api, grantId }));
  }, [api, dispatch, grantId]);

  const statusOptions = useMemo(() => {
    if (!ginPrograms) return [];
    const statuses = new Set(ginPrograms.map((p) => p.program_grant_status));
    return Array.from(statuses).map((status) => ({
      label: status,
      value: status,
    }));
  }, [ginPrograms]);

  const filteredPrograms = useMemo(() => {
    if (!ginPrograms) return [];
    const term = searchTerm.toLowerCase();
    return ginPrograms.filter((program) => {
      const matchesSearch =
        !searchTerm ||
        (program.program_company_name &&
          program.program_company_name.toLowerCase().includes(term)) ||
        (program.program_contact_name &&
          program.program_contact_name.toLowerCase().includes(term));
      const matchesStatus =
        selectedStatuses.length === 0 ||
        (program.program_grant_status &&
          selectedStatuses.includes(program.program_grant_status));
      return matchesSearch && matchesStatus;
    });
  }, [ginPrograms, searchTerm, selectedStatuses]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const filterOption = (input: string, option: any) =>
    option?.label?.toLowerCase().includes(input.toLowerCase());

  const dropdownStyle: React.CSSProperties = {
    padding: 0,
    borderRadius: 0,
    border: `1px solid ${theme.colors.primary}`,
  };

  const tagRender = ({ value }: { value: ProgramGrantStatus | undefined }) => {
    value = value ?? ProgramGrantStatus.NotSubmited;
    return (
      <HdTag
        color={getProgramGrantStatusColor(value, theme)}
        withBorder
        text={t(`programs_labels|${value}`)}
        size="small"
        style={{ margin: '8px 0 8px 4px' }}
      />
    );
  };

  return (
    <Modal
      title={t('gin_program|applications_search')}
      open={isVisible}
      onCancel={handleClose}
      width={1000}
      footer={null}
    >
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={10}>
          <Select
            mode="multiple"
            placeholder={t('gin_program|status_placeholder')}
            value={selectedStatuses}
            onChange={(selected) => setSelectedStatuses(selected)}
            style={{ width: '100%' }}
            filterOption={filterOption}
            tagRender={tagRender}
            dropdownStyle={dropdownStyle}
            suffixIcon={<TabArrow size={10} />}
            menuItemSelectedIcon={null}
          >
            {statusOptions.map((option) => (
              <Select.Option
                key={option.value}
                value={option.value}
                label={t(`programs_labels|${option.value}`)}
                style={{ backgroundColor: 'transparent' }}
              >
                <Div
                  flex="row"
                  gap={8}
                  align="center"
                  justify="space-between"
                  style={{ background: 'transparent' }}
                >
                  {tagRender({ value: option.value })}
                  <Checkbox
                    type="square"
                    checked={
                      option.value
                        ? selectedStatuses.includes(option.value)
                        : false
                    }
                  />
                </Div>
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={14}>
          <Input.Search
            placeholder={t('gin_program|search_applications')}
            allowClear
            onSearch={handleSearch}
            onChange={(e) => handleSearch(e.target.value)}
            style={{ borderRadius: 0 }}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        {filteredPrograms.map((program) => (
          <Col key={program.program_id} span={6}>
            <ProgramCard program={program} selectedLocale={selectedLocale} />
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

export default ProgramsSearchModal;
