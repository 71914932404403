import styled from '@hellodarwin/core/lib/plugins/styled';

export const MissingDataContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.grey_1};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
  padding: 32px 54px;
  max-width: 760px;
  margin: auto;
`;
