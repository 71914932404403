import LoadingScreen from '@hellodarwin/core/lib/components/loading/screen';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type UpdateSettingFunc = <K extends keyof AdminSettings>(
  key: K,
  value: AdminSettings[K],
) => void;
export type GetSettingFunc = <K extends keyof AdminSettings>(
  key: K,
) => AdminSettings[K];

export interface AdminSettingContextType {
  getSetting: GetSettingFunc;
  updateSetting: UpdateSettingFunc;
}

export interface SidebarsOpen {
  left: boolean;
  right: boolean;
}
export const InitialSidbarsOpenState: SidebarsOpen = {
  right: false,
  left: false,
};

export interface AdminSettings {
  searchSections: string[];
  companySidebarsCollapsed: SidebarsOpen;
  applicationSidebarsCollapsed: SidebarsOpen;
  ginSidebarsCollapsed: SidebarsOpen;
  navOpen: boolean;
}

export const InitialAdminSettings: AdminSettings = {
  searchSections: [
    'grants',
    'companies',
    'providers',
    'contacts',
    'projects',
    'grantProviders',
  ],
  companySidebarsCollapsed: InitialSidbarsOpenState,
  applicationSidebarsCollapsed: InitialSidbarsOpenState,
  ginSidebarsCollapsed: InitialSidbarsOpenState,
  navOpen: true,
};

export const AdminSettingContext = React.createContext<AdminSettingContextType>(
  {
    getSetting: <K extends keyof AdminSettings>() =>
      undefined as unknown as AdminSettings[K],
    updateSetting: () => {},
  },
);

interface AdminSettingsProviderProps extends PropsWithChildren {}

export const AdminSettingsProvider = ({
  children,
}: AdminSettingsProviderProps) => {
  const [settings, setSettings] = useState<AdminSettings>(InitialAdminSettings);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const newSettings = Object.keys(InitialAdminSettings).reduce((o, key) => {
      const storedValue = localStorage.getItem(key);
      return {
        ...o,
        [key]: storedValue
          ? JSON.parse(storedValue)
          : InitialAdminSettings[key as keyof AdminSettings], // Parse string back to object
      };
    }, {} as AdminSettings);

    setSettings(newSettings);
    setIsReady(true);
  }, []);

  const updateSetting: UpdateSettingFunc = (key, value) => {
    setSettings((prev) => ({
      ...prev,
      [key]: value,
    }));
    localStorage.setItem(key, JSON.stringify(value));
  };

  const getSetting: GetSettingFunc = useCallback(
    (key) => {
      return settings[key];
    },
    [settings],
  );

  const contextValue: AdminSettingContextType = useMemo(
    () => ({
      getSetting,
      updateSetting,
    }),
    [getSetting],
  );

  if (!isReady) return <LoadingScreen />;
  return (
    <AdminSettingContext.Provider value={contextValue}>
      {children}
    </AdminSettingContext.Provider>
  );
};

export const useAdminSettings = () => useContext(AdminSettingContext);

export default AdminSettingsProvider;
