import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import { useEffect } from 'react';
import { GinBlockComponentProps } from '../../../gins/gin-single/gin-block';

const CompanyGamePlansBlock: React.FC<GinBlockComponentProps> = ({
  isEditing,
  handleEditing,
  entityId,
  section,
}) => {
  const theme = useTheme();
  useEffect(() => {
    if (!!isEditing) {
      window.open(`/companies/${entityId}/game-plan`);
      handleEditing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, entityId]);

  return (
    <Div
      flex="column"
      align="center"
      gap={8}
      style={{ maxWidth: 600, margin: 'auto' }}
    >
      <div style={{ fontSize: 60 }}>🚧</div>
      <Typography
        color={theme.colors.grey_1}
        textAlign="center"
        elementTheme="subtitle1"
      >
        Cette section est en cours de construction.
      </Typography>
      <Typography color={theme.colors.grey_1} textAlign="center">
        Les{' '}
        <b style={{ color: theme.colors.purple_1 }}>plans de match générés</b>{' '}
        seront bientôt disponibles !
      </Typography>
    </Div>
  );
};

export default CompanyGamePlansBlock;
