import { Path, Svg } from '@react-pdf/renderer';
import theme from '../../../theme';
import { IconNode } from './types';

const TimelineIcon: IconNode = ({
  width = 32,
  height = 32,
  color = theme.colors.grey_1,
}) => {
  return (
    <Svg viewBox="0 0 32 32" fill="none" width={width} height={height}>
      <Path
        d="M6.83333 1V4.5M23.1667 1V4.5M1 25.5V8C1 7.07174 1.36875 6.1815 2.02513 5.52513C2.6815 4.86875 3.57174 4.5 4.5 4.5H25.5C26.4283 4.5 27.3185 4.86875 27.9749 5.52513C28.6313 6.1815 29 7.07174 29 8V25.5M1 25.5C1 26.4283 1.36875 27.3185 2.02513 27.9749C2.6815 28.6313 3.57174 29 4.5 29H25.5C26.4283 29 27.3185 28.6313 27.9749 27.9749C28.6313 27.3185 29 26.4283 29 25.5M1 25.5V13.8333C1 12.9051 1.36875 12.0148 2.02513 11.3585C2.6815 10.7021 3.57174 10.3333 4.5 10.3333H25.5C26.4283 10.3333 27.3185 10.7021 27.9749 11.3585C28.6313 12.0148 29 12.9051 29 13.8333V25.5M15 16.1667H15.0124V16.1791H15V16.1667ZM15 19.6667H15.0124V19.6791H15V19.6667ZM15 23.1667H15.0124V23.1791H15V23.1667ZM11.5 19.6667H11.5124V19.6791H11.5V19.6667ZM11.5 23.1667H11.5124V23.1791H11.5V23.1667ZM8 19.6667H8.01244V19.6791H8V19.6667ZM8 23.1667H8.01244V23.1791H8V23.1667ZM18.5 16.1667H18.5124V16.1791H18.5V16.1667ZM18.5 19.6667H18.5124V19.6791H18.5V19.6667ZM18.5 23.1667H18.5124V23.1791H18.5V23.1667ZM22 16.1667H22.0124V16.1791H22V16.1667ZM22 19.6667H22.0124V19.6791H22V19.6667Z"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Svg>
  );
};

export default TimelineIcon;
