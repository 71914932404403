import Edit from '@hellodarwin/icons/dist/icons/Edit';
import { AdminProjectResponse } from '../../../features/entities/projects-entities';
import parse, { domToReact } from '../../../features/helpers/parse';
import { useTheme } from '../../../plugins/styled';
import Button from '../../common/button';
import Div from '../../common/div';
import Typography from '../../common/typography';
import { ProjectsListStateValues } from '../hooks/use-projects-list-state';

interface CompanyMainProjectTableHeaderProps {
  project: AdminProjectResponse;
  projectsListState: ProjectsListStateValues;
}

const CompanyMainProjectTableHeader: React.FC<
  CompanyMainProjectTableHeaderProps
> = ({ project, projectsListState: { handleEditProject, editState } }) => {
  const theme = useTheme();
  const { title, description } = project;
  const handleEdit = () => {
    handleEditProject(project);
  };

  return (
    <Div flex="row" gap={54} justify="space-between">
      <Div flex="column" gap={8} style={{ maxWidth: theme.caps.big }}>
        <Typography elementTheme="subtitle1">{title}</Typography>
        <Typography elementTheme="body2">
          {parse(description ?? '', {
            replace(domNode: any) {
              if (domNode.attribs && domNode.name === 'p') {
                return <>{domToReact(domNode.children)}</>;
              }
            },
          })}
        </Typography>
      </Div>
      {editState === 'editable' && (
        <Button
          defaultStyle={theme.colors.grey_3}
          transparent
          size="square"
          headingIcon={<Edit size={20} />}
          onClick={handleEdit}
        />
      )}
    </Div>
  );
};

export default CompanyMainProjectTableHeader;
