import {
  ApiError,
  ApiErrorInitialState,
  Gin,
} from '@hellodarwin/core/lib/features/entities';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app';
import AdminApiClient from '../admin-api-client';

const ginSectionsAdapter = createEntityAdapter({
  selectId: (model: Gin) => model.section,
});

const grantContentSectionsAdapterEn = createEntityAdapter({
  selectId: (model: { section: string; content: string }) => model.section,
});
const grantContentSectionsAdapterFr = createEntityAdapter({
  selectId: (model: { section: string; content: string }) => model.section,
});

export interface GinSectionsState {
  status: 'idle' | 'pending';
  error: ApiError;
  ginSections: EntityState<Gin, string>;
  grantContentSectionsEn: EntityState<
    { section: string; content: string },
    string
  >;
  grantContentSectionsFr: EntityState<
    { section: string; content: string },
    string
  >;
}

const initialState: GinSectionsState = {
  status: 'idle',
  error: ApiErrorInitialState,
  ginSections: ginSectionsAdapter.getInitialState(),
  grantContentSectionsEn: grantContentSectionsAdapterEn.getInitialState(),
  grantContentSectionsFr: grantContentSectionsAdapterFr.getInitialState(),
};

export const fetchSection = createAsyncThunk<
  Gin,
  { api: AdminApiClient; grantId: string; section: string },
  { rejectValue: ApiError }
>(
  'ginSections/fetchSection',
  async (
    {
      api,
      grantId,
      section,
    }: { api: AdminApiClient; grantId: string; section: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.getGinSection(grantId, section);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchGrantContentSection = createAsyncThunk<
  { section: string; content: string; locale: string },
  { api: AdminApiClient; grantId: string; section: string; locale: string },
  { rejectValue: ApiError }
>(
  'ginSections/fetchGrantContentSection',
  async ({ api, grantId, section, locale }, { rejectWithValue }) => {
    try {
      const response = await api.getGrantContentSection(
        grantId,
        section,
        locale,
      );
      return { section, content: response.content, locale: response.locale };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const saveSection = createAsyncThunk<
  Gin,
  { api: AdminApiClient; grantId: string; section: string; content: string },
  { rejectValue: ApiError }
>(
  'ginSections/saveSection',
  async (
    {
      api,
      grantId,
      section,
      content,
    }: {
      api: AdminApiClient;
      grantId: string;
      section: string;
      content: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.createGinSection(grantId, section, content);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const saveGrantContentSection = createAsyncThunk<
  { section: string; content: string; locale: string },
  {
    api: AdminApiClient;
    grantId: string;
    section: string;
    content: string;
    locale: string;
  },
  { rejectValue: ApiError }
>(
  'ginSections/saveGrantContentSection',
  async (
    {
      api,
      grantId,
      section,
      content,
      locale,
    }: {
      api: AdminApiClient;
      grantId: string;
      section: string;
      content: string;
      locale: string;
    },
    { rejectWithValue },
  ) => {
    try {
      await api.updateGinGrantContentSection(grantId, section, content, locale);
      return { section, content, locale };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateGinWithHistory = createAsyncThunk<
  Gin,
  { api: AdminApiClient; ginId: string },
  { rejectValue: ApiError }
>(
  'ginSections/updateGinWithHistory',
  async (
    { api, ginId }: { api: AdminApiClient; ginId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateGinHistory(ginId);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

const ginSectionsSlice = createSlice({
  name: 'ginSections',
  initialState,
  reducers: {
    clearSection: (state, action: PayloadAction<string>) => {
      ginSectionsAdapter.removeOne(state.ginSections, action.payload);
      state.status = 'pending';
    },
    clearGrantContentSection: (state, action: PayloadAction<string>) => {
      grantContentSectionsAdapterEn.removeOne(
        state.grantContentSectionsEn,
        action.payload,
      );
      grantContentSectionsAdapterFr.removeOne(
        state.grantContentSectionsEn,
        action.payload,
      );
      state.status = 'pending';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSection.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchSection.fulfilled, (state, action) => {
        state.status = 'idle';
        if (!!action.payload) {
          ginSectionsAdapter.upsertOne(state.ginSections, action.payload);
        }
      })
      .addCase(fetchSection.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload as ApiError;
      })
      .addCase(fetchGrantContentSection.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchGrantContentSection.fulfilled, (state, action) => {
        state.status = 'idle';
        if (!!action.payload) {
          if (action.payload.locale === 'en') {
            grantContentSectionsAdapterEn.upsertOne(
              state.grantContentSectionsEn,
              action.payload,
            );
          } else {
            grantContentSectionsAdapterFr.upsertOne(
              state.grantContentSectionsFr,
              action.payload,
            );
          }
        }
      })
      .addCase(fetchGrantContentSection.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload as ApiError;
      })
      .addCase(saveSection.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(saveSection.fulfilled, (state, action) => {
        state.status = 'idle';
        ginSectionsAdapter.upsertOne(state.ginSections, action.payload);
      })
      .addCase(saveSection.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload as ApiError;
      })
      .addCase(saveGrantContentSection.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(saveGrantContentSection.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload.locale === 'en') {
          grantContentSectionsAdapterEn.upsertOne(
            state.grantContentSectionsEn,
            action.payload,
          );
        } else {
          grantContentSectionsAdapterFr.upsertOne(
            state.grantContentSectionsFr,
            action.payload,
          );
        }
      })
      .addCase(saveGrantContentSection.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload as ApiError;
      })
      .addCase(updateGinWithHistory.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(updateGinWithHistory.fulfilled, (state, action) => {
        state.status = 'idle';
        ginSectionsAdapter.updateOne(state.ginSections, {
          id: action.payload.gin_id,
          changes: action.payload,
        });
      })
      .addCase(updateGinWithHistory.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload as ApiError;
      });
  },
});

export const {
  selectAll: selectAllGinSections,
  selectById: selectGinSectionById,
} = ginSectionsAdapter.getSelectors(
  (state: RootState) => state.ginSections.ginSections,
);

export const {
  selectAll: selectAllGrantContentSectionsEn,
  selectById: selectGrantContentSectionByIdEn,
} = grantContentSectionsAdapterEn.getSelectors(
  (state: RootState) => state.ginSections.grantContentSectionsEn,
);

export const {
  selectAll: selectAllGrantContentSectionsFr,
  selectById: selectGrantContentSectionByIdFr,
} = grantContentSectionsAdapterFr.getSelectors(
  (state: RootState) => state.ginSections.grantContentSectionsFr,
);

export const selectContent = createSelector(
  [
    (state: RootState, _: string) => state.ginSections.ginSections,
    (_: RootState, sectionId: string) => sectionId,
  ],
  (sections, sectionId) => {
    const section = sections.entities[sectionId];
    return section ? section.content : '';
  },
);

export const selectGrantContentEn = createSelector(
  [
    (state: RootState, _: string) => state.ginSections.grantContentSectionsEn,
    (_: RootState, sectionId: string) => sectionId,
  ],
  (sections, sectionId) => {
    const section = sections.entities[sectionId];
    return section ? section.content : '';
  },
);

export const selectGrantContentFr = createSelector(
  [
    (state: RootState, _: string) => state.ginSections.grantContentSectionsFr,
    (_: RootState, sectionId: string) => sectionId,
  ],
  (sections, sectionId) => {
    const section = sections.entities[sectionId];
    return section ? section.content : '';
  },
);

export const { clearSection, clearGrantContentSection } =
  ginSectionsSlice.actions;

export const ginSectionsReducer = ginSectionsSlice.reducer;
