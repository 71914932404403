import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Program } from '@hellodarwin/core/lib/features/entities/programs-entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Empty from 'antd/es/empty';
import Select from 'antd/es/select';
import { useEffect, useMemo, useState } from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../app/app-hooks';
import {
  getCompanyPrograms,
  selectProgramsByCompanyId,
  selectProgramsLoading,
} from '../../../../../../../features/api/slices/programs-slice';
import { useAdminApi } from '../../../../../../../features/api/use-admin-api';
import { ProjectApplicationFormProps } from '../../../types';

const ProjectSearchExistingApplication: React.FC<
  ProjectApplicationFormProps
> = ({
  handleSaveApplication,
  companyId,
  handleCancel,
  projectApplicationsIds,
  projectId,
}) => {
  const { t } = useTranslations();
  const theme = useTheme();

  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector((state) =>
    selectProgramsLoading(state, 'fetchCompanyPrograms'),
  );
  const applications = useAppSelector((state) =>
    selectProgramsByCompanyId(state, companyId),
  );

  const filteredApplications = useMemo(
    () =>
      applications.filter(
        (a) => !projectApplicationsIds.includes(a.program_id),
      ),
    [applications, projectApplicationsIds],
  );

  const [activeApplication, setActiveApplication] = useState<
    Program | undefined
  >(undefined);

  useEffect(() => {
    if (!!companyId && companyId !== '') {
      dispatch(getCompanyPrograms({ api, companyId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const onSave = () => {
    if (!!activeApplication?.program_id) {
      if (handleSaveApplication) {
        handleSaveApplication(activeApplication);
      }
      handleCancel();
    }
  };

  const handleSelect = (application: Program) => {
    if (typeof application.program_grant_id === 'string')
      setActiveApplication(application);
  };

  return (
    <Div flex="column" gap={12} style={{ overflow: 'hidden' }}>
      <Typography elementTheme="h5">
        {t(`project|searchApplicationTitle`)}
      </Typography>

      {!filteredApplications.length ? (
        <Empty description={t('project|noExistingProjectApplication')} />
      ) : (
        <>
          <Select
            placeholder={t(`project|searchApplicationsPlaceholder`)}
            loading={isLoading}
            options={filteredApplications}
            size="large"
            onSelect={(_, application) => handleSelect(application)}
            fieldNames={{ label: 'program_name', value: 'program_id' }}
          />
          <Button
            onClick={onSave}
            defaultStyle={theme.colors.purple_2}
            style={{ alignSelf: 'flex-end' }}
          >
            {t('button|assign')}
          </Button>
        </>
      )}
    </Div>
  );
};

export default ProjectSearchExistingApplication;
