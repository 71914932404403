import { View } from '@react-pdf/renderer';
import Typography from '../common/typography';
import PdfHdLogo from '../logo';
import { HeaderStyles } from './styles';
import { PageHeaderProps } from './types';

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  textAlign = 'left',
  elementTheme = 'h4',
}) => {
  return (
    <View
      style={{
        ...HeaderStyles.container,
        height: ['h6'].includes(elementTheme)
          ? '8vh'
          : ['h5'].includes(elementTheme)
            ? '9vh'
            : HeaderStyles.container.height,
      }}
    >
      <Typography
        elementTheme={elementTheme}
        textAlign={textAlign}
        noLineHeight
      >
        {title}
      </Typography>
      <PdfHdLogo backgroundType="light" />
    </View>
  );
};

export default PageHeader;
