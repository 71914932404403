import Div from '@hellodarwin/core/lib/components/common/div';
import { GrantTimeline } from '@hellodarwin/core/lib/features/entities';
import { getStringDate } from '@hellodarwin/core/lib/features/helpers';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Form from 'antd/es/form';
import { useState } from 'react';
import ClosedAtCheckbox from './closed-at-checkbox';

type GrantClosedAtSelectionProps = {
  timelines: GrantTimeline[];
  handleSelectOption: (date: string | undefined) => void;
  handleManualSelectDate: (date: Date) => void;
};

export const NO_CLOSING_DATE = 'no_closing_date';
export const MANUAL_CLOSING_DATE = 'manual_closing_date';

const GrantClosedAtSelection = ({
  timelines,
  handleSelectOption,
  handleManualSelectDate,
}: GrantClosedAtSelectionProps) => {
  const { t } = useTranslations();
  const [selectedTimelineId, setSelectedTimelineId] = useState<string>();
  const { selectedLocale } = useLocale();
  const handleChange = (timelineId: string) => {
    setSelectedTimelineId(timelineId);
    handleSelectOption(timelineId);
  };
  const handleDateChanged = (date: Date) => {
    handleManualSelectDate(date);
  };

  return (
    <Form>
      <Div flex="column" gap={4}>
        {timelines.map((timeline) => (
          <ClosedAtCheckbox
            timelineId={timeline.grant_timeline_id!}
            label={getStringDate(timeline.closed_at!, selectedLocale)}
            isSelected={selectedTimelineId === timeline.grant_timeline_id}
            hasCalendarSelect={false}
            handleChange={handleChange}
          />
        ))}
        <ClosedAtCheckbox
          timelineId={MANUAL_CLOSING_DATE}
          label={t('createApplication|select_closing_date')}
          isSelected={selectedTimelineId === MANUAL_CLOSING_DATE}
          hasCalendarSelect={true}
          handleChange={handleChange}
          handleDateChanged={handleDateChanged}
        />
        <ClosedAtCheckbox
          timelineId={NO_CLOSING_DATE}
          label={t('createApplication|no_closing_date')}
          isSelected={selectedTimelineId === NO_CLOSING_DATE}
          hasCalendarSelect={false}
          handleChange={handleChange}
        />
      </Div>
    </Form>
  );
};

export default GrantClosedAtSelection;
