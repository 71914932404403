import { CompanyGamePlan } from '@hellodarwin/core/lib/features/entities';
import {
  ApiError,
  ApiErrorInitialState,
} from '@hellodarwin/core/lib/features/entities/api-entitites';
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app/app-store';
import AdminApi from '../admin-api';
import SliceRequest from '../slice-request';

type StatusType = 'generic' | 'generateGamePlan' | 'fetchCompanyGamePlan';
type Status = {
  [key in StatusType]: 'idle' | 'pending';
};

const companiesGamePlansAdapter = createEntityAdapter({
  selectId: (model: CompanyGamePlan) => model.company_id,
});

export interface CompaniesGamePlansState {
  status: Status;
  error: ApiError;
  companiesGamePlans: EntityState<CompanyGamePlan, string>;
}

const initialState: CompaniesGamePlansState = {
  status: {
    generic: 'idle',
    generateGamePlan: 'idle',
    fetchCompanyGamePlan: 'idle',
  },
  error: ApiErrorInitialState,
  companiesGamePlans: companiesGamePlansAdapter.getInitialState(),
};

export const generateGamePlan = SliceRequest<
  CompanyGamePlan,
  { api: AdminApi; formData: FormData }
>('uploadAsset', async ({ api, formData }) => {
  const response = await api.post<CompanyGamePlan>(
    `/gameplan`,
    formData,
    undefined,
    'multipart/form-data',
  );
  return response.data;
});

const companiesGamePlansSlice = createSlice({
  name: 'companies-game-plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(generateGamePlan.pending, (state) => {
      state.status.generateGamePlan = 'pending';
    });
    builder.addCase(generateGamePlan.fulfilled, (state, { payload }) => {
      companiesGamePlansAdapter.upsertOne(state.companiesGamePlans, payload);
      state.status.generateGamePlan = 'idle';
    });
    builder.addCase(generateGamePlan.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;

      state.status.generateGamePlan = 'idle';
    });
  },
});

export const selectGamePlansIsLoading = createSelector(
  [
    (state: RootState, _?: StatusType) => state.gamePlans.status,
    (_, type?: StatusType) => type,
  ],
  (status, type) => {
    if (!type) {
      return !!Object.keys(status).find((state) => state === 'pending');
    } else {
      return status[type] === 'pending';
    }
  },
);

export const companiesGamePlansReducer = companiesGamePlansSlice.reducer;
