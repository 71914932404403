import { WebsiteGrantResult } from '../../../../../features/entities/grants-entities';
import parse from '../../../../../features/helpers/parse';
import Div from '../../../../common/div';
import { grantSingleParseOptions } from '../../styles';

const GrantRegister = ({ steps_how_to_apply }: WebsiteGrantResult) => {
  if (!steps_how_to_apply) return <></>;
  return <Div>{parse(steps_how_to_apply, grantSingleParseOptions)}</Div>;
};

export default GrantRegister;
