import Div from '@hellodarwin/core/lib/components/common/div';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Select, { SelectProps } from 'antd/es/select';
import Typography from 'antd/es/typography/Typography';
import React from 'react';

interface GrantChangeCompletionTimeEstimateProps {
  cte?: string;
  grantId: string;
  handleChange: (value: string) => Promise<void>;
}

const GrantChangeCompletionTimeEstimate: React.FC<
  GrantChangeCompletionTimeEstimateProps
> = ({ cte, grantId, handleChange }) => {
  const { t } = useTranslations();
  const cteOptions: SelectProps['options'] = [
    { label: '', value: '' },
    { label: t('programs_admin|lessThanTwoHours'), value: '-2h' },
    { label: t('programs_admin|betweenTwoAndFiveHours'), value: '2h-5h' },
    { label: t('programs_admin|betweenFiveAndSevenHours'), value: '5h-7h' },
    { label: t('programs_admin|betweenSevenAndTwelveHours'), value: '7h-12h' },
    { label: t('programs_admin|moreThanTwelveHours'), value: '12h+' },
  ];

  return (
    <Div flex="column" align="center" gap={8} style={{ width: 'auto' }}>
      <Div key={grantId} flex="row" align="center" gap={8} fitContent>
        <Typography>{t('programs_admin|completionTimeEstimate')}: </Typography>
        <Select
          value={cte || undefined}
          style={{ width: 180 }}
          options={cteOptions}
          onChange={(value) => handleChange(value)}
        />
      </Div>
    </Div>
  );
};

export default GrantChangeCompletionTimeEstimate;
