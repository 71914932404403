import { WebsiteGrantResult } from '../../../../../features/entities/grants-entities';
import parse from '../../../../../features/helpers/parse';
import Div from '../../../../common/div';
import { grantSingleParseOptions } from '../../styles';

const GrantTermsAndConditions = ({
  funding_terms_and_conditions,
}: WebsiteGrantResult) => {
  if (!funding_terms_and_conditions) return <></>;
  return (
    <Div>{parse(funding_terms_and_conditions, grantSingleParseOptions)}</Div>
  );
};

export default GrantTermsAndConditions;
