import SingleLanguage from '../enums/single-language';

export interface Contact {
  contact_id: string;
  company_id: string;
  name: string;
  email?: string;
  title?: string;
  phone?: string;
  ext?: string;
  preferred_language?: SingleLanguage;
  gender?: string;
  roles?: string;
  comments?: string;
  auth_id?: string;
  created_at?: string;
  last_login_at?: string;
  updated_at?: string;
  auth_id_match?: boolean;
  hubspot_url?: string;
  crm_id?: string;
}

export const ContactInitialState: Contact = {
  contact_id: '',
  company_id: '',
  name: '',
};

export interface Client {
  company_id: string;
  name: string;
  website: string;
  size: string;
  contacts: Contact[];
  city?: string;
}
