import { ReactNode } from 'react';
import { Contact } from './contact-entities';
import { ProviderSearchResult } from './providers-entities';

export * from './companies-entities';
export * from './contact-entities';
export * from './provider-portfolios-entities';
export * from './providers-entities';

declare global {
  interface Window {
    _hsq: any;
  }
}

export type AppType = 'client' | 'admin' | 'provider' | 'website';

export type Localisation = {
  lat: number;
  lng: number;
};

export const InitialLocalisation: Localisation = {
  lat: 0,
  lng: 0,
};

export interface AdminOauthOptions {
  domain: string;
  admin_client_id: string;
  audience: string;
}

export interface PlanPrice {
  interval: string;
  interval_count: number;
  price: number;
  billing_price_id: string;
}

export interface Plan {
  name: string;
  billing_product_id: string;
  prices: PlanPrice[];
}

export interface BillingSession {
  url: string;
}

export interface Subscription {
  billing_subscription_id: string;
  status: string;
  plan: PlanPrice;
}

export interface RfpSearchResult {
  rfp_id: string;
  title: string;
  rfp_original_description: string;
  rfp_status: string;
  budget_min: number;
  budget_max: number;
  rfp_created_at: string;
  company_id: string;
  company_name: string;
  company_email: string;
  rfp_lead_price: number;
  rfp_estimated_value: number;
  rfp_territory: string;
  rfp_category: string;
  discounted_amount: number;
  is_premium: boolean;
}

export interface ContactSearchResult {
  contact_id: string;
  contact_name: string;
  contact_email: string;
}

export interface CompanySearchResult {
  company_id: string;
  company_name: string;
  company_email: string;
}

export interface GrantSearchResult {
  grant_id: string;
  grant_title: string;
  grant_display_title: string;
}

export interface GrantProviderSearchResult {
  grant_provider_id: string;
  title: string;
  acronym: string;
}

export interface SearchResults {
  providers: ProviderSearchResult[];
  rfp: RfpSearchResult[];
  contacts: Contact[];
  companies: CompanySearchResult[];
  grants: GrantSearchResult[];
  grantProviders: GrantProviderSearchResult[];
}

export interface Admin {
  id: string;
  name: string;
  email: string;
  picture: string;
  phone: string;
  meeting_link_fr: string;
  meeting_link_en: string;
}

export type Invoice = {
  HostedURL: string;
};

export interface ReferralCreditParams {
  price: number;
}

export interface CreateAuth0UserResponse {
  auth_id: string;
  reset_link: string;
}

export interface FindAuth0IDResponse {
  auth_id: string;
  auth_id_match: boolean;
}

export type NextMeeting = {
  Uri: string;
  Name: string;
  StartTime: string;
  EndTime: string;
  EventType: string;
  Location: Location | undefined;
  InviteesCounter: InviteesCounter;
  CreatedAt: string;
  UpdatedAt: string;
  EventGuests: EventGuest[];
};

export type EventGuest = {
  Email: String;
  CreatedAt: string;
  UpdatedAt: string;
};
export type InviteesCounter = {
  Total: number;
  Active: number;
  Limit: number;
};

export interface QA {
  question: string;
  answer: string | ReactNode;
  videos?: {
    label: string;
    name: string;
    url: string;
  }[];
  images?: {
    url: string;
  }[];
}

export type FormAddressInformations = {
  city: string;
  country: string;
  province: string;
  postal_code: string;
  address: string;
};
export type AddressInformations = {
  administrative_area_level_1: string;
  administrative_area_level_2: string;
  country: string;
  locality: string;
  neighborhood: string;
  postal_code: string;
  route: string;
  street_number: string;
  subpremise: string;
};

export const AddressInformationsInitialState: AddressInformations = {
  street_number: '',
  subpremise: '',
  route: '',
  administrative_area_level_1: '',
  administrative_area_level_2: '',
  postal_code: '',
  country: '',
  locality: '',
  neighborhood: '',
};

export enum AppName {
  Admin = 'Admin',
  Partner = 'Partner',
  Client = 'Client',
  Website = 'Website',
}

export interface HdUser {
  logo?: string;
  name: string;
}

export interface TeamMemberItem {
  provider_team_member_id?: string;
  order_team?: number;
}
