import React, { ReactNode, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ErrorNotFound from '../../components/error/notfound';
import { MappedAppPages } from '../entities/pages-entities';

const renderRoutes = (pages: MappedAppPages): ReactNode => {
  return React.Children.toArray(
    Object.entries(pages).map(([path, page]) => (
      <>
        <Route
          key={path}
          path={page.pathname}
          element={<Suspense fallback="">{page.component}</Suspense>}
        />
        {!!page.children ? renderRoutes(page.children) : undefined}
      </>
    )),
  );
};

interface AppRoutesProps {
  defaultHomepage?: string;
  pages: MappedAppPages;
}

const AppRoutesRender: React.FC<AppRoutesProps> = ({
  defaultHomepage,
  pages,
}) => {
  return (
    <Routes>
      {!!defaultHomepage && (
        <Route path="/" element={<Navigate to={defaultHomepage} />} />
      )}

      {renderRoutes(pages)}

      <Route path="*" element={<ErrorNotFound />} />
    </Routes>
  );
};

export default AppRoutesRender;
