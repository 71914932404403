import { View } from '@react-pdf/renderer';
import { PdfProps } from '../@types';
import Group3Outlined from '../components/background/group3-outlined';
import List from '../components/common/list';
import Typography from '../components/common/typography';
import PdfHdLogo from '../components/logo';
import { PdfLayoutStyles } from '../styles/layout';
import { createTemplateStyle } from '../styles/templates';
import theme from '../theme';

const PdfOverviewPage: React.FC<PdfProps> = ({
  translations,
  sections,
  warning,
}) => {
  return (
    <View>
      <Group3Outlined
        width={648}
        height={738}
        color={theme.colors.beige_3}
        top={-82}
        margin={-205}
        align="left"
      />

      <View
        style={{
          ...createTemplateStyle({
            withFooter: false,
            withHeader: false,
            type: 'center',
          }),
          paddingTop: 54,
        }}
      >
        <PdfHdLogo backgroundType="light" />
        <Typography
          color={theme.colors.purple_1}
          elementTheme="body1"
          noLineHeight
        >
          {translations.introduction}
        </Typography>
        <Typography elementTheme="h3">{translations.menuTitle}</Typography>
        <View style={PdfLayoutStyles.menu}>
          <List
            items={sections.map((s) => ({
              level: 0,
              children: s.label,
              typography: {
                elementTheme: 'body1',
                color: theme.colors.purple_2,
              },
              href: `#${s.id}`,
            }))}
            ordered
          />
        </View>
        {!!warning && (
          <View style={PdfLayoutStyles.warning}>
            <Typography color={theme.colors.purple_1} elementTheme="overline">
              {warning.label}
            </Typography>
            <View style={PdfLayoutStyles.warningBody}>
              <List
                items={warning.children.map((label) => ({
                  level: 0,
                  typography: { elementTheme: 'body3' },
                  children: label,
                }))}
              />
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

export default PdfOverviewPage;
