import { Page } from '@react-pdf/renderer';
import { PdfProps } from '../@types';
import { PdfPagesStyles } from '../styles/pages';
import PdfOverviewPage from '../templates/overview-page';
import PdfTitlePage from '../templates/title-page';

const PdfIntro: React.FC<PdfProps> = (props) => {
  return (
    <>
      <Page
        size="A4"
        orientation="landscape"
        style={PdfPagesStyles.titlePage}
        id="title-page"
      >
        <PdfTitlePage {...props} />
      </Page>

      <Page
        size="A4"
        orientation="landscape"
        style={PdfPagesStyles.pageLevel1}
        id="overview"
      >
        <PdfOverviewPage {...props} />
      </Page>
    </>
  );
};

export default PdfIntro;
