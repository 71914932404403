import { StyleSheet } from '@react-pdf/renderer';
import theme from '../theme';

export const PdfLayoutStyles = StyleSheet.create({
  header: {
    height: '11vh',
    paddingHorizontal: '4vw',
    paddingTop: '4vh',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 10,
  },
  footer: {
    height: '20vh',
    paddingHorizontal: '4vw',
    paddingVertical: '4vh',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: 2,
  },
  warning: {
    display: 'flex',
    padding: 8,
    flexDirection: 'column',
    gap: 8,
    backgroundColor: theme.colors.white_2,
    border: `1px solid ${theme.colors.grey_1}`,
    textAlign: 'left',
  },
  warningBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },

  menu: {
    maxWidth: 340,
    gap: 6,
    display: 'flex',
    flexDirection: 'column',
    marginVertical: 24,
  },
});
