const addParamToTranslation = (
  str: string,
  params: { [key: string]: string | number },
) => {
  if (!params || Object.keys(params)?.length === 0) return str;

  const regex = new RegExp(`{{(${Object.keys(params).join('|')})}}`, 'g');
  return str.replace(regex, (_, key) => `${params[key]}` || '');
};

export default addParamToTranslation;
