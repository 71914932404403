import { Link } from '@react-pdf/renderer';
import { PropsWithChildren } from 'react';

export interface ListItemLinkWrapperProps extends PropsWithChildren {
  href?: string;
}

const ListItemLinkWrapper: React.FC<ListItemLinkWrapperProps> = ({
  children,
  href,
}) => {
  if (!!href?.length)
    return (
      <Link
        style={{
          textDecoration: 'none',
          width: '100%',
        }}
        href={href}
      >
        {children}
      </Link>
    );
  return children;
};

export default ListItemLinkWrapper;
