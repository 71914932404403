import { WebsiteGrantResult } from '../../../../../features/entities/grants-entities';
import parse from '../../../../../features/helpers/parse';
import Div from '../../../../common/div';
import { grantSingleParseOptions } from '../../styles';

const GrantOtherInfos = ({ additional_information }: WebsiteGrantResult) => {
  if (!additional_information) return <></>;
  return <Div>{parse(additional_information, grantSingleParseOptions)}</Div>;
};

export default GrantOtherInfos;
