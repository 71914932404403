import { WebsiteGrantResult } from '../../../../../features/entities/grants-entities';
import parse from '../../../../../features/helpers/parse';
import { grantSingleParseOptions } from '../../styles';

const GrantCriteria = ({ selection_criteria }: WebsiteGrantResult) => {
  if (!selection_criteria) return <></>;
  return <div>{parse(selection_criteria, grantSingleParseOptions)}</div>;
};

export default GrantCriteria;
