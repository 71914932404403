import {
  ApiError,
  ApiErrorInitialState,
  Program,
  ProgramInitialState,
} from '@hellodarwin/core/lib/features/entities';
import {
  EntityState,
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app';
import { showErrorNotification } from '../../utils';
import AdminApiClient from '../admin-api-client';

const programsAdapter = createEntityAdapter({
  selectId: (model: Program) => model.program_id,
});

type StatusType = 'generic' | 'fetchCompanyPrograms';

type Status = {
  [key in StatusType]: 'idle' | 'pending';
};

export interface ProgramsState {
  status: 'idle' | 'pending';
  mappedStatus: Status;
  error: ApiError;
  programs: EntityState<Program, string>;
  selectedProgram: Program;
  activeTab: string;
  modal: {
    isVisible: boolean;
    type: string;
  };
}

const initialState: ProgramsState = {
  status: 'idle',
  mappedStatus: {
    generic: 'idle',
    fetchCompanyPrograms: 'idle',
  },
  error: ApiErrorInitialState,
  programs: programsAdapter.getInitialState(),
  selectedProgram: ProgramInitialState,
  activeTab: '',
  modal: {
    isVisible: false,
    type: '',
  },
};

export const queryPrograms = createAsyncThunk<
  Program[],
  { api: AdminApiClient; page: number; limit: number },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/queryPrograms',
  async (
    { api, page, limit }: { api: AdminApiClient; page: number; limit: number },
    { rejectWithValue },
  ) => {
    try {
      return await api.queryPrograms(page, limit);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { programs } = getState();
      if (programs.status === 'pending') return false;
    },
  },
);

export const getCompanyPrograms = createAsyncThunk<
  Program[],
  { api: AdminApiClient; companyId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/getCompanyPrograms',
  async (
    { api, companyId }: { api: AdminApiClient; companyId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.getCompanyPrograms(companyId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { programs } = getState();
      if (programs.status === 'pending') return false;
    },
  },
);

export const fetchProgramById = createAsyncThunk<
  Program,
  { api: AdminApiClient; programId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/fetchProgramById',
  async (
    { api, programId }: { api: AdminApiClient; programId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchProgramById(programId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { programs } = getState();
      if (programs.status === 'pending') return false;
    },
  },
);

//
export const createProgram = createAsyncThunk<
  Program,
  { api: AdminApiClient; createdProgram: Program; timelineId?: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/createProgram',
  async (
    {
      api,
      createdProgram,
      timelineId,
    }: { api: AdminApiClient; createdProgram: Program; timelineId?: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.createProgram(createdProgram, timelineId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { programs } = getState();
      if (programs.status === 'pending') return false;
    },
  },
);
export const updateProgram = createAsyncThunk<
  Program,
  { api: AdminApiClient; updatedProgram: Program },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/updateProgram',
  async (
    { api, updatedProgram }: { api: AdminApiClient; updatedProgram: Program },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateProgram(updatedProgram);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { programs } = getState();
      if (programs.status === 'pending') return false;
    },
  },
);

export const createHubspotDeal = createAsyncThunk<
  Program,
  { api: AdminApiClient; program: Program },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/createHubspotDeal',
  async (
    { api, program }: { api: AdminApiClient; program: Program },
    { rejectWithValue },
  ) => {
    try {
      return await api.createHubspotDeal(program);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateHubspotDeal = createAsyncThunk<
  Program,
  { api: AdminApiClient; program: Program },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/updateHubspotDeal',
  async (
    { api, program }: { api: AdminApiClient; program: Program },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateHubspotDeal(program);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const setSelectedProgram = createAction<Program>(
  'admin/setSelectedProgram',
);
export const toggleProgramsModal = createAction<{
  isVisible: boolean;
  type: string;
}>('admin/toggleProgramsModal');

export const setActiveTab = createAction<string>('admin/setActiveTab');
export const selectActiveTab = (state: RootState) => state.programs.activeTab;

const programsSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(queryPrograms.fulfilled, (state, { payload }) => {
      if (payload != null) {
        programsAdapter.setAll(state.programs, payload);
      }
      state.status = 'idle';
    });
    builder.addCase(queryPrograms.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(queryPrograms.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getCompanyPrograms.fulfilled, (state, { payload }) => {
      if (payload != null) {
        programsAdapter.setAll(state.programs, payload);
      }
      state.mappedStatus.fetchCompanyPrograms = 'idle';
    });
    builder.addCase(getCompanyPrograms.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.mappedStatus.fetchCompanyPrograms = 'idle';
    });
    builder.addCase(getCompanyPrograms.pending, (state) => {
      state.mappedStatus.fetchCompanyPrograms = 'pending';
    });
    builder.addCase(fetchProgramById.fulfilled, (state, { payload }) => {
      programsAdapter.upsertOne(state.programs, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchProgramById.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchProgramById.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createProgram.fulfilled, (state, { payload }) => {
      programsAdapter.setOne(state.programs, payload);
      state.status = 'idle';
    });
    builder.addCase(createProgram.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(createProgram.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateProgram.fulfilled, (state, { payload }) => {
      programsAdapter.updateOne(state.programs, {
        id: payload.program_id,
        changes: payload,
      });
      state.status = 'idle';
    });
    builder.addCase(updateProgram.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateProgram.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(setSelectedProgram, (state, { payload }) => {
      state.selectedProgram = payload;
    });
    builder.addCase(toggleProgramsModal, (state, { payload }) => {
      state.modal = payload;
    });
    builder.addCase(createHubspotDeal.fulfilled, (state, { payload }) => {
      programsAdapter.updateOne(state.programs, {
        id: payload.program_id,
        changes: payload,
      });
      state.status = 'idle';
    });
    builder.addCase(createHubspotDeal.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(createHubspotDeal.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateHubspotDeal.fulfilled, (state, { payload }) => {
      programsAdapter.updateOne(state.programs, {
        id: payload.program_id,
        changes: payload,
      });
      state.status = 'idle';
    });
    builder.addCase(updateHubspotDeal.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateHubspotDeal.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(setActiveTab, (state, { payload }) => {
      state.activeTab = payload;
    });
  },
});

export const { selectAll: selectPrograms, selectById: selectProgramsId } =
  programsAdapter.getSelectors((state: RootState) => state.programs.programs);

// Custom selector to get programs by company_id
export const selectProgramsByCompanyId = createSelector(
  [
    (state: RootState, _) => selectPrograms(state),
    (_, companyId: string) => companyId,
  ],
  (programs, companyId) =>
    programs.filter((program) => program.program_company_id === companyId),
);

export const selectProgramsByGrantAndCompanyIds = createSelector(
  [
    (state: RootState, _: string, __: string) => selectPrograms(state),
    (_, companyId: string, __: string) => companyId,
    (_, __: string, grantId: string) => grantId,
  ],
  (programs, companyId, grantId) => {
    return programs.filter(
      (program) =>
        program.program_company_id === companyId &&
        program.program_grant_id === grantId,
    );
  },
);

export const selectProgramsLoading = createSelector(
  [
    (state: RootState, _?: StatusType) => state.programs.mappedStatus,
    (state: RootState, _?: StatusType) => state.programs.status,
    (_, type?: StatusType) => type,
  ],
  (mappedStatus, status, type) => {
    if (!type) {
      return status === 'pending';
    } else {
      return mappedStatus[type] === 'pending';
    }
  },
);

export const selectProgramsModal = (state: RootState) => state.programs.modal;
export const selectSelectedProgram = (state: RootState) =>
  state.programs.selectedProgram;

export const programsReducer = programsSlice.reducer;
