import Container from '@hellodarwin/core/lib/components/common/container';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../app';
import AdminFilesList from '../components/files/files-list';
import { selectFolderById } from '../features/api/slices/assets-slice';
import { selectAuthentifiedUser } from '../features/api/slices/global-slice';

const AdminFileVault = () => {
  const { t } = useTranslation();
  const { folderId } = useParams<{ folderId: string }>();
  const folder = useAppSelector((state) =>
    selectFolderById(state, folderId ?? ''),
  );

  const admin = useAppSelector(selectAuthentifiedUser);

  return (
    <PageLayout
      app="admin"
      title={folder?.record_name ?? t('navigation.fileVault')}
      breadcrumbs={
        folder.record_id
          ? [
              {
                breadcrumbName: t('navigation.home'),
                path: '/',
              },
              {
                breadcrumbName: t('navigation.fileVault'),
                path: '/files-vault',
              },
              {
                breadcrumbName: folder.record_name,
              },
            ]
          : [
              {
                breadcrumbName: t('navigation.home'),
                path: '/',
              },
              {
                breadcrumbName: t('navigation.fileVault'),
              },
            ]
      }
    >
      <Container>
        <AdminFilesList
          folderId={folderId}
          owner={{ owner_id: admin.admin_id, owner_type: 'admin' }}
          vaultPath={'/files-vault'}
        />
      </Container>
    </PageLayout>
  );
};

export default AdminFileVault;
