import { Company, Contact } from './core-entities';

export enum ProgramStatus {
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  Active = 'Active',
  Completed = 'Completed',
}
export enum MilestoneStatus {
  ToSchedule = 'ToSchedule',
  Scheduled = 'Scheduled',
  Canceled = 'Canceled',
  Completed = 'Completed',
}
export enum MilestoneTypes {
  Internal = 'Internal',
  Meeting = 'Meeting',
}

export interface MilestoneAttachment {
  milestone_attachment_id: string;
  milestone_attachment_milestone_id: string;
  milestone_attachment_created_at?: string;
  milestone_attachment_updated_at?: string;
  milestone_attachment_deleted_at?: string;
  milestone_attachment_display_name?: string;
  milestone_attachment_description?: string;
  milestone_attachment_url: string;
  milestone_attachment_hs_id: string;
  milestone_attachment_filename: string;
  milestone_attachment_extension: string;
}

export interface Milestone {
  milestone_id: string;
  milestone_program_id?: string;
  milestone_status?: MilestoneStatus;
  milestone_type?: MilestoneTypes;
  milestone_scheduled_at?: string;
  milestone_title?: string;
  milestone_description?: string;
  milestone_recap?: string;
  milestone_dued_at?: string;
  milestone_created_at?: string;
  milestone_updated_at?: string;
  milestone_completed_at?: string;
  milestone_caller?: string;
  milestone_hs_id?: string;
}

export interface Program {
  program_id: string;
  program_name?: string;
  program_status?: ProgramStatus;
  program_created_at?: string;
  program_activated_at?: string;
  program_canceled_at?: string;
  program_rejected_at?: string;
  program_completed_at?: string;
  program_deleted_at?: string;
  program_updated_at?: string;
  program_canceled_reason?: string;
  program_canceled_reason_specified?: string;
  program_reject_reason?: string;
  program_reject_reason_specified?: string;
  program_contact_id?: string;
  program_company_id?: string;
  program_price?: number;
  program_account_manager?: string;
  program_included_milestones?: number;
  program_hs_deal_id?: string;
  program_contact_name?: string;
  program_company_name?: string;
  company?: Company;
  contacts?: Contact[];
  notification_contacts?: Contact[];
  hubspot_url?: string;
  full_count?: number;
  completion_rate?: number;
  program_grant_id?: string;
  program_grant_status?: ProgramGrantStatus;
  program_requested_at?: Date;
  program_targeted_amount?: number;
  program_application_amount?: number;
  program_applied_at?: Date;
  program_application_result?: ApplicationResult;
  program_accepted_amount?: number;
  program_result_at?: Date;
  program_refusal_reason?: GrantsRefusalReason;
  program_type?: ProgramType;
  program_project_id?: string;
  program_source?: ProgramSource;
  program_closed_at?: Date;
}

export interface GinProgram {
  program_id: string;
  program_name?: string;
  program_grant_status?: ProgramGrantStatus;
  program_activated_at?: string;
  program_company_id?: string;
  program_targeted_amount?: number;
  program_company_name?: string;
  program_contact_name?: string;
  program_contact_last_login?: Date;
}

export interface GinHistory {
  grant_internal_notes_id: string;
  created_at?: Date;
  grant_id: string;
  content: string;
  admin_id?: string;
  first_name: string;
  last_name: string;
}
export interface DealHubspotResponse {
  deal_id: string;
  dealname: string;
}

export const ProgramInitialState: Program = {
  program_id: '',
};

export enum ProgramGrantStatus {
  IdentifiedOpportunities = 'identified_opportunitites',
  ConfirmedOpportunities = 'confirmed_opportunities',
  OngoingInformationRequired = 'ongoing_information_required',
  OngoingWorkByHelloDarwin = 'ongoing_work_hellodarwin',
  ValidationRequired = 'validation_required',
  CompletedToSubmit = 'completed_to_submit',
  SubmittedWaitingForResults = 'submitted_waiting_for_results',
  Accepted = 'accepted',
  Refused = 'refused',
  NotSubmited = 'not_submited',
}

export enum ApplicationResult {
  Pending = 'pending',
  Accepted = 'accepted',
  Refused = 'refused',
}

export enum GrantsRefusalReason {
  NonEligibleApplicant = 'non-eligible applicant',
  NonEligibleProject = 'non-eligible project',
  MissingDocumentation = 'missing documentation',
  AfterDeadline = 'after_deadline',
  NoReasonProvided = 'no_reason_provided',
}

export enum ProgramType {
  Consulting = 'consulting',
  GrantsRoadmap = 'grants_roadmap',
}

export enum ProgramSource {
  AM = 'AM',
  Recherchiste = 'Recherchiste',
  Client = 'Client',
}
