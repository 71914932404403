import { Page } from '@react-pdf/renderer';
import { PdfProps } from '../@types';
import { AdminProfile } from '../@types/company-types';
import { PdfPagesStyles } from '../styles/pages';
import AdminPageTemplate from '../templates/admin-page';

export interface PdfOutroProps {
  admin: AdminProfile;
  locale: string;
}

const PdfOutro: React.FC<PdfProps> = (props) => {
  return (
    <Page size="A4" orientation="landscape" style={PdfPagesStyles.pageLevel1}>
      <AdminPageTemplate {...props} />
    </Page>
  );
};

export default PdfOutro;
