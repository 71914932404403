import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import { ModalConfirm } from '@hellodarwin/core/lib/components/common/hd-modal/confirm';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import Loading from '@hellodarwin/core/lib/components/loading';
import { Company } from '@hellodarwin/core/lib/features/entities/companies-entities';
import { AdminProfile } from '@hellodarwin/core/lib/features/entities/profile-entities';
import { AdminProjectResponse } from '@hellodarwin/core/lib/features/entities/projects-entities';
import parse from '@hellodarwin/core/lib/features/helpers/parse';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import { PdfSection } from '@hellodarwin/pdf/lib/@types';
import { GamePlanTranslations } from '@hellodarwin/pdf/lib/builders/game-plan/types';
import Empty from 'antd/es/empty';
import message from 'antd/es/message';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app';
import { updateCompany } from '../../../features/api/slices/companies-slice';
import { selectAuthentifiedUser } from '../../../features/api/slices/global-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';
import { MissingDataContainer } from './styles';

export interface GamePlanMissingDataWrapperProps extends PropsWithChildren {
  admin: AdminProfile;
  sections: PdfSection[];
  translations: GamePlanTranslations;
  company: Company;
  projects: AdminProjectResponse[];
}

const GamePlanMissingDataWrapper: React.FC<GamePlanMissingDataWrapperProps> = ({
  admin,
  sections,
  translations,
  children,
  company,
  projects,
}) => {
  const theme = useTheme();
  const { translationsLoaded, t } = useTranslations();

  const profile = useAppSelector(selectAuthentifiedUser);

  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGoToCompany = () => {
    navigate(`/companies/${company.company_id}`);
  };
  const handleAccountManagerSelect = async () => {
    ModalConfirm({
      title: t('gamePlanPdf|areYouSureAssignYourselfAsManagerTitle'),

      okButtonProps: {
        style: {
          backgroundColor: theme.colors.purple_1,
          color: theme.colors.white_1,
          borderRadius: '24px',
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: theme.colors.white_1,
          borderColor: theme.colors.grey_1,
          color: theme.colors.grey_1,
          borderRadius: '24px',
        },
      },
      cancelText: t('button|cancel'),
      okText: t('button|confirm'),
      content: (
        <>
          {parse(t('gamePlanPdf|areYouSureAssignYourselfAsManagerDescription'))}
        </>
      ),
      onOk: async () => {
        try {
          const updatedCompany: Company = {
            ...company,
            account_manager: profile.admin_id,
          };

          await dispatch(updateCompany({ api, company: updatedCompany }));
          message.success(t('companyForm|saved'));
        } catch (e: any) {
          message.error(t('companyForm|messageError'));
          console.error(e);
        }
      },
      onCancel: async () => {},
    });
  };
  if (
    !translationsLoaded ||
    !sections?.length ||
    !Object.values(translations).filter((itm) => !!itm?.length).length
  )
    return <Loading />;

  if (!projects.length)
    return (
      <MissingDataContainer>
        <Empty
          description={
            <Div flex="column" gap={24} align="center">
              <Div flex="column" gap={8} align="center">
                <Typography
                  elementTheme="subtitle1"
                  color={theme.colors.grey_1}
                >
                  {t('gamePlanPdf|noGameplanProjects')}
                </Typography>
                <Typography color={theme.colors.grey_2}>
                  {t('gamePlanPdf|noGameplanProjectsDescription')}
                </Typography>
              </Div>
              <Button
                onClick={handleGoToCompany}
                defaultStyle={theme.colors.grey_1}
              >
                {t('gamePlanPdf|returnToCompany')}
              </Button>
            </Div>
          }
        />
      </MissingDataContainer>
    );
  if (!admin?.admin_id)
    return (
      <MissingDataContainer>
        <Empty
          description={
            <Div flex="column" gap={24} align="center">
              <Div flex="column" gap={8} align="center">
                <Typography
                  elementTheme="subtitle1"
                  color={theme.colors.grey_1}
                >
                  {t('gamePlanPdf|noAdminAssigned')}
                </Typography>
                <Typography color={theme.colors.grey_2}>
                  {t('gamePlanPdf|noAdminAssignedDescription')}
                </Typography>
              </Div>
              <Button
                onClick={handleAccountManagerSelect}
                defaultStyle={theme.colors.grey_1}
              >
                {t('gamePlanPdf|assignMeAsAccountManager')}
              </Button>
            </Div>
          }
        />
      </MissingDataContainer>
    );
  return children;
};

export default GamePlanMissingDataWrapper;
