import { AssetEntity } from './assets-entities';
import { Contact } from './core-entities';
import { AdminGrantResult, GrantStatus } from './grants-entities';

export interface Gin {
  gin_id: string;
  grant_id: string;
  admin_id: string;
  section: string;
  content?: string;
}

export interface GinSection {
  section: string;
  content?: string;
}

export interface AdminGinResult {
  grant: AdminGrantResult;
  ginSections: Gin[];
  templates: GinEmail[];
  snippets: GinEmail[];
}

export interface GinContact {
  gin_contact_id: string;
  created_at?: Date;
  grant_ids: string[];
  admin_id?: string;
  crm_id?: string;
  grant_provider_id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  title?: string;
  phone_number?: string;
  extension?: string;
  language?: string;
  notes?: string;
}

export interface GinInterviewGuide {
  gin_interview_guide_id: string;
  grant_id: string;
  admin_id: string;
  name: string;
  link: string;
}

export interface Step {
  step_id: string;
  grant_id: string;
  title_en?: string;
  title_fr?: string;
  external_content_en?: string;
  external_content_fr?: string;
  medium: string;
  type: string;
  description?: string;
  conditions?: string;
  step_order: number;
  owner?: string;
  assets: StepAsset[];
  validated?: boolean;
}

export interface StepAsset {
  step_asset_id: string;
  step_id: string;
  asset_type: string;
  custom_asset_type?: string;
  name_en: string;
  name_fr: string;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  example?: AssetEntity;
}

export interface GinEmail {
  id: string;
  grant_id: string;
  link: string;
  type: string;
  name?: string;
  content?: string;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

export interface HubspotTemplate {
  id: number;
  label: string;
  is_available_for_new_content: boolean;
  deleted_at: Date;
}

export const InitialStepValue: Step = {
  step_id: '',
  grant_id: '',
  title_en: '',
  title_fr: '',
  external_content_en: '',
  external_content_fr: '',
  medium: '',
  type: '',
  description: '',
  conditions: '',
  step_order: 0,
  assets: undefined,
};

export const InitialStepAssetValue: StepAsset = {
  step_asset_id: '',
  step_id: '',
  asset_type: '',
  name_en: '',
  name_fr: '',
};

export const InitialGinEmailValue: GinEmail = {
  id: '',
  grant_id: '',
  type: '',
  link: '',
  name: '',
};

export const InitialGinInterviewGuideValue: GinInterviewGuide = {
  gin_interview_guide_id: '',
  grant_id: '',
  admin_id: '',
  name: '',
  link: '',
};

export interface StepFormFunction {
  updateSelectedStep: (step: Step) => Promise<Step>;
  createNewStep: (grantId: string, step: Step) => Promise<Step>;
  deleteSelectedStep: (grant_id: string, step_id: string) => Promise<Step[]>;
  reorderSteps: (grant_id: string, steps: Step[]) => Promise<Step[]>;
  createNewAsset: (asset: StepAsset) => Promise<StepAsset>;
  updateSelectedAsset: (asset: StepAsset) => Promise<StepAsset>;
  deleteSelectedAsset: (step_asset_id: string) => Promise<string>;
}

export interface GrantProviderFormValues {
  first_name: string;
  last_name: string;
  title: string;
  email: string;
  phone_number: string;
  extension: string;
  language: string;
  notes: string;
  grant_ids: string[];
}

export interface AdminGinContactResponse extends GinContact {
  provider_title?: string;
}
export declare const GinInitialState: AdminGinResult;

export interface GinApplication {
  application_id: string;
  application_name: string;
  application_program_name: string;
  application_status: string;
  application_created_at: string;
  application_company_id: string;
  application_company_name: string;
  application_contact_id: string;
  application_contact_name: string;
  application_account_manager: string;
  application_price: number;
  application_requested_at: string;
  application_targeted_amount: number;
  application_submitted_at: string;
  application_submitted_amount: number;
  application_result_at: string;
  application_completed_at: string;
  application_rejected_at: string;
  application_accepted_amount: number;
  application_submission_result: string;
  application_refusal_reason: string;
  application_refusal_reason_specified: string;
  application_program_grant_id: string;
  application_program_status: GrantStatus;
  application_hubspot_url: string;
  application_notes: string;
  application_project_end: string;
  application_closed_at: Date;
}

export const InitialStateGinApplication: GinApplication = {
  application_id: '',
  application_name: '',
  application_program_name: '',
  application_status: '',
  application_created_at: '',
  application_company_id: '',
  application_company_name: '',
  application_contact_id: '',
  application_contact_name: '',
  application_account_manager: '',
  application_price: 0,
  application_requested_at: '',
  application_targeted_amount: 0,
  application_submitted_at: '',
  application_submitted_amount: 0,
  application_result_at: '',
  application_completed_at: '',
  application_rejected_at: '',
  application_accepted_amount: 0,
  application_submission_result: '',
  application_refusal_reason: '',
  application_refusal_reason_specified: '',
  application_program_grant_id: '',
  application_program_status: 'open',
  application_hubspot_url: '',
  application_notes: '',
  application_project_end: '',
  application_closed_at: undefined,
};

export interface GinShortApplication {
  application_id: string;
  application_name: string;
  application_status: string;
  application_company_id: string;
  application_contact_name: string;
  application_program_grant_id: string;
  application_program_name: string;
}

export const InitialStateGinShortApplication: GinShortApplication = {
  application_id: '',
  application_name: '',
  application_status: '',
  application_company_id: '',
  application_contact_name: '',
  application_program_grant_id: '',
  application_program_name: '',
};

export interface GinApplicationCompany {
  application_id: string;
  company_id: string;
  company_name: string;
  company_applications: GinShortApplication[];
}

export const InitialStateGinApplicationCompany: GinApplicationCompany = {
  application_id: '',
  company_id: '',
  company_name: '',
  company_applications: [],
};
export interface GinApplicationProgram {
  application_id: string;
  program_grant_id: string;
  program_name: string;
  program_applications: GinShortApplication[];
}

export const InitialStateGinApplicationProgram: GinApplicationProgram = {
  application_id: '',
  program_grant_id: '',
  program_name: '',
  program_applications: [],
};

export interface FullGinApplication {
  application: GinApplication;
  contacts: Contact[];
  company: GinApplicationCompany;
  program: GinApplicationProgram;
  interviewGuides: GinInterviewGuide[];
}

export const InitialStateFullGinApplication: FullGinApplication = {
  application: InitialStateGinApplication,
  contacts: [],
  company: InitialStateGinApplicationCompany,
  program: InitialStateGinApplicationProgram,
  interviewGuides: [],
};

export declare const AdminGinInitialState: AdminGinContactResponse;
