import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import Loading from '@hellodarwin/core/lib/components/loading';
import {
  getFormattedDate,
  getShortId,
} from '@hellodarwin/core/lib/features/helpers';
import { getRfpStatusColor } from '@hellodarwin/core/lib/features/helpers/get-status-color';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Tooltip from 'antd/es/tooltip';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import List from '../../components/list/list';
import {
  fetchProviderReferredRfp,
  selectProviderReferredRfp,
  selectProvidersLoading,
} from '../../features/api/slices/providers-slice';
import { useAdminApi } from '../../features/api/use-admin-api';

type ReferredRfpListProps = {
  providerId: string;
};

const ReferredRfpList = ({ providerId }: ReferredRfpListProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const rfps = useAppSelector(selectProviderReferredRfp);
  const loading = useAppSelector(selectProvidersLoading);
  const { t } = useTranslations();
  const theme = useTheme();
  useEffect(() => {
    dispatch(fetchProviderReferredRfp({ api, providerId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headers = [
    t('referred_rfps|rfp_id'),
    t('referred_rfps|category'),
    t('referred_rfps|status'),
    t('referred_rfps|estimatedValue'),
    t('referred_rfps|leadPrice'),
    t('referred_rfps|receivedCredits'),
    t('referred_rfps|receivedCreditsOn'),
  ];

  const getRows = () => {
    if (rfps.length === 0) {
      return [];
    }
    return rfps.map((p, index) => {
      return [
        <Tooltip placement="bottom" key={index} title="Project page">
          <Link to={'/projects/' + p.rfp_id}>{getShortId(p.rfp_id)}</Link>
        </Tooltip>,
        p.category,
        <HdTag
          text={t(`projectStatus|${p.status}`)}
          color={getRfpStatusColor(p.status, theme)}
          withBorder
        />,
        `$${p.estimated_value || 0}`,
        `$${p.lead_price || 0}`,
        p.referral_credit_amount ? `$${p.referral_credit_amount}` : 'n/a',
        p.referral_credit_applied_at
          ? `${getFormattedDate(p.referral_credit_applied_at)}`
          : 'n/a',
      ];
    });
  };

  if (loading) return <Loading />;

  return <List headers={headers} rows={getRows()} onClick={() => null} />;
};

export default ReferredRfpList;
