import { Styles, View } from '@react-pdf/renderer';
import theme from '../../../theme';
import { CardWithIconStyles } from './styles';
import { CardWithIcon_IconProps } from './types';

const CardWithIcon_Icon: React.FC<CardWithIcon_IconProps> = ({
  Icon,
  backgroundColor,
  cardDirection,
  iconSizes,
  color = theme.colors.grey_1,
}) => {
  const { halfIconContainerSize, iconSize, iconContainerSize, iconPadding } =
    iconSizes;

  const iconAlignementStyle: Styles = {
    alignement:
      cardDirection === 'horizontal'
        ? {
            left: `50%`,
            top: -halfIconContainerSize,
            transform: `translateX(${-halfIconContainerSize})`,
          }
        : {
            top: `50%`,
            left: -halfIconContainerSize,
            transform: `translateY(${-halfIconContainerSize})`,
          },
  };
  return (
    <View
      style={{
        ...iconAlignementStyle.alignement,
        ...CardWithIconStyles.iconContainer,
        backgroundColor,
        padding: iconPadding,
        width: iconContainerSize,
        height: iconContainerSize,
      }}
    >
      <Icon width={'100%'} height={'100%'} color={color} />
    </View>
  );
};

export default CardWithIcon_Icon;
