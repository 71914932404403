import { View } from '@react-pdf/renderer';
import { PdfTableColumnProps } from '.';
import Typography from '../../typography';
import { TableStyles } from '../styles';

const PdfColumnContent: React.FC<PdfTableColumnProps> = ({ content }) => {
  return (
    <View style={TableStyles.tableColumnContent}>
      <Typography elementTheme="body3">{content}</Typography>
    </View>
  );
};

export default PdfColumnContent;
