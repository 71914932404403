import { Page } from '@react-pdf/renderer';
import { Fragment } from 'react/jsx-runtime';
import { ProjectResponse } from '../../../@types/projects-types';
import { PdfPagesStyles } from '../../../styles/pages';
import TitleWithList from '../../../templates/title-with-list';
import PriorityInfos from '../templates/priority-infos';
import ProjectApplicationTemplate from '../templates/project-application';
import ProjectInfosTemplate from '../templates/project-infos';
import { GamePlanTranslations } from '../types';
import getGrantsOverview from '../utils/get-grants-overview';

export interface GamePlanProjectsAndGrantsProps {
  projects: ProjectResponse[];
  translations: GamePlanTranslations;
  locale: string;
}

const GamePlanProjectsAndGrants: React.FC<GamePlanProjectsAndGrantsProps> = ({
  projects,
  translations,
  locale,
}) => {
  const overviewItems = getGrantsOverview(projects);
  return (
    <>
      <Page size="A4" orientation="landscape" style={PdfPagesStyles.pageLevel1}>
        <TitleWithList
          items={overviewItems}
          label={translations.targetedGrants}
        />
      </Page>
      {projects?.map((priority) => (
        <Fragment key={priority.project_id}>
          <Page
            size="A4"
            orientation="landscape"
            style={PdfPagesStyles.pageLevel2}
            id={priority.project_id}
          >
            <PriorityInfos priority={priority} translations={translations} />
          </Page>
          {priority.projects?.map((project) => (
            <Fragment key={project.project_id}>
              <Page
                size="A4"
                orientation="landscape"
                style={PdfPagesStyles.pageLevel3}
              >
                <ProjectInfosTemplate
                  project={project}
                  translations={translations}
                  locale={locale}
                  priority={priority}
                />
              </Page>
              {project.grants?.map((grant) => (
                <Fragment key={grant.grant_id}>
                  {grant.programs?.map((application) => (
                    <Page
                      key={application.program_id}
                      size="A4"
                      orientation="landscape"
                      style={PdfPagesStyles.pageLevel4}
                    >
                      <ProjectApplicationTemplate
                        grant={grant}
                        application={application}
                        translations={translations}
                        locale={locale}
                        project={project}
                      />
                    </Page>
                  ))}
                </Fragment>
              ))}
            </Fragment>
          ))}
        </Fragment>
      ))}
    </>
  );
};

export default GamePlanProjectsAndGrants;
