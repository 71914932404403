import { Path, Svg } from '@react-pdf/renderer';
import theme from '../../../theme';
import { IconNode } from './types';

const NewWindowIcon: IconNode = ({
  width = 32,
  height = 32,
  color = theme.colors.grey_1,
}) => {
  return (
    <Svg viewBox="0 0 32 32" fill="none" width={width} height={height}>
      <Path
        d="M16.7581 6.66667H5.49976C4.5715 6.66667 3.68126 7.03542 3.02488 7.69179C2.3685 8.34817 1.99976 9.23841 1.99976 10.1667V26.5C1.99976 27.4283 2.3685 28.3185 3.02488 28.9749C3.68126 29.6313 4.5715 30 5.49976 30H21.8331C22.7613 30 23.6516 29.6313 24.308 28.9749C24.9643 28.3185 25.3331 27.4283 25.3331 26.5V15.2417M8.99976 23L29.9998 2M29.9998 2H21.8331M29.9998 2V10.1667"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Svg>
  );
};

export default NewWindowIcon;
