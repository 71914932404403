import { BlobProvider, DocumentProps } from '@react-pdf/renderer';
import { PropsWithChildren, useCallback } from 'react';

export interface DarwinPdfUploadProps extends PropsWithChildren {
  document: React.ReactElement<DocumentProps>;
  fileName: string;
  handleUpload: (file: Blob) => Promise<boolean>;
}

const DarwinPdfUpload: React.FC<DarwinPdfUploadProps> = ({
  document,
  handleUpload,
  fileName,
  children,
}) => {
  return (
    <BlobProvider document={document}>
      {({ blob, url, loading, error }) => {
        const handleClick = useCallback(() => {
          if (!blob) return;
          const file = new File([blob], fileName + '.pdf', {
            type: 'application/pdf',
          });
          handleUpload(file);
        }, [blob, fileName, handleUpload]);

        return <div onClick={handleClick}>{children}</div>;
      }}
    </BlobProvider>
  );
};

export default DarwinPdfUpload;
