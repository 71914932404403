import { StyleSheet } from '@react-pdf/renderer';

export const PdfContentStyles = StyleSheet.create({
  slideContentDefault: {
    paddingHorizontal: '4vw',
    paddingVertical: '4vh',
  },
  slideContentWithHeader: {
    height: `${100 - 12}vh`,
    width: '100vw',
  },
  slideContentWithFooter: {
    height: `${100 - 20}vh`,
    width: '100vw',
  },
  slideContentWithHeaderFooter: {
    height: `${100 - 12 - 20}vh`,
    width: '100vw',
  },
  slideContentFull: {
    height: '100vh',
    width: '100vw',
  },
});
