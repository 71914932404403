import { View } from '@react-pdf/renderer';
import { PdfTranslations } from '../@types';
import Group4Outlined from '../components/background/group4-outlined';
import PdfHdLogo from '../components/logo';
import { PdfLayoutStyles } from '../styles/layout';
import { createTemplateStyle } from '../styles/templates';
import theme from '../theme';
import Typography from '../components/common/typography';

export interface PdfSectionTitlePageProps {
  title: string;
  translations: PdfTranslations;
  sectionIndex: number;
}

const PdfSectionTitlePage: React.FC<PdfSectionTitlePageProps> = ({
  title,
  translations,
  sectionIndex,
}) => {
  return (
    <View>
      <Group4Outlined
        width={612}
        height={855}
        color={theme.colors.purple_2}
        top={-34}
        align="right"
        margin={-115}
        rotate={-90}
      />
      <View style={PdfLayoutStyles.header}>
        <PdfHdLogo backgroundType="dark" />
      </View>
      <View
        style={createTemplateStyle({
          withFooter: true,
          withHeader: true,
          type: 'center',
        })}
      >
        <Typography
          elementTheme="overline"
          parseHtml
          color={theme.colors.white_1}
        >
          {`${translations.section} ${sectionIndex}`}
        </Typography>
        <Typography elementTheme="h3" parseHtml color={theme.colors.white_1}>
          {title}
        </Typography>
      </View>
      <View style={PdfLayoutStyles.footer}></View>
    </View>
  );
};

export default PdfSectionTitlePage;
