import { PdfSection } from '@hellodarwin/pdf/lib/@types';
import GamePlanBusinessPriorities from '@hellodarwin/pdf/lib/builders/game-plan/sections/business-priorities';
import GamePlanBusinessProfile from '@hellodarwin/pdf/lib/builders/game-plan/sections/business-profile';
import GamePlanProjectsAndGrants from '@hellodarwin/pdf/lib/builders/game-plan/sections/projects-and-grants';
import { GamePlanTranslations } from '@hellodarwin/pdf/lib/builders/game-plan/types';
import { useMemo } from 'react';
import { AdminProjectResponse, Company } from '../../../../features/entities';
import useLocale from '../../../../features/providers/locale-provider';
import { useTranslations } from '../../../../features/providers/translations-provider';

interface useGamePlanPdfProps {
  projects: AdminProjectResponse[];
  company: Company;
  translations: GamePlanTranslations;
}
const useGamePlanSections = ({
  projects,
  company,
  translations,
}: useGamePlanPdfProps): PdfSection[] => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();

  return useMemo(() => {
    const address = !!company
      ? [
          company.address,
          company.city,
          company.province,
          company.country,
          company.postal_code,
        ].filter((itm) => !!itm)
      : [];
    const sections: PdfSection[] = [
      {
        label: t('gamePlanPdf|businessProfile'),
        children: (
          <GamePlanBusinessProfile
            projects={projects}
            label={t('gamePlanPdf|businessProfile')}
            company={company}
            columns={[
              {
                label: t('gamePlanPdf|naicsCode'),
                content: company?.naics_code?.length
                  ? company.naics_code.join(', ')
                  : ' - ',
                id: 'naics',
              },
              {
                label: t('gamePlanPdf|businessNumber'),
                content: !!company?.business_number
                  ? company.business_number
                  : ' - ',
                id: 'business_number',
              },
              {
                label: t('gamePlanPdf|annualRevenue'),
                content: !!company?.annual_revenue
                  ? company.annual_revenue
                  : ' - ',
                id: 'business_number',
              },
              {
                label: t('gamePlanPdf|nbEmployees'),
                content: !!company?.size ? company.size : ' - ',
                id: 'business_number',
              },
              {
                label: t('gamePlanPdf|companyAddress'),
                content: !!address?.length ? address.join(', ') : ' - ',
                id: 'business_number',
              },
            ]}
          />
        ),
        id: 'business-profile-section',
      },
      {
        label: t('gamePlanPdf|mainBusinessPriorities'),
        children: !!projects.length && (
          <GamePlanBusinessPriorities
            label={t('gamePlanPdf|mainBusinessPriorities')}
            projects={projects}
          />
        ),
        id: 'business-priorities-section',
      },
      {
        label: t('gamePlanPdf|projectsAndGrantsSection'),
        children: !!projects.length && (
          <GamePlanProjectsAndGrants
            projects={projects}
            translations={translations}
            locale={selectedLocale}
          />
        ),
        id: 'projects-and-grants-section',
      },
    ];

    return sections;
  }, [projects, company, selectedLocale]);
};
export default useGamePlanSections;
