import { Link, View } from '@react-pdf/renderer';
import theme from '../../../theme';
import NewWindowIcon from '../../common/icons/new-window';
import Typography from '../../common/typography';
import CardWithIcon_Icon from './info-card-icon';
import { CardWithIconStyles } from './styles';
import { CardWithIconProps } from './types';
import getIconSizes from './utils/get-icon-sizes';

const CardWithIcon: React.FC<CardWithIconProps> = ({
  icon,
  size,
  direction,
  label,
  content,
  withLink,
  spacing = 16,
  bgColor = theme.colors.white_1,
  href,
}) => {
  const iconSizes = getIconSizes(size);
  return (
    <View
      style={{
        ...CardWithIconStyles.container,
        paddingTop:
          direction === 'horizontal'
            ? iconSizes.halfIconContainerSize + 8
            : undefined,
        paddingLeft:
          direction === 'vertical'
            ? iconSizes.halfIconContainerSize + 8
            : undefined,
        paddingBottom: 16,
        marginTop: withLink ? iconSizes.halfIconContainerSize + spacing : 16,
        flex: size !== 'small' ? 1 : undefined,
        width: '100%',
        height: 'auto',
        backgroundColor: bgColor,
        position: 'relative',

        textOverflow: 'ellipsis',
      }}
    >
      {!!href && (
        <View style={{ position: 'absolute', top: 16, right: 16 }}>
          <Link href={href}>
            <NewWindowIcon
              width={24}
              height={24}
              color={theme.colors.purple_2}
            />
          </Link>
        </View>
      )}
      {!!withLink && (
        <View
          style={{
            ...(direction === 'vertical'
              ? CardWithIconStyles.verticalDivider
              : CardWithIconStyles.horizontalDivider),
            top:
              direction === 'horizontal'
                ? -(iconSizes.halfIconContainerSize + spacing + 2)
                : '50%',
            left:
              direction === 'vertical'
                ? -(iconSizes.halfIconContainerSize + spacing + 2)
                : '50%',
            width: direction === 'vertical' ? spacing + 2 : 1,
            height: direction === 'horizontal' ? spacing + 2 : 1,
          }}
        />
      )}
      <CardWithIcon_Icon
        {...icon}
        iconSizes={iconSizes}
        cardDirection={direction}
      />
      <Typography
        elementTheme={
          ['small'].includes(size)
            ? 'overline'
            : ['xl'].includes(size)
              ? 'h6'
              : 'subtitle1'
        }
        fullWidth
      >
        {label}
      </Typography>

      {typeof content === 'string' ? (
        <Typography
          elementTheme={
            ['small'].includes(size)
              ? 'body4'
              : content?.length > 800 && size !== 'xl'
                ? 'body4'
                : content?.length > 700 && size !== 'xl'
                  ? 'body3'
                  : 'body2'
          }
          fullWidth
          parseHtml
        >
          {content}
        </Typography>
      ) : (
        content
      )}
    </View>
  );
};

export default CardWithIcon;
