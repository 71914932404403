import { Path, Svg } from '@react-pdf/renderer';
import { IconNode } from './types';
import theme from '../../../theme';

const GrantsIcon: IconNode = ({
  width = 32,
  height = 32,
  color = theme.colors.grey_1,
}) => {
  return (
    <Svg viewBox="0 0 32 32" fill="none" width={width} height={height}>
      <Path
        d="M16 6.66667V25.3333M11.3333 20.9498L12.7007 21.9749C14.5222 23.3422 17.4762 23.3422 19.2993 21.9749C21.1224 20.6076 21.1224 18.3924 19.2993 17.0251C18.3893 16.3407 17.1947 16 16 16C14.8722 16 13.7444 15.6578 12.8842 14.9749C11.1638 13.6076 11.1638 11.3924 12.8842 10.0251C14.6047 8.65778 17.3953 8.65778 19.1158 10.0251L19.7613 10.5384M30 16C30 17.8385 29.6379 19.659 28.9343 21.3576C28.2307 23.0561 27.1995 24.5995 25.8995 25.8995C24.5995 27.1995 23.0561 28.2307 21.3576 28.9343C19.659 29.6379 17.8385 30 16 30C14.1615 30 12.341 29.6379 10.6424 28.9343C8.94387 28.2307 7.40053 27.1995 6.1005 25.8995C4.80048 24.5995 3.76925 23.0561 3.06569 21.3576C2.36212 19.659 2 17.8385 2 16C2 12.287 3.475 8.72601 6.1005 6.10051C8.72601 3.475 12.287 2 16 2C19.713 2 23.274 3.475 25.8995 6.10051C28.525 8.72601 30 12.287 30 16Z"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Svg>
  );
};

export default GrantsIcon;
