import { View } from '@react-pdf/renderer';
import { PdfTableColumnProps } from '.';
import Typography from '../../typography';
import { TableStyles } from '../styles';
import theme from '../../../../theme';

const PdfColumnHeader: React.FC<PdfTableColumnProps> = ({ label }) => {
  return (
    <View style={TableStyles.tableColumnHeader}>
      <Typography elementTheme="overline" color={theme.colors.white_1}>
        {label}
      </Typography>
    </View>
  );
};

export default PdfColumnHeader;
