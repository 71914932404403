import { StyleSheet } from '@react-pdf/renderer';

export const HeaderStyles = StyleSheet.create({
  container: {
    height: '10vh',
    paddingHorizontal: '4vw',
    paddingTop: '2vh',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 10,
  },
});
