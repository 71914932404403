import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import Checkbox from 'antd/es/checkbox/Checkbox';
import DatePicker from 'antd/es/date-picker';

export type ClosedAtCheckboxProps = {
  timelineId: string;
  label: string;
  isSelected: boolean;
  hasCalendarSelect?: boolean;
  dateSelected?: Date;
  handleChange: (timelineId: string) => void;
  handleDateChanged?: (date: Date) => void;
};

const ClosedAtCheckbox = ({
  timelineId,
  label,
  isSelected,
  hasCalendarSelect,
  dateSelected,
  handleChange,
  handleDateChanged,
}: ClosedAtCheckboxProps) => {
  const handleCheckboxChange = () => {
    handleChange(timelineId);
  };
  return (
    <Div flex="column" gap={8}>
      <Checkbox
        checked={isSelected}
        name={label}
        onChange={handleCheckboxChange}
        style={{ alignItems: 'center', gap: 8 }}
      >
        <Typography elementTheme="body2" medium>
          {label}
        </Typography>
      </Checkbox>
      {isSelected && hasCalendarSelect && (
        <div style={{ paddingLeft: `calc(16px + 16px)` }}>
          <DatePicker
            size="large"
            picker="date"
            allowClear
            value={dateSelected}
            onChange={(date) => {
              if (!!handleDateChanged) handleDateChanged(date);
            }}
          />
        </div>
      )}
    </Div>
  );
};

export default ClosedAtCheckbox;
