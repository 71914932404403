import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import Tooltip from 'antd/es/tooltip';

type AdminTagProps = {
  text?: string;
  tooltip?: string;
  color?: string;
  onClick?: () => void;
};

const AdminTag = ({ text, tooltip, color, onClick }: AdminTagProps) => {
  return (
    <Tooltip color={color} title={tooltip}>
      <HdTag
        color={color}
        onClick={onClick}
        text={text}
        withBorder
        hoverable={!!onClick}
      />
    </Tooltip>
  );
};

export default AdminTag;
