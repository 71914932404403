import styled from '../../../../plugins/styled';
import { createFontSizeCSS } from '../../../../theme/global-style';

export const GrantSectionHeaderContent = styled.div`
  padding: 32px 48px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {
    padding: 24px;
    padding-top: 48px;
    align-items: flex-start;
    flex-direction: column;
  }
`;
export const GrantSectionContent = styled.div`
  padding: 48px;
  padding-top: 0;
  ${createFontSizeCSS('body1')}
  a {
    text-decoration: none;
  }

  @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {
    padding: 24px;
    padding-top: 0;
  }
`;

export const GrantSectionContainer = styled.div`
  background: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.primary};

  &:last-child {
    border-radius: 0px 0px 11px 11px;
  }
`;
