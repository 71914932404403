import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { Form, Input, message, Modal } from 'antd';
import { useAppDispatch } from '../../../app';
import { updateGinApplicationName } from '../../../features/api/slices/gins-slice';
import { useNewAdminApi } from '../../../features/api/use-admin-api';

interface ProgramNameModalProps {
  isVisible: boolean;
  handleClose: () => void;
  applicationName: string;
  applicationId: string;
}

const ProgramNameModal = ({
  isVisible,
  handleClose,
  applicationName,
  applicationId,
}: ProgramNameModalProps) => {
  const { t } = useTranslations();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const api = useNewAdminApi();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      console.log(values);
      const result = await dispatch(
        updateGinApplicationName({
          api,
          applicationId,
          newName: values.application_name,
        }),
      );

      if (updateGinApplicationName.fulfilled.match(result)) {
        form.resetFields();
        message.success(
          t('application_single|application_name_update_success'),
        );
        handleClose();
      } else {
        message.error(t('application_single|application_name_update_failed'));
      }
    } catch (e) {
      message.error(t('application_single|application_name_update_failed'));
      console.error(e);
    }
  };
  return (
    <Modal
      title={t('application_single|edit_application_name')}
      open={isVisible}
      onCancel={handleClose}
      onOk={handleSubmit}
    >
      <Form form={form} initialValues={{ application_name: applicationName }}>
        <Form.Item
          label={t('application_single|application_name')}
          name="application_name"
          rules={[
            {
              required: true,
              message: t('application_single|please_enter_application_name'),
            },
          ]}
        >
          <Input placeholder={t('application_single|enter_application_name')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProgramNameModal;
