import { View } from '@react-pdf/renderer';
import PdfTableColumn, { PdfTableColumnProps } from './column';
import { TableStyles } from './styles';

export interface PdfTableProps {
  columns: PdfTableColumnProps[];
}

const PdfTable: React.FC<PdfTableProps> = ({ columns }) => {
  return (
    <View style={TableStyles.table}>
      {columns.map((col, i) => (
        <PdfTableColumn
          key={col.id}
          {...col}
          isLast={i === columns?.length - 1}
        />
      ))}
    </View>
  );
};

export default PdfTable;
