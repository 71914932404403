import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import Div from '@hellodarwin/core/lib/components/common/div';
import Modal from '@hellodarwin/core/lib/components/common/hd-modal';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { SearchResults } from '@hellodarwin/core/lib/features/entities';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import Input, { InputRef } from 'antd/es/input';
import Select, { SelectProps } from 'antd/es/select';
import Space from 'antd/es/space';
import { useEffect, useRef, useState } from 'react';
import SearchBarCompanies from '../components/search-bar/search-bar-companies';
import SearchBarContact from '../components/search-bar/search-bar-contact';
import SearchBarGrantProviders from '../components/search-bar/search-bar-grant-providers';
import SearchBarGrants from '../components/search-bar/search-bar-grants';
import SearchBarProvider from '../components/search-bar/search-bar-providers';
import SearchBarRfp from '../components/search-bar/search-bar-rfp';
import { useAdminApi } from '../features/api/use-admin-api';
import useDebounce from '../hooks/use-debounce';

import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Empty from 'antd/es/empty';
import { useAdminSettings } from '../features/providers/admin-settings-provider';

const SEARCH_DELAY_MS = 500;

const SearchBar = () => {
  const api = useAdminApi();
  const { updateSetting, getSetting } = useAdminSettings();
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState<SearchResults | undefined>(undefined);
  const inputElement = useRef<InputRef>(null);
  const { selectedLocale } = useLocale();
  const theme = useTheme();
  const { t } = useTranslations();

  const values = getSetting('searchSections');

  useEffect(() => {
    if (isModalVisible) {
      setTimeout(() => {
        inputElement.current?.focus();
      }, 0.1);
    }
  }, [isModalVisible]);

  const input = useRef<InputRef>(null);
  const options: SelectProps['options'] = [
    { label: t('programs_admin|programsTitle'), value: 'grants' },
    { label: t('searchBarAdmin|companies'), value: 'companies' },
    { label: t('searchBarAdmin|providers'), value: 'providers' },
    { label: 'Contacts', value: 'contacts' },
    { label: t('searchBarAdmin|rfp'), value: 'projects' },
    { label: t('searchBarAdmin|grantProviders'), value: 'grantProviders' },
  ];

  const onOpen = () => {
    input.current?.blur();
    setIsModalVisible(true);
  };
  const onClose = () => setIsModalVisible(false);

  const onSearch = (query: string) => {
    if (query === '') return;
    (async () => {
      setIsSearching(true);
      try {
        const response = await api.globalSearch(query, selectedLocale);
        if (
          Object.values(response).every(
            (value) =>
              value === null || (Array.isArray(value) && value.length === 0),
          )
        ) {
          setResults(undefined);
          return;
        }

        setResults(response);
      } catch (e: any) {
        console.error(e);
      } finally {
        setIsSearching(false);
      }
    })();
  };

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <>
      <Div
        flex="row"
        align="stretch"
        borderColor={theme.colors.grey_5}
        style={{
          borderWidth: '1px 1px 0 0',
          overflow: 'hidden',
          borderRadius: '0 16px 0  0 ',
          width: '100%',
          height: 'calc(100% + 1px)',
          marginTop: -1,
        }}
      >
        <Input
          ref={input}
          onFocus={onOpen}
          size="large"
          placeholder={t('searchBarAdmin|search')}
          style={{
            width: '100%',
            border: 0,

            margin: 0,
          }}
        />
      </Div>

      <Modal open={isModalVisible} handleCancel={onClose} size="full">
        <Div
          onClick={(e) => e.stopPropagation()}
          style={{ padding: 16, maxHeight: '90vh', overflow: 'hidden' }}
          backgroundColor={theme.colors.white_1}
          gap={10}
        >
          <Input
            ref={inputElement}
            value={searchTerm}
            placeholder={t('searchBarAdmin|searchBarPlaceholder')}
            onChange={(e) => setSearchTerm(e.target.value)}
            addonBefore={<SearchOutlined style={{ fontSize: 20 }} />}
            addonAfter={
              searchTerm ? (
                <CloseCircleOutlined onClick={() => setSearchTerm('')} />
              ) : undefined
            }
          />
          <Div flex="column" gap={16}>
            <Space
              style={{ width: '100%', marginTop: '20px' }}
              direction="vertical"
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                value={values}
                onChange={(values) => updateSetting('searchSections', values)}
                options={options}
              />
            </Space>
            {!!results ? (
              <Div
                style={{
                  maxHeight: 400,
                  position: 'relative',
                  overflowY: 'auto',
                }}
              >
                {results?.grants?.length > 0 && values.includes('grants') && (
                  <SearchBarGrants
                    results={results}
                    isSearching={isSearching}
                    onClose={onClose}
                  ></SearchBarGrants>
                )}
                {results?.companies?.length > 0 &&
                  values.includes('companies') && (
                    <SearchBarCompanies
                      results={results}
                      isSearching={isSearching}
                      onClose={onClose}
                    ></SearchBarCompanies>
                  )}
                {results?.providers?.length > 0 &&
                  values.includes('providers') && (
                    <SearchBarProvider
                      results={results}
                      isSearching={isSearching}
                      onClose={onClose}
                    ></SearchBarProvider>
                  )}
                {results?.contacts?.length > 0 &&
                  values.includes('contacts') && (
                    <SearchBarContact
                      results={results}
                      isSearching={isSearching}
                      onClose={onClose}
                    ></SearchBarContact>
                  )}
                {results?.rfp?.length > 0 && values.includes('projects') && (
                  <SearchBarRfp
                    results={results}
                    isSearching={isSearching}
                    onClose={onClose}
                  ></SearchBarRfp>
                )}
                {results?.grantProviders?.length > 0 &&
                  values.includes('grantProviders') && (
                    <SearchBarGrantProviders
                      results={results}
                      isSearching={isSearching}
                      onClose={onClose}
                    ></SearchBarGrantProviders>
                  )}
              </Div>
            ) : (
              <Div
                flex="column"
                align="center"
                justify="center"
                style={{ padding: 30 }}
              >
                <Empty
                  description={
                    <Typography>{t('searchBarAdmin|noResults')}</Typography>
                  }
                ></Empty>
              </Div>
            )}
          </Div>
        </Div>
      </Modal>
    </>
  );
};

export default SearchBar;
