import { StyleSheet } from '@react-pdf/renderer';
import theme from '../theme';

export const PdfPagesStyles = StyleSheet.create({
  titlePage: {
    flexDirection: 'column',
    backgroundColor: theme.colors.purple_1,
    color: theme.colors.white_1,
  },

  pageLevel1: {
    flexDirection: 'column',
    backgroundColor: theme.colors.beige_1,
    color: theme.colors.grey_1,
  },
  pageLevel2: {
    flexDirection: 'column',
    backgroundColor: theme.colors.beige_2,
    color: theme.colors.grey_1,
  },
  pageLevel3: {
    flexDirection: 'column',
    backgroundColor: theme.colors.beige_3,
    color: theme.colors.grey_1,
  },
  pageLevel4: {
    flexDirection: 'column',
    backgroundColor: theme.colors.beige_4,
    color: theme.colors.grey_1,
  },
});
