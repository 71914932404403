import { View } from '@react-pdf/renderer';
import Typography from '../typography';
import { TypographyProps } from '../typography/types';
import ListItemLinkWrapper from './link-wrapper';
import ListDot from './list-dot';
import { ListStyles } from './styles';

export interface ListItemProps {
  children: string;
  level: number;
  index: number;
  typography?: Omit<TypographyProps, 'children'>;
  ordered?: boolean;
  href?: string;
}

const ListItem: React.FC<ListItemProps> = ({
  children,
  level,
  typography = { elementTheme: 'body1' },
  ordered,
  index,
  href,
}) => {
  return (
    <View style={{ ...ListStyles.listItem, marginLeft: level * 24 }}>
      <ListDot
        level={level}
        elementTheme={typography.elementTheme ?? 'body1'}
        ordered={ordered}
        index={index}
        color={typography.color}
      />
      <ListItemLinkWrapper href={href}>
        <Typography {...typography} textAlign="left" fullWidth>
          {children}
        </Typography>
      </ListItemLinkWrapper>
    </View>
  );
};

export default ListItem;
