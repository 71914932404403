import { ReactNode } from 'react';
import Modal, { ModalProps } from '..';
import Div from '../../div';
import Typography from '../../typography';

// *** MODAL HEADER ***
interface HeaderProps {
  title: string;
  action?: ReactNode;
}

const Header = ({ title, action }: HeaderProps) => {
  return (
    <Div
      flex="row"
      align="center"
      justify="space-between"
      gap={32}
      tablet={{
        flex: 'column',
        align: 'flex-start',
        justify: 'flex-start',
        gap: 8,
      }}
    >
      <Typography elementTheme="subtitle1">{title}</Typography>
      {action}
    </Div>
  );
};

// *** MODAL FOOTER ***

export interface FooterProps {
  actions: ReactNode;
}

const Footer = ({ actions }: FooterProps) => {
  return (
    <Div
      flex="row"
      align="center"
      justify="flex-end"
      gap={8}
      tablet={{ flex: 'column-reverse', align: 'center' }}
    >
      {actions}
    </Div>
  );
};

// *** MODAL CONTENT ***

interface ModalWithHeaderFooterProps extends ModalProps {}
const ModalWithHeaderFooter = ({
  ...modalProps
}: ModalWithHeaderFooterProps) => {
  return <Modal {...modalProps} />;
};
ModalWithHeaderFooter.Header = Header;
ModalWithHeaderFooter.Footer = Footer;

export default ModalWithHeaderFooter;
